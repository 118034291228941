import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";
import { RoutePath } from "../../../routes/route-path";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { useDispatch } from "react-redux";
import { actionsetIsClosed } from "../../../store/reducer/SavedPost";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function TagUser(props: any) {
  const t = AppStringUtils();
  const router = useNavigate();
  const isMobileOrTablet = useMediaQuery("(max-width: 900px)");
  const { showPopup, setShowPopup, tagUserList, tagType } = props;
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dispatch = useDispatch();
  const UserData = (props: any) => {
    return (
      <Box
        sx={{
          ...styles.alignSpaceBetween,
          width: isMobileOrTablet ? "100%" : 300,
          p: 1,
          cursor: "pointer",
          borderRadius: "8px",
          background: "rgba(0, 0, 0, 0.1)",
        }}
        onClick={() => {
          dispatch(actionsetIsClosed(true));
          router(RoutePath.userProfile + props?.tagged_user?.user_uuid);
        }}
      >
        <Box sx={styles.alignStart}>
          <Box>
            <Avatar
              sx={styles.profileImage4}
              src={props?.tagged_user?.profile_image}
              imgProps={{ loading: "lazy" }}
            />
          </Box>
          <Box sx={{ ml: 1, pt: "2px" }}>
            <Typography component="div" sx={styles.tagUserNameText1}>
              {props?.tagged_user?.first_name +
                " " +
                props?.tagged_user?.last_name}
            </Typography>
            <Typography
              component="div"
              sx={{ ...styles.tagUserNameText2, ...styles.lowercase }}
            >
              {props?.tagged_user?.username}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Dialog
      fullScreen={isMobileOrTablet}
      open={showPopup}
      maxWidth="md"
      onClose={handleClosePopup}
      PaperProps={{
        sx: {
          height: isMobileOrTablet ? "100vh" : "60vh",
          overflow: "hidden",
        },
      }}
      TransitionComponent={Transition}
      BackdropProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
    >
      <Container
        sx={{
          ...styles.dialogBox,
          p: 0,
          px: isMobileOrTablet ? 2 : 0,
        }}
      >
        <DialogTitle
          sx={{
            ...styles.textInformation3,
            fontWeight: "bold",
            p: 1,
          }}
        >
          {`${t.tagged} ${tagType === "image" ? "image" : "video"}`}
          <IconButton
            aria-label="close"
            sx={{ ...styles.closeIcon, right: 15 }}
            onClick={handleClosePopup}
          >
            <Close sx={{ height: 14, width: 14 }} />
          </IconButton>
        </DialogTitle>
        <Box>
          <Divider color="grey" sx={styles.autoHW} />
        </Box>
        <Box sx={{ ...styles.tagUserListBox }}>
          <Box>
            {tagUserList &&
              tagUserList?.map((item: any) => (
                <Box sx={{ my: 1 }}>
                  <UserData {...item} />
                </Box>
              ))}
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
}
