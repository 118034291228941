import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";

const apiCallGetOtherUserProfile = (userUid: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.OtherUserProfile.profilePublicDetails + userUid}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetPostsGallery = (params: any, token: string) => {
  let queryString = getCleanQueryString(params);
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.OtherUserProfile.profilePostGallery + "?" + queryString}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostFollowUnfollowUser = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.OtherUserProfile.followUnfollowUser,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetFollowerFollowing = (params: any, token: string) => {
  let queryString = getCleanQueryString(params);
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.OtherUserProfile.followerFollowing}?${queryString}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForGetMutualFriendList = (params: any, token: string) => {
  let queryString = getCleanQueryString(params);
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.OtherUserProfile.mutualFollower}?${queryString}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

export const getCleanQueryString = (params: any) => {
  let query = params;
  if (!query) return;
  return Object.keys(query)
    .filter((key) => query[key] !== "")
    .map((keyName) => keyName + "=" + query[keyName])
    .join("&");
};

const apiCallGetUserSavedPosts = (params: any, token: string) => {
  let queryString = getCleanQueryString(params);
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.dashboard.savedPostList + "?" + queryString}`,
      {},
      token,
    )
      .then((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostBlockUser = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.profile.blockUser, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetBlockUserList = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(endPoint.profile.blockUser, params, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallDeleteUnBlockUser = (userId: any, token: string) => {
  let url = `${endPoint.profile.blockUser + userId}/`;
  return new Promise((resolve, reject) => {
    ServiceRequest.DeleteApiRequest(url, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostReportUser = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.profile.userReport, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
export const ServiceUserProfile = {
  apiCallGetOtherUserProfile,
  apiCallGetPostsGallery,
  apiCallPostFollowUnfollowUser,
  apiCallGetFollowerFollowing,
  apiCallGetUserSavedPosts,
  apiCallPostBlockUser,
  apiCallDeleteUnBlockUser,
  apiCallPostReportUser,
  apiCallGetBlockUserList,
  apiCallForGetMutualFriendList,
};
