import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";
import { getCleanQueryString } from "../user-profile";

const apiCallGetExplorePost = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    let queryString = getCleanQueryString(params);
    let url = endPoint.explore.explorePost + "?" + queryString;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (res.data.length > 0) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiExploreGetAllHastags = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    let queryString = getCleanQueryString(params);
    let url = endPoint.explore.exploreHastags + "?" + queryString;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (res.data.length > 0) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetExploreReels = (
  pageSize: number,
  page: number,
  token: string,
) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.explore.explorePost}?post_type=reels&page_size=${pageSize}&page=${page}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (res.data.length > 0) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
export const ServiceExplore = {
  apiCallGetExplorePost,
  apiCallGetExploreReels,
  apiExploreGetAllHastags,
};
