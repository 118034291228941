import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { PatterColor } from "../../theming/Style/color.styles";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
      border: "1px solid white",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: PatterColor.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: "2px solid white",
        backgroundColor:
          "linear-gradient(270.04deg, #16E8FF 0.03%, #E900FF 99.97%)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    border: "2px solid white",
    backgroundColor:
      "linear-gradient(270.04deg, #16E8FF 0.03%, #E900FF 99.97%)",
    boxSizing: "border-box",
  },
}));

export default function CommonSwitch(switchValues: any) {
  const { switchValue } = switchValues;
  return (
    <FormGroup>
      <Stack direction="row" sx={{ py: "4px" }}>
        <CustomSwitch
          checked={switchValue.value}
          onChange={(e) => {
            switchValue.formik.handleChange(e);
            switchValue.formik.handleSubmit();
          }}
          name={switchValue.name}
        />
      </Stack>
    </FormGroup>
  );
}
