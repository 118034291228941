import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppStringUtils } from "../../utils";
import { styles } from "../../theming/Style/Styles";
import { PatterColor } from "../../theming/Style/color.styles";
import NoRecordFound from "../../container/hype-cast/common/NoRecordFound";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../api";
import { RoutePath } from "../../routes/route-path";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TagIcon from "@mui/icons-material/Tag";

const homeScreenLabelStyle = {
  marginTop: "12px",
  fontSize: { md: "20px", xs: "16px" },
  fontWeight: 700,
  justifyContent: "flex-start",
  color: "#fff",
};

const Label = ({ title }: any) => {
  return (
    <Typography
      variant="h4"
      sx={{
        ...styles.text,
        ...homeScreenLabelStyle,
        textOverflow: "ellipsis",
      }}
    >
      {title}
    </Typography>
  );
};

export default function SearchBarPage(props: any) {
  const { searchTerm } = props;
  const t = AppStringUtils();
  let selectedItem = 0;
  const { token } = useSelector((state: any) => state?.rLogin?.infoLogin || {});

  const [userList, setUserList] = useState([]);
  const [isLocation, setIsLocation] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const router = useNavigate();

  const [selectedMenuItem, setSelectedMenuItem] = useState("Top"); // State to track the selected menu item

  const handleMenuItemClick = (title: string) => {
    setSelectedMenuItem(title); // Update the selected menu item when clicked
  };

  const apiCallSearch = (user: any) => {
    if (user) {
      apiCallGetUserList(user);
      apiCallGetHastags(user);
      apiCallGetLocation(user);
    }
  };

  useEffect(() => {
    if (searchTerm?.length !== 0) {
      apiCallSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedMenuItem === "Location") {
      apiCallGetLocation(searchTerm);
    } else if (selectedMenuItem === "Hastag") {
      apiCallGetHastags(searchTerm);
    }
  }, [selectedMenuItem]);

  const apiCallGetUserList = (user: any) => {
    if (user) {
      ServiceDashBoard.apiCallGetUserList(user, token)
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {});
    } else if (user === "") {
      setUserList([]);
    }
  };

  const apiCallGetHastags = (user: any) => {
    if (user) {
      ServiceDashBoard.apiCallSearchHastags(user, token)
        .then((res: any) => {
          setHashtags(res?.data);
        })
        .catch((err: any) => {});
    } else if (user === "") {
      setHashtags([]);
    }
  };

  const apiCallGetLocation = (user: any) => {
    if (user) {
      ServiceDashBoard.apiCallSearchLocation(user, token)
        .then((res: any) => {
          setIsLocation(res?.data);
        })
        .catch((err: any) => {});
    } else if (user === "") {
      setIsLocation([]);
    }
  };

  const renderMenuItem = (title: string, index: number) => {
    return (
      <Button
        sx={{
          textTransform: title === t.reportBug ? "none" : "capitalize",
          color: selectedItem === index ? PatterColor.white : PatterColor.Gray,
          fontFamily: "IBM Plex Mono",
          justifyContent: "initial",
          m: 1,
          mr: "4px",
        }}
        onClick={() => {
          handleMenuItemClick(title); // Call function to handle menu item click
        }}
      >
        {title}
      </Button>
    );
  };

  const handleClear = () => {
    // setSearchTerm("");
    // setIsLocation([]);
    // setHashtags([]);
    // setUserList([]);
    props?.setAnchorEl(null);
  };

  const headers = (title: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Label title={title} />
      </Box>
    );
  };

  const UserLists = () => {
    return (
      <>
        {userList?.map((item: any, index: number) => (
          <Box
            key={index}
            style={{
              backgroundColor: "#000000",
              px: 1,
              cursor: "pointer",
              paddingBottom: "10px",
            }}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              my: -1,
            }}
            {...props}
          >
            <Box
              sx={{ mx: 1, py: 1, ...styles.alignStart }}
              onClick={() => {
                sessionStorage.setItem("userDetail", JSON.stringify(item));
                router(RoutePath.userProfile + item?.user_uuid);
                handleClear();
              }}
            >
              <Avatar
                sx={styles.notificationUserImg}
                imgProps={{ loading: "lazy" }}
                src={item?.profile_image}
              />
              <Typography component="div" sx={styles.searchUsername}>
                {item?.first_name + " " + item?.last_name}
                <Typography
                  component="div"
                  variant="body2"
                  sx={styles.postNameText2}
                >
                  {"@" + item?.username}
                </Typography>
              </Typography>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  const LocationList = () => {
    return (
      <>
        {isLocation?.map((item: any, index: number) => {
          return (
            <>
              <Box
                style={{
                  backgroundColor: "#000000",
                  px: 1,
                  mt: 2,
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
                component="li"
                sx={{
                  "& > img": { mr: 2, flexShrink: 0 },
                  my: -1,
                }}
                {...props}
              >
                <Box
                  sx={{ mx: 1, py: 1, ...styles.alignStart }}
                  onClick={() => {
                    props?.setAnchorEl(null);
                    router(RoutePath.exploreSearch, {
                      state: {
                        locationName: item?.uuid,
                        hashtag: null,
                      },
                    });
                    handleClear();
                  }}
                >
                  <IconButton>
                    <LocationOnIcon sx={{ color: "#FFF", fontSize: "32px" }} />
                  </IconButton>
                  <Typography component="div" sx={styles.searchUsername}>
                    {item?.location_name}
                  </Typography>
                </Box>
              </Box>
            </>
          );
        })}
      </>
    );
  };

  const HastagsList = () => {
    return (
      <>
        {hashtags?.map((item: any, index: number) => {
          return (
            <>
              <Box
                style={{
                  backgroundColor: "#000000",
                  px: 1,
                  mt: 2,
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
                component="li"
                sx={{
                  "& > img": { mr: 2, flexShrink: 0 },
                  my: -1,
                }}
                {...props}
              >
                <Box
                  sx={{
                    mx: 1,
                    py: 1,
                    ...styles.alignStart,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props?.setAnchorEl(null);
                    router(RoutePath.exploreSearch, {
                      state: {
                        hashtag: item?.uuid,
                        locationName: null,
                      },
                    });
                    handleClear();
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px solid #FFF",
                      borderRadius: "50%",
                    }}
                  >
                    <TagIcon sx={{ color: "#FFF", fontSize: "32px" }} />
                  </IconButton>
                  <Typography component="div" sx={styles.searchUsername}>
                    {item?.post_tag_name}
                  </Typography>
                </Box>
              </Box>
            </>
          );
        })}
      </>
    );
  };

  return (
    <Container maxWidth={"sm"}>
      <Box>
        <Box
          marginTop={3}
          sx={{
            color: "#FFF",
            display: "flex",
            alignItems: "start",
            justifyContent: "space-around",
          }}
        >
          {renderMenuItem("Top", 1)}
          {renderMenuItem("Account", 2)}
          {renderMenuItem("Hastag", 3)}
          {renderMenuItem("Location", 4)}
        </Box>
        <Box>
          {userList?.length === 0 &&
            isLocation?.length === 0 &&
            hashtags?.length === 0 && (
              <Box>
                <Grid item xs={12}>
                  <NoRecordFound text={"No records found"} />
                </Grid>
              </Box>
            )}
          {selectedMenuItem === "Top" && (
            <>
              {userList?.length > 0 && (
                <>
                  {headers("Account")}
                  {UserLists()}
                </>
              )}
              {isLocation?.length > 0 && (
                <>
                  {headers("Location")}
                  {LocationList()}
                </>
              )}
              {hashtags?.length > 0 && (
                <>
                  {headers("Hastag")}
                  {HastagsList()}
                </>
              )}
            </>
          )}
          {selectedMenuItem === "Account" && userList?.length !== 0 && (
            <>
              {headers("Account")}
              {UserLists()}
            </>
          )}
          {selectedMenuItem === "Location" && (
            <>
              {isLocation?.length === 0 ? (
                <Box>
                  <Grid item xs={12}>
                    <NoRecordFound text={"No records found"} />
                  </Grid>
                </Box>
              ) : (
                <>
                  {headers("Location")}
                  {LocationList()}
                </>
              )}
            </>
          )}
          {selectedMenuItem === "Hastag" && (
            <>
              {hashtags?.length === 0 ? (
                <Box>
                  <Grid item xs={12}>
                    <NoRecordFound
                      text={"No records found"}
                      // style={{ height: 25, pl: 2 }}
                    />
                  </Grid>
                </Box>
              ) : (
                <>
                  {headers("Hastag")}
                  {HastagsList()}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}
