import { createSlice } from "@reduxjs/toolkit";

const storySlice = createSlice({
  name: "storySlice",
  initialState: {
    initialIndex: 0,
    isTrue: false,
    selfStory: false,
    selfPagination: false,
    hasNextPage1: null,
  },
  reducers: {
    actionSetIndex(state, action) {
      state.initialIndex = action.payload;
    },
    actionSetFlag(state, action) {
      state.isTrue = action.payload;
    },
    actionSelfStory(state, action) {
      state.selfStory = action.payload;
    },
    actionSelfPagination(state, action) {
      state.selfPagination = action.payload;
    },
    actionHasNextPage(state, action) {
      state.hasNextPage1 = action.payload;
    },
  },
});

export const {
  actionSetIndex,
  actionSetFlag,
  actionSelfStory,
  actionSelfPagination,
  actionHasNextPage,
} = storySlice.actions;
export default storySlice.reducer;
