import { TextField, InputAdornment, IconButton } from "@mui/material";
import { styles } from "../../theming/Style/Styles";

export default function CommonTextInput(props: any) {
  const { textFieldValue } = props;
  return (
    <TextField
      key={textFieldValue.name}
      fullWidth
      label={textFieldValue.placeholder}
      className="input-rounded-corners"
      variant="outlined"
      size="small"
      InputLabelProps={{
        style: { ...styles.inputLabel },
      }}
      // placeholder={textFieldValue.placeholder}
      name={textFieldValue.name}
      sx={
        textFieldValue.icon1
          ? styles.textInputProps
          : textFieldValue.inputType === "number" ||
            textFieldValue.maxlength === 6
          ? styles.numberInput
          : styles.textInput
      }
      value={
        isNaN(textFieldValue.value) && textFieldValue?.inputType === "number"
          ? ""
          : textFieldValue.value
      }
      onBlur={textFieldValue.handleBlur}
      onChange={textFieldValue.handleChange}
      onFocus={textFieldValue.handleFocus}
      error={Boolean(textFieldValue.touchedValue && textFieldValue.errorsValue)}
      helperText={textFieldValue.touchedValue && textFieldValue.errorsValue}
      FormHelperTextProps={{
        style: { ...styles.helperText },
      }}
      type={textFieldValue.iconsValue ? "text" : textFieldValue.inputType}
      InputProps={
        textFieldValue.icon1 ? (
          {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={textFieldValue.handleClickShowPassword}
                  onMouseDown={textFieldValue.handleMouseDownPassword}
                  edge="end"
                  sx={styles.colorWhite}
                >
                  {textFieldValue.iconsValue
                    ? textFieldValue.icon1
                    : textFieldValue.icon2}
                </IconButton>
              </InputAdornment>
            ),
          }
        ) : (
          <></>
        )
      }
      inputProps={
        textFieldValue?.maxlength && {
          maxLength: textFieldValue?.maxlength,
        }
      }
      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
        textFieldValue?.inputType === "number" ? (
          (e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, textFieldValue?.maxlength))
        ) : (
          <></>
        );
      }}
    />
  );
}
