import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceReels } from "../../api/Reels/ReelsApi";
import Loader from "../../components/common/loader";
import MobileCommentPopup from "../../components/dashboard/post-card/MobileCommentPopup";
import { getEmojiFromUnicode } from "../../components/dashboard/post-card/comment-popup";
import { RoutePath } from "../../routes/route-path";
import { styles } from "../../theming/Style/Styles";
import CommonSkeleton from "./../../components/common/skeleton";
import ReelsFollowButton from "./buttons/ReelsFollowButton";
import ReelsPostIcons from "./buttons/ReelsPostIcons";
import CommentPopup from "./components/CommentPopup";
import MobileSharePopup from "./components/MobileSharePopup";
import ProfileImage from "./components/ProfileImage";
import ReelsCaption from "./components/ReelsCaption";
import SharePopup from "./components/SharePopup";
import VideoPlayer2 from "./components/VideoPlayer";

const Reels = () => {
  const router = useNavigate();
  const location = useLocation();
  let userData = location.state?.data.slice(
    location.state.index,
    location.state?.data?.length
  );
  const { isMuted } = useSelector((state: any) => state.reels);
  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [reelsData, setReelsData] = useState<any>(userData || []);
  const [sharePopup, setSharePopup] = useState(false);
  const [commentPopup, setCommentPopup] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [postId, setPostId] = useState(false);
  const [reelsInfo, setReelsInfo] = useState(null);
  const [commentCount, setCommentCount] = useState<any>(
    userData?.map((item: any) => item?.comment_count) || []
  );
  const [itemIndex, setItemIndex] = useState(0);
  const { token, user_uuid } = useSelector(
    (state: any) => state.rLogin.infoLogin
  );
  const [mobileCommentPopup, setMobileCommentPopup] = useState(false);
  const [mobilesharePopup, setMobileSharePopup] = useState(false);
  const [page, setPage] = useState(location.state?.page || 1);
  const [postData, setPostData] = useState<any>({});

  const apiCallForGetAllReels = (pageNumber: number) => {
    setIsLoading(true);
    ServiceReels.apiCallForGetAllReels(
      {
        pageNumber: pageNumber,
      },
      token
    )
      .then((res: any) => {
        setIsLoading(false);
        if (res.links.next) {
          const commentData = res?.data?.map((item: any) => item.comment_count);
          setCommentCount((prev: any) => [...prev, ...commentData]);
          setReelsData((prev: any) => [...prev, ...res?.data]);
          setNextPage(res.links.next);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const scrollableElementRef: any = useRef<HTMLDivElement | null>(null);
  const ref: any = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    (userData?.length == 0 || userData?.length == undefined) &&
      apiCallForGetAllReels(page);
    const body: any = document.getElementsByTagName("body");
    // body[0].style.overflow = "hidden";
    return () => {
      // body[0].style.overflow = "scroll";
    };
  }, []);

  const [index, setIndex] = useState(location?.state?.index || 0);

  const scrollToElement = (index: number) => {
    const childElement = scrollableElementRef?.current?.children[index];
    if (childElement) {
      window.requestAnimationFrame(() => {
        childElement.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  const handleScroll = (event: any) => {
    ref.current.removeEventListener("wheel", handleScroll);
    const delta = Math.sign(event.deltaY);
    if (delta === 1) {
      if (index !== reelsData?.length - 1) {
        scrollToElement(index + 1);
        setIndex(index + 1);
      } else {
        ref.current.addEventListener("wheel", handleScroll);
      }
    } else {
      if (index !== 0) {
        scrollToElement(index - 1);
        setIndex(index - 1);
      } else {
        ref.current.addEventListener("wheel", handleScroll);
      }
    }
  };

  useEffect(() => {
    if (index === reelsData?.length - 4 && nextPage !== null) {
      setPage(page + 1);
      apiCallForGetAllReels(page + 1);
    }
    setTimeout(async () => {
      await ref.current.addEventListener("wheel", handleScroll);
    }, 2000);
  }, [index]);

  let totalRemainsHeight = document.body.clientHeight;

  useEffect(() => {
    setCommentCount(
      commentCount.map((el: any, i: number) =>
        index === i ? postData.commentCount : el
      )
    );
  }, [postData]);

  const handleFollow = (user_uuid: string) => {
    setReelsData(
      reelsData.map((item: any) => {
        if (item.posted_by_data.user_uuid === user_uuid) {
          return {
            ...item,
            request_status: "both",
            follow_status: "unfollow",
          };
        } else {
          return item;
        }
      })
    );
  };

  return (
    <Box sx={styles.ReelsContainer} ref={ref}>
      {reelsData.length === 0 ? (
        <Box sx={styles.storySkeleton}>
          <CommonSkeleton variant="rectangular" height="100%" width="100%" />
        </Box>
      ) : (
        <>
          {isLoading && <Loader />}
          <Box sx={styles.reelsContainer2}>
            <Box sx={styles.reelsBackBtn}>
              <ArrowBackIcon onClick={() => router(-1)} />
            </Box>
            <Typography>Reels</Typography>
          </Box>
          <Box ref={scrollableElementRef} sx={{ paddingBottom: "10px" }}>
            {reelsData.length > 0 &&
              reelsData?.map((item: any, index: number) => (
                <Box
                  sx={
                    reelsData.length === index + 1
                      ? {
                          ...styles.ReelsChildContainer,
                          pb: 2,
                        }
                      : { ...styles.ReelsChildContainer }
                  }
                  key={index}
                >
                  <Box
                    sx={{
                      ...styles.videoPlayerContainer,
                      height: { xs: totalRemainsHeight, md: "88vh" },
                      width: "100%",
                      aspectRatio: { sm: "9 / 16" },
                    }}
                  >
                    <VideoPlayer2
                      videoUrl={item?.post_files?.at(0)?.media_url}
                      index={index}
                      height={totalRemainsHeight}
                      muted={isMuted}
                    />
                    <Box sx={styles.ReelsDetailsContainer}>
                      <Box sx={styles.ReelsUserDetailsContainer}>
                        <Box
                          sx={styles.ReelsUserDetailsContainer}
                          onClick={() =>
                            router(
                              `${
                                RoutePath.userProfile +
                                item?.posted_by_data?.user_uuid
                              }`
                            )
                          }
                        >
                          <ProfileImage
                            imageUrl={item?.posted_by_data?.profile_image}
                          />
                          <Typography sx={styles.ReelsUserDetailsText}>
                            {item?.posted_by_data?.username}
                          </Typography>
                        </Box>
                        {item?.posted_by_data?.user_uuid !== user_uuid && (
                          <ReelsFollowButton
                            data={item}
                            handleFollow={handleFollow}
                          />
                        )}
                      </Box>

                      <ReelsCaption
                        postedByData={item?.posted_by_data}
                        content={
                          item?.post_caption?.includes("U+")
                            ? getEmojiFromUnicode(item?.post_caption)
                            : item?.post_caption
                        }
                        postLocation={item?.post_location}
                      />
                    </Box>
                  </Box>
                  <ReelsPostIcons
                    data={item}
                    reelsData={reelsData}
                    setReelsData={setReelsData}
                    setPostData={setPostData}
                    setReelsInfo={setReelsInfo}
                    setSharePopup={setSharePopup}
                    setCommentPopup={setCommentPopup}
                    setPostId={setPostId}
                    commentCount={commentCount[index] ?? 0}
                    setIndex={setItemIndex}
                    index={index}
                    setMobileCommentPopup={setMobileCommentPopup}
                    setMobileSharePopup={setMobileSharePopup}
                    muted={isMuted}
                    setIsRefresh={setIsRefresh}
                  />
                </Box>
              ))}
          </Box>
          <SharePopup
            isOpen={sharePopup}
            setIsOpen={setSharePopup}
            uuid={postId}
            postInfo={reelsInfo}
          />
          <CommentPopup
            isOpen={commentPopup}
            setIsOpen={setCommentPopup}
            uuid={postId}
            setCommentCount={setCommentCount}
            commentCount={commentCount}
            index={itemIndex}
            postData={postData}
            setPostData={setPostData}
          />
          <MobileCommentPopup
            info={reelsData?.[index]}
            showPopup={mobileCommentPopup}
            setShowPopup={setMobileCommentPopup}
            postData={postData}
            setPostData={setPostData}
          />
          <MobileSharePopup
            isOpen={mobilesharePopup}
            setIsOpen={setMobileSharePopup}
            uuid={postId}
            postInfo={reelsInfo}
          />
        </>
      )}
    </Box>
  );
};

export default Reels;
