import { ClipLoader } from "react-spinners";
import { PatterColor } from "../../theming/Style/color.styles";

interface SpinnerProps {
  isShow: boolean | false;
  color?: string | undefined;
  size?: number;
}

const Spinner = (props: SpinnerProps) => {
  const { isShow } = props;
  return (
    <ClipLoader
      size={props.size ?? 30}
      color={props.color ?? PatterColor.white}
      loading={isShow}
    />
  );
};

export default Spinner;
