import { useSelector } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";
import { UserTypes } from "../components/common/enums/user-types";
import { ListRouteElements } from "./route-elements";
import { CommonRouteProps } from "./route-interface.props";
import { AthletePath, RoutePath } from "./route-path";

/**
 * generate Route List
 */
export const RouteList = () => {
  const { isLogin, infoLogin } = useSelector((state: any) => state?.rLogin);
  if (infoLogin) {
    var { is_registration_complete, user_type } = infoLogin;
  }

  const location = useLocation();

  const CommonRoute = (routeInfo: CommonRouteProps) => {
    return (
      <Route
        index={routeInfo.index}
        path={routeInfo.path}
        element={protectRoutes(routeInfo)}
        key={routeInfo.path}
      />
    );
  };

  const protectRoutes = (routeInfo: CommonRouteProps) => {
    if (routeInfo.isAuth === false) {
      if (isLogin) {
        if (String(location.search).length > 0) {
          try {
            return <Navigate replace to={RoutePath.hypeCast} />;
          } catch (err) {
            return <Navigate replace to={RoutePath.home} />;
          }
        } else {
          return <Navigate replace to={RoutePath.home} />;
        }
      }
      return routeInfo.element;
    } else if (routeInfo.isAuth === null) {
      return routeInfo.element;
    } else if (routeInfo.isAuth === true) {
      if (isLogin) {
        return routeInfo.element;
      } else {
        return (
          <Navigate
            replace
            to={
              RoutePath.login +
              "?refer=" +
              encodeURIComponent(location.pathname)
            }
          />
        );
      }
    }
  };

  return ListRouteElements.map((item: CommonRouteProps) => {
    return CommonRoute(item);
  });
};
