/**
 * save data to local storage
 * @param {*} key
 * @param {*} data
 */
const SET_LOCAL_STORAGE_DATA = (key, data) => {
  localStorage.setItem(key, data);
};

/**
 * get data from local storage
 * @param {*} key
 */
const GET_LOCAL_STORAGE_DATA = (key) => {
  return localStorage.getItem(key);
};

/**
 * remove data from local storage particular key
 * @param {*} key
 */
const REMOVE_LOCAL_STORAGE_DATA = (key) => {
  localStorage.removeItem(key);
};

/**
 * remove all key and data from local storage
 */
const CLEAR_LOCAL_STORAGE = () => {
  localStorage.clear();
};

export const LocalStorageUtils = {
  SET_LOCAL_STORAGE_DATA,
  GET_LOCAL_STORAGE_DATA,
  REMOVE_LOCAL_STORAGE_DATA,
  CLEAR_LOCAL_STORAGE,
};
