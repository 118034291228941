import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";
import { getCleanQueryString } from "../user-profile";

const apiCallGetAllChatsUser = (params: any, token: string, user_type: any) => {
  let queryString = getCleanQueryString(params);
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.message.allchatlist + "?" + queryString}`,
      {},
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForReadMessages = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(endPoint.message.readNewMsg, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetAllConversation = (
  pageNo: string,
  reqBody: any,
  token: string
) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      pageNo.length > 0
        ? `${endPoint.message.conversation}?page=${pageNo}`
        : endPoint.message.conversation,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSearchfriend = (searchParam: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.message.searchFriend + searchParam}`,
      {},
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSendNewMessage = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.message.newMessage, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSharedProfileMessages = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.message.sharedMessagesAPI,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSendNewMediaMessage = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.message.sendMediaMessage,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallUpdateConversationStatus = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.message.updatestatus, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallforMessgeSeen = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.message.messageSeenStatus,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallforMessgeDelete = (params: any, token: string) => {
  let queryString = getCleanQueryString(params);
  return new Promise((resolve, reject) => {
    ServiceRequest.DeleteApiRequest(
      endPoint.message.deleteMessage + "?" + queryString,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

export const ServiceMessage = {
  apiCallGetAllChatsUser,
  apiCallGetAllConversation,
  apiCallSearchfriend,
  apiCallSendNewMessage,
  apiCallSendNewMediaMessage,
  apiCallUpdateConversationStatus,
  apiCallforMessgeSeen,
  apiCallforMessgeDelete,
  apiCallSharedProfileMessages,
  apiCallForReadMessages,
};
