import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideSnackbar, hideSocialSnackbar } from "../../store/reducer/Snackbar";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SocialSnackbar() {
  const dispatch = useDispatch();
  const { showSocial, message, type, bgColor } = useSelector(
    (state: any) => state.snackbar,
  );
  useEffect(() => {
    showSocial &&
      setTimeout(() => {
        dispatch(hideSocialSnackbar());
      }, 4000);
  }, [showSocial]);

  const handleClose = () => {
    dispatch(hideSocialSnackbar());
  };

  const AlertStyle = {
    background: bgColor ? "#000" : "#2D1F4C",
    marginTop: "40px",
    color: bgColor ? bgColor : "",
    border: bgColor ? `1px solid ${bgColor}` : "",
    borderRadius: bgColor ? "50px" : "",
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={showSocial}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={type} sx={AlertStyle}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
