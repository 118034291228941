import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceMessage, ServiceUserProfile } from "../../../api";
import hypeImage from "../../../assets/Images/hype1.jpg";
import DialogCommon from "../../../components/common/dialog-common";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { styles } from "../../../theming/Style/Styles";
import { PatterColor } from "../../../theming/Style/color.styles";
import { AppStringUtils } from "../../../utils";
import CommonSkeleton from "../../../components/common/skeleton";
import { Spinner } from "../../../components/common";
import ShareMessageDialog from "../../../components/common/ShareMessageDialog";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "50%",
    height: "550px",
    backgroundImage: `url(${hypeImage})`,
    overflow: "hidden",
    borderRadius: "20px",
  },
}));
const SharePopup = ({ uuid, isOpen, setIsOpen, postInfo }: any) => {
  const classes = useStyles();
  const { token, user_details } = useSelector(
    (state: any) => state.rLogin.infoLogin,
  );
  const t = AppStringUtils();

  const dispatch = useDispatch();

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<any>([]);
  const [showLimitExceededDialog, setShowLimitExceededDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleClose = () => {
    setIsOpen(false);
    setUsersList([]);
  };

  const [hasInitialDataFetched, setHasInitialDataFetched] = useState(false);

  const apiCallGetAllChatsOnce = () => {
    if (!hasInitialDataFetched) {
      apiCallGetAllChats("");
      setHasInitialDataFetched(true);
    }
  };

  const handleChange = (e: any) => {
    const searchText = e.target.value.toLowerCase();
    if (searchText.length === 0) {
      setFilteredData(data);
    } else {
      const filteredData = data.filter((item: any) => {
        const { username, first_name, last_name } = item;
        return (
          username.toLowerCase().includes(searchText) ||
          first_name.toLowerCase().includes(searchText) ||
          last_name.toLowerCase().includes(searchText)
        );
      });
      setFilteredData(filteredData);
    }
  };

  const apiCallGetAllChats = async (userName: any) => {
    setLoading(true);
    let dataParam = {
      user_uuid: user_details?.user_uuid,
      search: userName === "" ? "" : userName,
      list_type: "follower",
      page_size: 20,
      page: 1,
    };

    try {
      const res: any = await ServiceUserProfile.apiCallGetFollowerFollowing(
        dataParam,
        token,
      );
      let dataList = res.data.map((item: any) => {
        return { ...item?.requested_by_data };
      });
      setData(dataList);
      setLoading(false);
    } catch (err) {
      setData([]);
      setLoading(false);
    }
  };

  const apiCallForSharedPosts = (item: any) => {
    setLoader(true);
    const [{ uuid }] = postInfo;
    let reqBody = {
      text_url_shared_media: uuid,
      to_message_user_uuid: item.map((item1: any) => item1?.user_uuid),
      message_type: "Shared_Post",
    };
    ServiceMessage.apiCallSharedProfileMessages(reqBody, token)
      .then((res: any) => {
        if (res) {
          dispatch(
            showSocialSnackbar({ message: t.postSharedMsg, type: "success" }),
          );
        }
        setLoader(false);
        handleClose();
      })
      .catch((err: any) => {
        dispatch(
          showSocialSnackbar({ message: t.wentWrongMsg, bgColor: "#4DAE50" }),
        );
        setLoader(false);
        handleClose();
      });
  };

  useEffect(() => {
    apiCallGetAllChatsOnce();
  }, []);

  useEffect(() => {
    setUsersList([]);
  }, [isOpen]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{ className: classes.drawer }}
    >
      <Container>
        <Box sx={{ p: 2 }}>
          <DialogCommon dialogTitle={t.share} setIsOpen={setIsOpen} />
        </Box>

        <Divider color="grey" sx={styles.autoHW} />
        <DialogContent>
          <Box sx={styles.ReelsSharePopup}>
            <Box
              sx={{
                display: { xs: "block", md: "block" },
                padding: "5px 0",
              }}
            >
              <TextField
                className="input-plain-background"
                placeholder="Search..."
                fullWidth
                size="small"
                style={{
                  color: "white",
                  border: "1px solid #526156",
                  borderRadius: "17px ",
                }}
                InputLabelProps={{
                  style: { ...styles.inputLabel, color: "white" },
                }}
                onChange={handleChange}
              />
            </Box>
            {filteredData &&
              (loading ? Array.from(new Array(20)) : filteredData).map(
                (item: any, index: number) => {
                  return (
                    <Box className="hoverConver" sx={styles.popoverPost}>
                      {loading ? (
                        <CommonSkeleton
                          variant="rectangulars"
                          width="100%"
                          height="50px"
                          styles={{ borderRadius: "20px", marginBottom: "5px" }}
                        />
                      ) : (
                        <ListItem
                          sx={{
                            padding: "0px 0px",
                          }}
                        >
                          <ListItemButton
                            sx={{ color: PatterColor.white }}
                            onClick={() => {
                              const userUuidArray = usersList.map(
                                (item: any) => item.user_uuid,
                              );
                              const isUserSelected = userUuidArray.includes(
                                item.user_uuid,
                              );
                              if (isUserSelected) {
                                setUsersList(
                                  usersList.filter(
                                    (el: any) =>
                                      el.user_uuid !== item.user_uuid,
                                  ),
                                );
                              } else if (usersList.length < 5) {
                                setUsersList([...usersList, item]);
                              } else {
                                setShowLimitExceededDialog(true);
                              }
                            }}
                          >
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                marginRight: "20px",
                              }}
                              src={item.profile_image}
                              imgProps={{ loading: "lazy" }}
                            />
                            <ListItemText
                              primary={item.first_name + " " + item.last_name}
                            />
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  color: PatterColor.white,
                                }}
                                edge="end"
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={usersList
                                  ?.map((item: any) => item?.user_uuid)
                                  .includes(item?.user_uuid)}
                              />
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      )}
                    </Box>
                  );
                },
              )}
            {filteredData.length === 0 && (
              <Box sx={styles.postShareRecordStyle}>{t.noUserFound}</Box>
            )}
          </Box>
          <Divider color="grey" sx={styles.autoHW} />
          <Box>
            {loader ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Spinner isShow={loader} />
                </Box>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    ...styles.popoverPost,
                    color: PatterColor.white,
                    padding: "10px",
                    marginTop: "20px",
                  }}
                  // disabled={usersList?.length === 0 ? true : false}
                  onClick={() => {
                    return (
                      usersList?.length >= 1 && apiCallForSharedPosts(usersList)
                    );
                  }}
                >
                  {t.send}
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Container>
      <ShareMessageDialog
        showLimitExceededDialog={showLimitExceededDialog}
        setShowLimitExceededDialog={setShowLimitExceededDialog}
      />
    </Dialog>
  );
};

export default SharePopup;
