import axios from 'axios';
import { CreateUrl } from './create-url';

/**
 *
 * @param {} endpoint
 * @returns
 * pass endpoint to create url
 * create common string url
 */
const strUrl = (endpoint: string) => {
  return CreateUrl(endpoint);
};

/**
 * if token is exist then add in header object
 * @param token
 * @returns
 */
const addTokenInHeader = (token: string) => {
  let dictHeaders: any = {};
  if (token && token !== null && token.length > 0) {
    dictHeaders.Authorization = 'token ' + token;
  }
  return dictHeaders;
};

/**
 * common axios class
 * @returns
 */
const commonAxiosService = (dictRest: any, resolve: any, reject: any) => {
  return axios(dictRest)
    .then(function (response) {
      resolve(response.data);
    })
    .catch(function (error) {
      reject(error);
    })
    .then(function () {
      // always executed
    });
};

/**
 * GET method
 * pass endpoint
 * pass query in Object Type
 * @param {*} endpoint
 * @returns
 */
const GetApiRequest = (endpoint: string, query: any, token: string) => {
  return new Promise((resolve, reject) => {
    let dictHeaders: any = addTokenInHeader(token);
    let dictInfo = {
      method: 'GET',
      url: strUrl(endpoint),
      params: query,
      headers: dictHeaders,
    };
    commonAxiosService(dictInfo, resolve, reject);
  });
};

/**
 * POST method
 * @param {*} endpoint
 * @param {*} param
 * @param {*} token
 * @returns
 */
const PostApiRequest = (endpoint: string, param: any, token: string) => {
  return new Promise((resolve, reject) => {
    let dictHeaders: any = addTokenInHeader(token);
    let dictInfo = {
      method: 'POST',
      url: strUrl(endpoint),
      data: param,
      headers: dictHeaders,
    };
    commonAxiosService(dictInfo, resolve, reject);
  });
};

/**
 * PUT method
 * @param {*} endpoint
 * @param {*} param
 * @param {*} token
 * @returns
 */
const PutApiRequest = (endpoint: string, param: any, token: string) => {
  return new Promise((resolve, reject) => {
    let dictHeaders: any = addTokenInHeader(token);
    let dictInfo = {
      method: 'PUT',
      url: strUrl(endpoint),
      data: param,
      headers: dictHeaders,
    };
    commonAxiosService(dictInfo, resolve, reject);
  });
};

/**
 * DELETE method
 * pass endpoint
 * pass query in Object Type
 * @param {*} endpoint
 * @returns
 */
const DeleteApiRequest = (endpoint: string, token: string) => {
  return new Promise((resolve, reject) => {
    let dictHeaders: any = addTokenInHeader(token);
    let dictInfo = {
      method: 'DELETE',
      url: strUrl(endpoint),
      headers: dictHeaders,
    };
    commonAxiosService(dictInfo, resolve, reject);
  });
};

export const ServiceRequest = {
  GetApiRequest,
  PostApiRequest,
  PutApiRequest,
  DeleteApiRequest,
};
