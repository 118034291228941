import { Alert } from "@mui/material";

type AlertColor = "success" | "info" | "warning" | "error";

interface AlertBoxProps {
  message: string | "";
  alertType: AlertColor | "success";
}

const AlertBox = (props: AlertBoxProps) => {
  const { message, alertType } = props;
  return (
    <Alert variant="filled" severity={alertType}>
      {message}
    </Alert>
  );
};

export default AlertBox;
