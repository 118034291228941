import { Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { styles } from "../../theming/Style/Styles";

export default function AutoCompleteInput(props: any) {
  const {
    onClickCall,
    tagPeopleList,
    onValueSelectCall,
    setTagPeopleValue,
    selectedValues,
    optionValue1,
    optionValue2,
    defaultValue,
  } = props;
  const onKeyUpValue = (event: any) => {
    onClickCall(event.target.value);
  };
  const [valueSelected, setValueSelected] = React.useState<string[]>(
    defaultValue || [],
  );
  const [inputValue, setInputValue] = React.useState("");

  return (
    <Stack spacing={3}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={tagPeopleList || []}
        defaultValue={defaultValue}
        value={valueSelected}
        getOptionLabel={(option: any) =>
          option[optionValue1] + " " + option[optionValue2]
        }
        onChange={(event, newValue: any) => {
          // Filter out duplicates
          const uniqueValues = newValue.filter(
            (value: any, index: number, self: any[]) =>
              index ===
              self.findIndex(
                (v: any) =>
                  v[optionValue1] === value[optionValue1] &&
                  v[optionValue2] === value[optionValue2],
              ),
          );

          setValueSelected(uniqueValues);
          selectedValues(uniqueValues);
          onClickCall("");
        }}
        onInputChange={(event, newInputValue: any) => {
          setInputValue(newInputValue);
        }}
        renderTags={(tagValue, getTagProps) => (
          <div style={{ maxHeight: 100, overflowY: "auto" }}>
            {tagValue.map((option, index) => (
              <Chip
                sx={styles.tagChips}
                label={option[optionValue1] + " " + option[optionValue2]}
                {...getTagProps({ index })}
              />
            ))}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className="input-plain-background1"
            variant="outlined"
            fullWidth
            size="small"
            placeholder=""
            onKeyUp={onKeyUpValue}
            sx={{ ...styles.borderOutline, ...styles.tagChips }}
          />
        )}
      />
    </Stack>
  );
}
