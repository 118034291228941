import { CssBaseline, Grid, Box, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { styles } from "../../theming/Style/Styles";
import { getIsRouteHypeCast } from "../header";
import { RoutePath } from "../../routes/route-path";

export default function BackgroundImage(props: any) {
  const location = useLocation();
  const [valueLocation, setValueLocation] = useState(false);
  const paths = [
    RoutePath.login,
    RoutePath.signup,
    RoutePath.forgotPassword,
    RoutePath.reportBug,
  ];
  useEffect(() => {
    if (paths.some((path) => location.pathname.includes(path))) {
      setValueLocation(true);
    } else {
      setValueLocation(false);
    }
  }, [location]);

  const getIsPathMatch = (pathname: string) => {
    const match = matchPath(pathname, location.pathname);
    return match ? true : false;
  };

  const noAppBarRoutesMobile = [
    RoutePath.reels,
    RoutePath.profile,
    RoutePath.chatByUserId,
    RoutePath.createPost,
    RoutePath.editPost,
    RoutePath.profileInfo,
  ];
  const noAppBarRoutesDesktop = [RoutePath.profileInfo];

  const notOnMobile = noAppBarRoutesMobile.some((item: any) =>
    getIsPathMatch(item)
  );
  const notOnDesktop = noAppBarRoutesDesktop.some((item: any) =>
    getIsPathMatch(item)
  );

  return (
    <Box>
      {getIsRouteHypeCast() ? (
        <Box
          sx={{
            padding: {
              ...styles.height100VH,
              xs: "60px 10px",
              sm: "60px 10px",
              md: "90px 30px 50px 280px",
              background: "black",
            },
          }}
        >
          {props.children}
        </Box>
      ) : (
        <Grid container sx={{ ...styles.height100VH }}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            component={Paper}
            elevation={9}
            square
            sx={{
              ...(valueLocation ? styles.hypeImage : styles.hypeImage3),
            }}
          >
            <Box
              sx={{
                ...(valueLocation
                  ? styles.mainBox1
                  : {
                      ...styles.mainBox2,
                      my: { xs: notOnMobile ? 0 : 6, md: notOnDesktop ? 0 : 6 },
                    }),
              }}
            >
              {props.children}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
