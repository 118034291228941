import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import {
  CommentIcon,
  LikeIcon,
  NotLikeIcon,
  PostSaveIcon,
  PostUnSaveIcon,
  SendIcon,
} from "../../../components/common/icons";
import { styles } from "../../../theming/Style/Styles";
import { useNavigate } from "react-router-dom";
import VolumeButton from "./VolumeButton";
import ReelsMenu from "../components/ReelsMenu";
import { useDispatch } from "react-redux";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { AppStringUtils } from "../../../utils";
import { Spinner } from "../../../components/common";

const IconContainerStyle = {
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
  "&:hover": {
    opacity: 0.5,
  },
};

function formatNumber(number: number): string {
  if (!number) return "0";
  const absNumber = Math.abs(number);
  if (absNumber < 1000) {
    return number.toString();
  }

  const abbreviations: { [key: string]: number } = { K: 1e3, M: 1e6, B: 1e9 };
  const abbreviation = Object.keys(abbreviations).find(
    (key) => absNumber < abbreviations[key as keyof typeof abbreviations],
  ) as keyof typeof abbreviations;

  return (number / abbreviations[abbreviation]).toFixed(1) + abbreviation;
}

export default function ReelsPostIcons({
  data,
  setSharePopup,
  setCommentPopup,
  setPostId,
  commentCount,
  setIndex,
  muted,
  index,
  setMobileCommentPopup,
  setMobileSharePopup,
  setPostData,
  reelsData,
  setReelsData,
  setReelsInfo,
  props,
}: any) {
  const { token } = useSelector((state: any) => state.rLogin.infoLogin);
  const dispatch = useDispatch();
  const t = AppStringUtils();
  const [isLoader, setIsLoader] = useState(false);

  const router = useNavigate();
  const [likeData, setLikeData] = useState({
    isLike: data?.is_like,
    likesCount: data?.likes_count,
  });
  const [isSaved, setIsSaved] = useState(data?.is_saved);
  const handleLike = () => {
    setLikeData({
      ...likeData,
      likesCount: likeData?.isLike
        ? likeData?.likesCount - 1
        : likeData?.likesCount + 1,
      isLike: !likeData?.isLike,
    });
    ServiceDashBoard.apiCallPostLikePost(data?.uuid, token);
  };

  const handleShare = () => {
    document.body.clientWidth > 900
      ? setSharePopup(true)
      : setMobileSharePopup(true);
    setPostId(data?.uuid);
    setReelsInfo(data?.post_files);
  };

  const handleComment = () => {
    setPostData(data);
    document.body.clientWidth > 900
      ? setCommentPopup(true)
      : setMobileCommentPopup(true);
    setPostId(data?.uuid);
    setIndex(index);
  };

  const handleSaved = () => {
    setIsSaved(!isSaved);
    isSaved ? handleUnsavedPost() : handleSavedUnsavedPost();
  };

  const handleSavedUnsavedPost = () => {
    setIsLoader(true);
    ServiceDashBoard.apiCallPostSavePost(data?.uuid, token)
      .then((res) => {
        dispatch(
          showSocialSnackbar({
            message: t.textPostAddedMessage,
            type: "success",
          }),
        );
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        return;
      });
  };

  const handleUnsavedPost = () => {
    setIsLoader(true);
    ServiceDashBoard.apiCallDeleteSavedPost(data?.uuid, token)
      .then((res) => {
        dispatch(
          showSocialSnackbar({
            message: t.textPostRemovedMessage,
            type: "success",
          }),
        );
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        return;
      });
  };

  useEffect(() => {
    setLikeData({
      isLike: data?.is_like,
      likesCount: data?.likes_count,
    });
    setIsSaved(data?.is_saved);
  }, [data]);

  return (
    <Box sx={styles.ReelsPostIconBox}>
      <VolumeButton muted={muted} />
      {data?.is_liked_allowed ? (
        <>
          <Box onClick={handleLike} sx={{ textAlign: "center" }}>
            <Box sx={IconContainerStyle}>
              {likeData?.isLike ? <LikeIcon /> : <NotLikeIcon />}
            </Box>
            <Typography variant="body2" sx={styles.ReelsPostIconText}>
              {formatNumber(likeData?.likesCount)}
            </Typography>
          </Box>
        </>
      ) : (
        <></>
      )}
      {data?.is_comment_allowed ? (
        <>
          <Box onClick={handleComment} sx={{ textAlign: "center" }}>
            <Box sx={IconContainerStyle}>
              <CommentIcon />
            </Box>
            <Typography variant="body2" sx={styles.ReelsPostIconText}>
              {formatNumber(commentCount)}
            </Typography>
          </Box>
        </>
      ) : (
        <></>
      )}

      {data?.is_post_share_as_msg_allowed ? (
        <>
          <Box sx={IconContainerStyle} onClick={handleShare}>
            <SendIcon />
          </Box>
        </>
      ) : (
        <></>
      )}
      {data?.is_saved_allowed ? (
        <>
          <Box sx={IconContainerStyle} onClick={handleSaved}>
            {isLoader ? (
              <Spinner isShow={isLoader} />
            ) : (
              <>{isSaved ? <PostSaveIcon /> : <PostUnSaveIcon />}</>
            )}
          </Box>
        </>
      ) : (
        <></>
      )}
      <Box sx={IconContainerStyle}>
        {
          <ReelsMenu
            item={data}
            index={index}
            data={reelsData}
            setData={setReelsData}
            uuid={data?.uuid}
            userId={data?.posted_by_data?.user_uuid}
            setIsRefresh={props?.setIsRefresh}
          />
        }
      </Box>
    </Box>
  );
}
