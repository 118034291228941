import { lazy } from "react";

const UserDetails = lazy(() => import("./user-details"));
const SideMenu = lazy(() => import("./side-menu"));
const Security = lazy(() => import("./security"));
const Privacy = lazy(() => import("./privacy"));
const Permission = lazy(() => import("./permission"));
const Notification = lazy(() => import("./notification"));
const About = lazy(() => import("./about"));
const FeedControl = lazy(() => import("./feed-control"));
const ReportBug = lazy(() => import("./report-bug"));

export {
  UserDetails,
  SideMenu,
  Security,
  Privacy,
  Permission,
  Notification,
  About,
  FeedControl,
  ReportBug,
};
