import { Button } from "@mui/material";

interface CommonCommonButton {
  buttonText: any | "";
  buttonType?: any | "";
  onClicked?: () => any;
  buttonStyle?: any;
  classStyle?: any;
  buttonSize?: any;
  style?: any;
  className?: string;
  disabled?: boolean;
  sx?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  buttonIcon?: any;
  height?: any;
  width?: any;
}

export default function CommonButton(props: CommonCommonButton) {
  const value = props;
  const { buttonStyle } = props;
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClicked) {
          props.onClicked();
        }
      }}
      size={value.buttonSize ? value.buttonSize : "small"}
      sx={
        buttonStyle && Object.keys(buttonStyle).length > 0
          ? buttonStyle
          : { alignSelf: "center" }
      }
      className={value.classStyle ? value.classStyle : "gradient-button1"}
      type={value?.buttonType}
      fullWidth
      startIcon={props?.buttonIcon}
      {...props}
    >
      {value.buttonText}
    </Button>
  );
}
