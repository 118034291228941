import { ExploreOutlined } from "@mui/icons-material";
import HomeIcon from "../../assets/Icons/HomeIcon";
import ReelsIcon from "../../assets/Icons/ReelsIcon";
import { RoutePath } from "../../routes/route-path";
import HypeCastIcon from "../../assets/Icons/HypeCastIcon";
import SettingsIcon from "../../assets/Icons/SettingsIcon";
import MessageIcon from "../../assets/Icons/MessageIcon";
import CreatePostIcon from "../../assets/Icons/CreatePostIcon";
import NotificationIcon from "../../assets/Icons/NotificationIcon";

export const Navigates = [
  {
    route: RoutePath.home,
    title: "Home",
    Icon: HomeIcon,
    navIndex: 1,
  },
  {
    route: RoutePath.reels,
    title: "Reels",
    Icon: ReelsIcon,
    navIndex: 2,
  },
  {
    route: RoutePath.explore,
    title: "Explore",
    Icon: ExploreOutlined,
    navIndex: 3,
  },
  {
    route: RoutePath.hypeCast,
    title: "Hype Cast",
    Icon: HypeCastIcon,
    navIndex: 0,
  },
  {
    route: "",
    title: "Notification",
    Icon: NotificationIcon,
    navIndex: 4,
  },
  {
    route: false,
    title: "New Post",
    Icon: CreatePostIcon,
    navIndex: 5,
  },
  {
    route: RoutePath.messageGeneral,
    title: "Chat",
    Icon: MessageIcon,
    navIndex: 6,
  },
  {
    route: RoutePath.profile,
    title: "Settings",
    Icon: SettingsIcon,
    navIndex: 7,
  },
];
