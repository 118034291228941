import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography } from "@mui/material";
import { redirect } from "next/dist/server/api-utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { ServiceDashBoard } from "../../../../api";
import SettingsIcon from "../../../../assets/Icons/SettingsIcon";
import { RoutePath } from "../../../../routes/route-path";
import { styles } from "../../../../theming/Style/Styles";
import { PatterColor } from "../../../../theming/Style/color.styles";
import { NavigationUtils } from "../../../../utils";
import AutoCompleteSearch from "../../../common/auto-complete";
import { UserTypes } from "../../../common/enums/user-types";
import { Logo } from "../../../common/icons";
import HamburgerMenu from "./HamburgerMenu";

const MobileAppBar = (props: any) => {
  const { count } = props;
  const { token, user_type, user_uuid } = useSelector(
    (state: any) => state?.rLogin?.infoLogin || {},
  );
  const router = useNavigate();
  const { title } = useSelector((state: any) => state.appBar);
  const navigation = NavigationUtils();
  const location = useLocation();
  const [userList, setUserList] = useState([]);

  const btnNavClicked = (pathName: string) => {
    navigation.navigate({ pathName });
  };
  const apiCallGetUserList = (user: any) => {
    if (user) {
      ServiceDashBoard.apiCallGetUserList(user, token)
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {});
    } else if (user === "") {
      setUserList([]);
    }
  };
  const HomeAppBar = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box onClick={() => router(RoutePath.home)}>
        <Logo style={styles.width50} />
      </Box>
      {user_type !== UserTypes.basic && (
        <HamburgerMenu btnNavClicked={btnNavClicked} count={count} />
      )}
    </Box>
  );

  const userProfileAppBar = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box onClick={() => router(RoutePath.home)}>
        <Logo style={styles.width50} />
      </Box>
      <SettingsIcon
        onClick={() => {
          btnNavClicked(RoutePath.profile);
        }}
      />
    </Box>
  );
  const notificationAppBar = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          textTransform: "capitalize",
          color: PatterColor.white,
          fontSize: 16,
          fontWeight: "700",
        }}
      >
        Notifications
      </Typography>
    </Box>
  );

  const exploreAppBar = (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
      <Box
        sx={{
          width: 28,
          height: 28,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 15,
          display: "flex",
          color: "white",
        }}
      >
        <ArrowBackIcon onClick={() => router(RoutePath.home)} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <AutoCompleteSearch
          onClickCall={apiCallGetUserList}
          listData={userList}
          onValueSelectCall={redirect}
          route={router}
          optionValue1={"first_name"}
          optionValue2={"last_name"}
          optionValue3={"username"}
          optionValue4={"profile_image"}
          optionValue5={"user_uuid"}
          inputStyles={{
            borderRadius: 5,
            backgroundColor: PatterColor.secondaryBlack,
          }}
        />
      </Box>
    </Box>
  );
  const userId = location.pathname.includes("/user-profile")
    ? location.pathname.split("/")[2]
    : "";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: {
          xs:
            location.pathname === RoutePath.explore
              ? "center"
              : "space-between",
          md: "center",
        },
      }}
    >
      {location.pathname === RoutePath.home ? (
        <>{HomeAppBar}</>
      ) : location.pathname === RoutePath.explore ? (
        <>{exploreAppBar}</>
      ) : user_uuid === userId ? (
        <>{userProfileAppBar}</>
      ) : location.pathname === RoutePath.notification ? (
        <>{notificationAppBar}</>
      ) : location.pathname === RoutePath.userPostsByTypeReels ? (
        <Box sx={{ display: "none" }}></Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              width: 28,
              height: 28,
              my: 0.5,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 15,
              display: "flex",
              color: "white",
            }}
          >
            <ArrowBackIcon
              onClick={() => {
                if (location.pathname.includes("/chat/inbox")) {
                  router("/");
                } else {
                  router(-1);
                }
              }}
            />
          </Box>
          {!location.pathname.includes("user-profile") && (
            <Typography
              sx={{
                textTransform: "capitalize",
                color: PatterColor.white,
                fontSize: 16,
                fontWeight: "700",
              }}
            >
              {title}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MobileAppBar;
