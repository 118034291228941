import React from "react";

const NotificationIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={"app-bar-icons"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99717 1.88544C5.67145 1.88544 4.22645 4.89687 4.22645 6.88758C4.22645 8.37544 4.44217 7.93758 3.61859 9.75258C2.61288 12.339 6.65717 13.3962 8.99717 13.3962C11.3365 13.3962 15.3807 12.339 14.3757 9.75258C13.5522 7.93758 13.7679 8.37544 13.7679 6.88758C13.7679 4.89687 12.3222 1.88544 8.99717 1.88544Z"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7295 15.3839C9.75881 16.4682 8.24453 16.4811 7.26453 15.3839"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationIcon;
