import { KeyboardArrowLeft, Send } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";
import Loader from "../../common/loader";
import CommentBox from "./components/CommentBox";
type Anchor = "top" | "left" | "bottom" | "right";

function removeAtNamePrefix(input: string) {
  return input.replace(/^@\w+\s*/, "");
}

export default function MobileCommentPopup(props: any) {
  let [pageSize, pageNumber] = [20, 1];
  const { postData, setPostData } = props;
  const [page, setPage] = React.useState(1);
  const t = AppStringUtils();
  const [isLoader, setIsLoader] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const inputRef: any = React.useRef(null);
  const [commentUuuid, setCommentUuid] = React.useState("");
  const [isReply, setIsReply] = React.useState(false);

  const [isInputDisabled, setIsInputDisabled] = React.useState(false);

  const [commentList, setCommentList] = React.useState({
    commentData: new Array(),
    hasNextPageCommentList: undefined,
  });

  const commentData = commentList.commentData;
  const hasNextPageCommentList = commentList.hasNextPageCommentList;
  const { token, user_uuid } = useSelector(
    (state: any) => state.rLogin.infoLogin,
  );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (!isInputDisabled && inputRef.current.value.trim() !== "") {
        setIsInputDisabled(true);
        let reqBody = {
          comment: removeAtNamePrefix(inputRef.current.value),
          comment_uuid: commentUuuid,
        };
        addComment(props?.info?.uuid, reqBody);
      }
    }
  };

  const commentONPost = async (event: any) => {
    event.preventDefault();
    const comment = inputRef.current.value;

    if (comment.trim() !== "" && !isInputDisabled) {
      setIsInputDisabled(true);

      if (isReply) {
        let reqBody = {
          comment: removeAtNamePrefix(comment),
          comment_uuid: commentUuuid,
        };
        addComment(props?.info?.uuid, reqBody);
      } else {
        let reqBody = {
          comment: comment,
        };
        addComment(props?.info?.uuid, reqBody);
      }
    }
  };

  const resetCommentList = async () => {
    await setCommentList({
      commentData: [],
      hasNextPageCommentList: undefined,
    });
  };
  const handleClose = () => {
    props?.setShowPopup(false);
    resetCommentList();
    setPage(1);
    pageNumber = 1;
  };

  const scrollContainerRef = React.useRef<any>();

  const fetchMoreData = () => {
    hasNextPageCommentList && setCurrentPage(currentPage + 1);
  };

  const addComment = (postId: any, reqBody: any) => {
    if (!isDisabled) {
      return ServiceDashBoard.apiCallPostAddComment(postId, reqBody, token)
        .then((res: any) => {
          inputRef.current.value = "";

          apiCallGetCommentByPostId(pageNumber, props?.info?.uuid);
          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
        })
        .finally(() => {
          setIsInputDisabled(false);
        });
    }
    return;
  };
  const replyOnComment = (commentData: any) => {
    setIsReply(true);
    document.getElementById("comment-input")?.focus();
    inputRef.current.value = "@" + commentData.commented_by_data.username + " ";
    setCommentUuid(commentData?.uuid);
  };

  const apiCallGetCommentByPostId = (
    pageNumber: number,
    postId: string,
    isCommentAdded = false,
  ) => {
    setIsLoader(true);
    ServiceDashBoard.apiCallGetCommentOnPost(
      pageNumber,
      pageSize,
      postId,
      token,
    )
      .then((res: any) => {
        setPostData({
          ...postData,
          commentCount: res?.count,
          comment_count: res?.count,
        });
        setCommentList({
          commentData:
            hasNextPageCommentList && currentPage !== 1
              ? [...commentList?.commentData, ...res.data]
              : [...res.data],
          hasNextPageCommentList: res.links.next,
        });
        setIsLoader(false);
      })
      .catch((err) => {
        setTimeout(() => {}, 3000);
        setIsLoader(false);
      });
  };
  React.useEffect(() => {
    setCommentList({
      commentData: new Array(),
      hasNextPageCommentList: undefined,
    });

    !hasNextPageCommentList && setCurrentPage(1);

    props?.info?.uuid &&
      props?.showPopup &&
      apiCallGetCommentByPostId(currentPage, props?.info?.uuid, true);
  }, [currentPage, props?.info?.uuid, props?.showPopup]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={props?.showPopup}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
        >
          <Box sx={styles.mobileCommentBox}>
            <Box sx={styles.mobileCommentBox2}>
              <Box sx={styles.mobileCommentBox3}>
                <KeyboardArrowLeft
                  onClick={handleClose}
                  sx={{ fontSize: "30px", color: "#1976D2" }}
                />
              </Box>

              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                {"Comments"}
              </Typography>
              <Box sx={styles.mobileCommentBox4}>
                <CloseIcon
                  onClick={handleClose}
                  sx={{ fontSize: "25px", color: "#1976D2" }}
                />
              </Box>
            </Box>
            <Box
              sx={styles.mobileCommentBox5}
              role="presentation"
              ref={scrollContainerRef}
            >
              {commentList?.commentData?.length === 0 && !isLoader ? (
                <Typography
                  sx={{
                    ...styles.textInformation2,
                  }}
                >
                  {t.noCommentsYet}
                </Typography>
              ) : (
                <InfiniteScroll
                  dataLength={commentList?.commentData?.length ?? 0}
                  next={fetchMoreData}
                  hasMore={commentList?.hasNextPageCommentList ? true : false}
                  loader={props?.isLoader && page > 1 && <Loader />}
                  scrollableTarget={scrollContainerRef.current}
                >
                  {(isLoader
                    ? Array.from(new Array(10))
                    : commentList?.commentData
                  ).map((item: any, index: any) => (
                    <CommentBox
                      key={index}
                      isLoader={isLoader}
                      {...item}
                      replyOnComment={replyOnComment}
                      apiCallGetCommentByPostId={apiCallGetCommentByPostId}
                      pageNumber={page}
                      postUuid={props?.info?.uuid}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </Box>
            <Box>
              <Divider
                color="grey"
                sx={{
                  ...styles.autoHW,
                  my: 1,
                }}
              />
              <Box sx={{ marginTop: "", display: "flex" }}>
                <input
                  className="input-plain-background6"
                  id="comment-input"
                  ref={inputRef}
                  placeholder="Comments"
                  onKeyDown={handleKeyDown}
                  type="text"
                  disabled={isInputDisabled}
                />
                <IconButton
                  disabled={isInputDisabled}
                  onClick={commentONPost}
                  edge="end"
                  sx={{
                    color: "#F6F6F9 !important",
                    position: "relative",
                    marginLeft: "-40px",
                  }}
                >
                  <Send />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
