import { createSlice } from "@reduxjs/toolkit";
interface PlayerReducerProps {
  isMuted: boolean | true;
}
interface PlayerPlayReducerProps {
  isPlay: boolean | false;
}
const PlayerSlice = createSlice({
  name: "playerSlice",
  initialState: {
    isMuted: true,
    isPlay: false,
  },
  reducers: {
    actionPlayer(state: PlayerReducerProps, action) {
      state.isMuted = action.payload;
    },
    actionPlayerPlay(state: PlayerPlayReducerProps, action) {
      state.isPlay = action.payload;
    },
  },
});

export const { actionPlayer, actionPlayerPlay } = PlayerSlice.actions;

export default PlayerSlice.reducer;
