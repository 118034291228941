import { GpsFixed } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ReelsCaption = ({ content, postLocation, postedByData }: any) => {
  const isContentBig = content?.length > 50;
  const collapsible = {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 1.0s ease-in-out",
  };
  const expanded = {
    maxHeight: "500px",
    transition: "max-height 1.0s ease-in-out",
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    isContentBig && setIsExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box sx={{ maxWidth: { xs: "75vw" } }}>
      <Typography
        sx={{
          marginBottom: "-10px",
          color: "white",
          marginTop: "10px",
          fontSize: { xs: 10, sm: 12, md: 14 },
        }}
      >
        {postedByData?.username}
      </Typography>
      {!isExpanded ? (
        <Typography
          sx={{
            color: "white",
            marginTop: "10px",
            fontSize: { xs: 10, sm: 12, md: 14 },
          }}
          onClick={toggleCollapse}
        >
          {isContentBig ? <span>{content.slice(0, 51)}</span> : content}
          {isContentBig && <span>...more</span>}
        </Typography>
      ) : (
        <Box onClick={toggleCollapse}>
          <Typography
            sx={{
              ...collapsible,
              ...expanded,
              color: "white",
              marginTop: "10px",
              fontSize: { xs: 10, sm: 12, md: 14 },
            }}
          >
            {content}
          </Typography>
        </Box>
      )}
      {postLocation?.full_address && (
        <Box>
          <Typography
            sx={{
              ...collapsible,
              ...expanded,
              color: "white",
              marginTop: 1 / 4,
              fontSize: { xs: 10, sm: 12, md: 14 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1 / 3,
              }}
            >
              <GpsFixed sx={{ fontSize: 16 }} />
              {`${postLocation?.full_address?.split(",")[0]?.trim()}`}
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReelsCaption;
