import { ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import hypeImage from "../../../assets/Images/hype1.jpg";
import { styles } from "../../../theming/Style/Styles";
type Anchor = "top" | "left" | "bottom" | "right";
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "25%",
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "start",
    backdropFilter: " blur(250px)",
    overflow: "hidden",
    borderRadius: "30px",
  },
  boxStyle: {
    padding: "8px 0px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "10px",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    background: "rgb(28, 26, 26)",
    width: "100%",
  },
}));
export default function MobilePostMenuPopup(props: any) {
  const { infoLogin } = useSelector((state: any) => state?.rLogin || {});
  const { user_details } = infoLogin;
  const classes = useStyles();
  const { postedByData } = props;
  const userId = postedByData?.user_uuid;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const ref: any = React.useRef();
  const handleClose = () => {
    props?.setShowPopup(false);
  };
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        handleClose();
      }
    };

    if (props?.showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props?.showPopup]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={props?.showPopup}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
        >
          <Box
            sx={{
              p: 2,
              width: "auto",
              background: "black",
              color: "white",
            }}
          >
            <Box ref={ref}>
              <Box sx={styles.alignCenter}>
                <Box sx={{ width: "100%" }}>
                  {userId === user_details?.user_uuid ? (
                    <>
                      {props.iconData1?.map((item: any, index: number) => {
                        return (
                          <>
                            <ListItem
                              className={classes.boxStyle}
                              key={index}
                              onClick={item.onClicked}
                            >
                              {item.icon}
                              {item.text}
                            </ListItem>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {props.iconData2?.map((item: any, index: number) => {
                        return (
                          <>
                            {item && (
                              <ListItem
                                key={index}
                                className={classes.boxStyle}
                                onClick={item.onClicked}
                              >
                                {item.icon}
                                {item.text}
                              </ListItem>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
