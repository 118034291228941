import { KeyboardArrowLeft } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceMessage, ServiceUserProfile } from "../../../api";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { styles } from "../../../theming/Style/Styles";
import { PatterColor } from "../../../theming/Style/color.styles";
import { AppStringUtils } from "../../../utils";
import { Spinner } from "../../../components/common";
import CommonSkeleton from "../../../components/common/skeleton";
import ShareMessageDialog from "../../../components/common/ShareMessageDialog";

const MobileSharePopup = ({ uuid, isOpen, setIsOpen, postInfo }: any) => {
  const t = AppStringUtils();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const { token, user_details } = useSelector(
    (state: any) => state.rLogin.infoLogin,
  );
  const dispatch = useDispatch();

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showLimitExceededDialog, setShowLimitExceededDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleClose = () => {
    setUsersList([]);
    setIsOpen(false);
  };

  const apiCallGetAllChats = (userName: any) => {
    setIsLoader(true);
    let dataParam = {
      user_uuid: user_details?.user_uuid,
      search: userName === "" ? "" : userName,
      list_type: "follower",
      page_size: 20,
      page: 1,
    };
    ServiceUserProfile.apiCallGetFollowerFollowing(dataParam, token)
      .then((res: any) => {
        let dataList = res.data.map((item: any) => {
          return { ...item?.requested_by_data };
        });
        setData(dataList);
        setIsLoader(false);
      })
      .catch((err: any) => {
        setData([]);
        setIsLoader(false);
      });
  };

  const apiCallForSharedPosts = (item: any) => {
    setLoading(true);
    const [{ uuid }] = postInfo;
    let reqBody = {
      text_url_shared_media: uuid,
      to_message_user_uuid: item.map((item1: any) => item1?.user_uuid),
      message_type: "Shared_Post",
    };
    ServiceMessage.apiCallSharedProfileMessages(reqBody, token)
      .then((res: any) => {
        if (res) {
          dispatch(
            showSocialSnackbar({ message: t.postSharedMsg, type: "success" }),
          );
        }
        setLoading(false);
        setUsersList([]);
      })
      .catch((err: any) => {
        dispatch(
          showSocialSnackbar({ message: t.wentWrongMsg, bgColor: "#4DAE50" }),
        );
        setLoading(false);
      });
  };

  const handleChange = (e: any) => {
    const searchText = e.target.value.toLowerCase();
    if (searchText.length === 0) {
      setFilteredData(data);
    } else {
      const filteredData = data.filter((item: any) => {
        const { username, first_name, last_name } = item;
        return (
          username.toLowerCase().includes(searchText) ||
          first_name.toLowerCase().includes(searchText) ||
          last_name.toLowerCase().includes(searchText)
        );
      });
      setFilteredData(filteredData);
    }
  };

  useEffect(() => {
    isOpen && apiCallGetAllChats("");
  }, [isOpen]);

  useEffect(() => {
    setUsersList([]);
  }, [isOpen]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={isOpen}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
        >
          <Box sx={styles.mobileShareBox}>
            <Box sx={styles.mobileShareBox2}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: PatterColor.fourthGray,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 15,
                  display: "flex",
                  marginRight: 1,
                }}
              >
                <KeyboardArrowLeft
                  onClick={handleClose}
                  sx={{ fontSize: "30px", color: "#1976D2" }}
                />
              </Box>
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                Share
              </Typography>
              <Box sx={{ ...styles.mobileShareCloseIcon, marginRight: 1 }}>
                <CloseIcon
                  onClick={handleClose}
                  sx={{ fontSize: "25px", color: "#1976D2" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                pt: 1,
              }}
            >
              <TextField
                className="input-plain-background"
                placeholder="Search..."
                fullWidth
                size="small"
                style={{
                  color: "white",
                  border: "1px solid #526156",
                  borderRadius: "17px ",
                }}
                InputLabelProps={{
                  style: { ...styles.inputLabel, color: "white" },
                }}
                onChange={handleChange}
              />
            </Box>
            <Box sx={styles.mobilePostSharePopup} role="presentation">
              {(isLoader ? Array.from(new Array(20)) : filteredData)?.map(
                (item: any, index: number) => {
                  return (
                    <Box
                      className="hoverConver"
                      sx={{
                        ...styles.popoverPost,
                        padding: 0,
                        mx: 0,
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <ListItem
                        sx={{
                          padding: "0px 0px",
                        }}
                      >
                        {isLoader ? (
                          <CommonSkeleton
                            variant="rectangulars"
                            width="100%"
                            height="50px"
                            styles={{ borderRadius: "20px" }}
                          />
                        ) : (
                          <ListItemButton
                            sx={{ color: PatterColor.white }}
                            onClick={() => {
                              const userUuidArray = usersList.map(
                                (item: any) => item.user_uuid,
                              );
                              const isUserSelected = userUuidArray.includes(
                                item.user_uuid,
                              );
                              if (isUserSelected) {
                                setUsersList(
                                  usersList.filter(
                                    (el: any) =>
                                      el.user_uuid !== item.user_uuid,
                                  ),
                                );
                              } else if (usersList.length < 5) {
                                setUsersList([...usersList, item]);
                              } else {
                                setShowLimitExceededDialog(true);
                                setIsOpen(false);
                              }
                            }}
                          >
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                marginRight: "10px",
                              }}
                              src={item.profile_image}
                              imgProps={{ loading: "lazy" }}
                            />
                            <ListItemText
                              primary={item.first_name + " " + item.last_name}
                            />
                            <ListItemIcon
                              sx={{
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Checkbox
                                sx={{
                                  color: PatterColor.white,
                                }}
                                edge="end"
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={usersList
                                  ?.map((item: any) => item?.user_uuid)
                                  .includes(item?.user_uuid)}
                              />
                            </ListItemIcon>
                          </ListItemButton>
                        )}
                      </ListItem>
                    </Box>
                  );
                },
              )}
              {filteredData.length === 0 && (
                <Box sx={styles.postShareRecordStyle}>{t.noUserFound}</Box>
              )}
            </Box>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Spinner isShow={loading} />
              </Box>
            ) : (
              <Button
                sx={{
                  ...styles.popoverPost,
                  color: PatterColor.white,
                  height: "40px",
                }}
                // disabled={usersList?.length === 0 ? true : false}
                onClick={() => {
                  return (
                    usersList?.length >= 1 && apiCallForSharedPosts(usersList)
                  );
                }}
              >
                {t.send}
              </Button>
            )}
          </Box>
        </SwipeableDrawer>
        <ShareMessageDialog
          showLimitExceededDialog={showLimitExceededDialog}
          setShowLimitExceededDialog={setShowLimitExceededDialog}
        />
      </React.Fragment>
    </div>
  );
};

export default MobileSharePopup;
