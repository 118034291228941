import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { styles } from "../../theming/Style/Styles";
import { PatterColor } from "../../theming/Style/color.styles";

export default function DialogCommon(props: any) {
  return (
    <Box sx={styles.dialogCommon}>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#FFF" }}>
          {props.dialogTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          width: 30,
          height: 30,
          backgroundColor: PatterColor.fourthGray,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 15,
          display: "flex",
        }}
      >
        <CloseIcon
          sx={{ ...styles.pointer, fontSize: "25px", color: "#1976D2" }}
          onClick={() => {
            props.setIsOpen(false);
            props.handleClose && props.handleClose();
          }}
        />
      </Box>
    </Box>
  );
}
