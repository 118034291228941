import { Box, CardMedia } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useSelector } from "react-redux";
import { ImagePath } from "../../assets/Images";
import { styles } from "../../theming/Style/Styles";

const MediaPreview = (props: any) => {
  const [postTypeVideo, postTypeImage] = ["video", "image"];
  const { croppedFiles } = useSelector((state: any) => state.createPost);

  return (
    <>
      {croppedFiles?.length === 1 &&
      croppedFiles[0]?.type?.includes(postTypeVideo) ? (
        <Box>
          <video autoPlay loop={true} width="100%" style={{ height: "80vh" }}>
            <source
              src={
                props.fileDetails !== null
                  ? URL.createObjectURL(croppedFiles[0])
                  : ""
              }
              type="video/mp4"
            />
          </video>
        </Box>
      ) : croppedFiles?.length === 1 &&
        croppedFiles[0].type.includes(postTypeImage) ? (
        <CardMedia
          component="img"
          loading="lazy"
          sx={{
            ...styles.postCreateImage3,
            ...props.style,
          }}
          image={
            croppedFiles[0]
              ? URL.createObjectURL(croppedFiles[0])
              : ImagePath.ImgPostTest
          }
        />
      ) : (
        <Carousel
          autoPlay={false}
          cycleNavigation={false}
          swipe={true}
          navButtonsAlwaysVisible={true}
          navButtonsAlwaysInvisible={false}
          sx={{
            ...props.style,
            width: "100%",
            maxWidth: 1150,
          }}
        >
          {croppedFiles?.map((item: any, index: any) => (
            <>
              {item?.type.includes(postTypeImage) ? (
                <CardMedia
                  component="img"
                  loading="lazy"
                  sx={{ height: "75vh", width: "100%", objectFit: "contain" }}
                  src={URL.createObjectURL(item)}
                />
              ) : (
                <video
                  autoPlay
                  loop={true}
                  width="100%"
                  style={{ height: "75vh" }}
                >
                  <source src={URL.createObjectURL(item)} type="video/mp4" />
                </video>
              )}
            </>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default MediaPreview;
