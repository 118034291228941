import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { primaryColor } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  drawer2: {
    backgroundColor: "#282956 !important",
    overflow: "hidden",
    borderRadius: "30px",
    minWidth: "300px",
  },
}));

export default function UserBlockPopup(props: any) {
  const { isShow, isShowUnblock, setBlock, setUnblock, handleClose } = props;
  const classes = useStyles();
  const t = AppStringUtils();

  return (
    <>
      <Dialog
        open={isShow || isShowUnblock}
        onClose={handleClose}
        maxWidth="xs"
        PaperProps={{ className: classes.drawer2 }}
      >
        <DialogTitle
          sx={{
            color: primaryColor,
            m: 1,
          }}
        >
          {isShowUnblock && t.blockUnblockUserWarning}
          {isShow && t.blockUserWarning}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              textAlign: "center",
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                color: primaryColor,
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ px: 2, cursor: "pointer" }} onClick={handleClose}>
                {"Cancel"}
              </Box>
              <Box
                sx={{ px: 2, cursor: "pointer" }}
                onClick={(isShow && setBlock) || (isShowUnblock && setUnblock)}
              >
                {"OK"}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
