import { createSlice } from "@reduxjs/toolkit";

interface SearchReducerProps {
  show: Boolean;
  showSocial: Boolean;
  message: any;
  type?: string;
  bgColor?: any;
}

const initialStates: SearchReducerProps = {
  show: false,
  showSocial: false,
  message: "",
  type: "",
  bgColor: "",
};

const SnackbarSlice = createSlice({
  name: "snackbar",
  initialState: initialStates,
  reducers: {
    showSnackbar(
      state: SearchReducerProps,
      action: { payload: { message: any } },
    ) {
      state.show = true;
      state.message = action.payload.message;
    },
    hideSnackbar(state: SearchReducerProps) {
      state.show = false;
    },
    showSocialSnackbar(state: SearchReducerProps, action: any) {
      state.showSocial = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.bgColor = action.payload.bgColor;
    },
    hideSocialSnackbar(state: SearchReducerProps) {
      state.showSocial = false;
    },
  },
});

export const {
  showSnackbar,
  hideSnackbar,
  showSocialSnackbar,
  hideSocialSnackbar,
} = SnackbarSlice.actions;
export default SnackbarSlice.reducer;
