import { createSlice } from "@reduxjs/toolkit";

interface createPostProps {
  title: string;
}

const initialStates: createPostProps = {
  title: "",
};

const appBar = createSlice({
  name: "appBar",
  initialState: initialStates,
  reducers: {
    updateAppBarTitle(state: any, action: any) {
      state.title = action.payload;
    },
  },
});

export const { updateAppBarTitle } = appBar.actions;
export default appBar.reducer;
