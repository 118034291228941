import { Box, Button, Container, Divider, Typography } from "@mui/joy";
import { Dialog } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";

interface DeletePostProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setShowPopup?: (value: boolean) => void;
  uuid: string;
  index?: number;
  setData: any;
  data: any;
}

const DeletePost = (props: DeletePostProps) => {
  const t = AppStringUtils();
  const { token } = useSelector((state: any) => state?.rLogin?.infoLogin || {});
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const apiCallForDeletePost = () => {
    setDeleteIsLoading(true);

    ServiceDashBoard.apiCallForDeletePost(props.uuid, token)
      .then((res: any) => {
        setDeleteIsLoading(false);
        setOpen(false);
        props?.setShowPopup && props?.setShowPopup(false);
        props?.setData(
          props?.data?.filter((item: any, i: number) => props?.index !== i)
        );
        dispatch(showSocialSnackbar({ message: res?.message }));
      })
      .catch((err) => {
        setDeleteIsLoading(false);
      });
  };

  return (
    <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
      <Container sx={styles.dialogBox} style={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "30em",
          }}
        >
          <Typography
            sx={{
              color: "white",
              width: "100%",
              textAlign: "center",
              marginTop: "30px",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {t.deletePost}
          </Typography>

          <Typography
            sx={{
              color: "white",
              width: "100%",
              textAlign: "center",
              margin: "10px 0px 30px 0px",
            }}
          >
            {t.sureDeleteThisPost}
          </Typography>
          <Divider sx={styles.deletePost} />
          <Button
            sx={{ color: "red", width: "100%", height: "45px" }}
            onClick={() => {
              apiCallForDeletePost();
            }}
            disabled={deleteIsLoading}
          >
            {t.buttonTextDelete}
          </Button>
          <Divider sx={styles.deletePost} />
          <Button
            sx={{ color: "white", width: "100%", height: "50px" }}
            onClick={() => setOpen(false)}
          >
            {t.buttonTextCancel}
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

export default DeletePost;
