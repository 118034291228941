import { createSlice } from "@reduxjs/toolkit";

const UsersDetailsSlice = createSlice({
  name: "user-details",
  initialState: {
    isBlockedByUser: false,
  },
  reducers: {
    userBlockedByAPI(state: any, action: any) {
      state.isBlockedByUser = action?.payload;
    },
  },
});

export const { userBlockedByAPI } = UsersDetailsSlice.actions;
export default UsersDetailsSlice.reducer;
