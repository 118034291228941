import { KeyboardArrowLeft } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";

import {
  AppBar,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationIcon from "../../../assets/Icons/NotificationIcon";
import SearchIcon from "../../../assets/Icons/SearchIcon";
import SocialIcon from "../../../assets/Icons/SocialIcon";
import { ImagePath } from "../../../assets/Images";
import Notification from "../../../container/notification";
import { RoutePath } from "../../../routes/route-path";
import {
  actionSearch,
  updateSearchResult,
} from "../../../store/reducer/search";
import { primaryColor, styles } from "../../../theming/Style/Styles";
import { PatterColor } from "../../../theming/Style/color.styles";
import { AppStringUtils } from "../../../utils";
import { StyledBadgeNotification } from "../LabelBottomNavigation";
import Sidebar from "./components/Sidebar";
import SidebarDrawer from "./components/SidebarDrawer";

export function Layout(props: any) {
  const { setReadNotification, count } = props;
  // const { notification_count } = props?.count;

  const t = AppStringUtils();
  let dispatch = useDispatch();
  const router = useNavigate();
  const { title } = useSelector((state: any) => state.appBar);
  const { search } = useSelector((state: any) => state.search);
  const location = useLocation();
  const [navIndex, setNavIndex] = useState<number>(1);

  useEffect(() => {
    if (location.pathname === RoutePath.hypeCast) {
      setNavIndex(1);
    } else if (location.pathname === RoutePath.hypeCastSearch) {
      setNavIndex(2);
    } else if (location.pathname === RoutePath.hypeCastSavedEpisodes) {
      setNavIndex(3);
    } else if (location.pathname === RoutePath.hypeCastFollowedPodcast) {
      setNavIndex(4);
    }
  }, [location]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setNavIndex(1);
  };
  const id = "basic-button";
  const notificationProps = {
    anchorEl: anchorEl,
    handleClose: handleClose,
    open: open,
    id: id,
  };

  const isSearch = useMemo(
    () => window.location.pathname.includes("search"),
    [window.location.pathname],
  );
  const isHomeScreen = useMemo(
    () =>
      window.location.pathname === RoutePath.hypeCast ||
      window.location.pathname === "/hype-cast/",
    [window.location.pathname],
  );

  const handleOnClear = () => {
    dispatch(actionSearch(""));
    dispatch(updateSearchResult({}));
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={styles.HypeCastAppBar}
        id={"hype-cast-app-bar"}
      >
        {isSearch && (
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Box
              sx={{
                width: 28,
                height: 28,
                my: 0.5,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 15,
                display: "flex",
                color: "white",
              }}
            >
              <ArrowBackIcon onClick={() => router(-1)} />
            </Box>
            <TextField
              className="search-plain-background"
              value={search}
              id="search"
              variant="outlined"
              fullWidth
              size="small"
              placeholder="Search"
              sx={{
                "& .MuiChip-label": {
                  color: primaryColor,
                },
                "&:hover .MuiSvgIcon-root": {
                  color: primaryColor,
                },
                "& .MuiSvgIcon-root": {
                  color: primaryColor,
                },
                color: primaryColor,
                position: "relative",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: search?.length > 0 && (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    title="Clear"
                  >
                    <ClearIcon
                      style={{ color: "rgba(255, 255, 255, 0.5)" }}
                      onClick={handleOnClear}
                    />
                  </InputAdornment>
                ),
              }}
              onClick={() => {
                location.pathname !== RoutePath.hypeCastSearch &&
                  router("hype-cast/search");
              }}
              onKeyDown={(e: any) => {
                if (e.code === "Enter") {
                  dispatch(actionSearch(e.target.value));
                }
              }}
              onChange={(e: any) => {
                dispatch(actionSearch(e.target.value));
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: { xs: `${isSearch ? "none" : "flex"}`, md: "flex" },
            justifyContent: {
              xs: isHomeScreen ? "space-between" : "",
              md: "space-between",
            },
            flexDirection: {
              xs: isHomeScreen ? "row-reverse" : "row",
              md: "row",
            },
            gap: { xs: 2, md: 0 },
            alignItems: "center",
          }}
        >
          {isHomeScreen ? (
            <Box
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <SidebarDrawer />
            </Box>
          ) : (
            <Box
              sx={{
                width: 28,
                height: 28,
                my: 0.9,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 15,
                display: { xs: "flex", md: "none" },
                color: "white",
              }}
            >
              <ArrowBackIcon onClick={() => router(-1)} />
            </Box>
          )}
          {isHomeScreen ? (
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <img
                src={ImagePath.hypeCastLogo}
                style={{
                  objectFit: "fill",
                  paddingTop: "11px",
                }}
                alt=""
              />
            </Box>
          ) : (
            <Typography
              sx={{
                display: { xs: "block", md: "none" },
                fontSize: "16px",
                fontWeight: 600,
                width: "100%",
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Typography>
          )}
          {!isHomeScreen && (
            <Box
              sx={{
                width: "20%",
                height: "100%",
                display: { xs: "block", md: "none" },
              }}
            ></Box>
          )}

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => router(-1)}>
              <KeyboardArrowLeft sx={styles.keyboardArrow} />
            </IconButton>

            <Box
              sx={{
                width: { md: 250, lg: 400, xl: 579 },
                display: { xs: "none", md: "block" },
                marginLeft: "20px",
              }}
            >
              <TextField
                className="search-plain-background"
                value={search}
                id="search"
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Search"
                sx={{
                  "& .MuiChip-label": {
                    color: primaryColor,
                  },
                  "&:hover .MuiSvgIcon-root": {
                    color: primaryColor,
                  },
                  "& .MuiSvgIcon-root": {
                    color: primaryColor,
                  },
                  color: primaryColor,
                  position: "relative",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: search?.length > 0 && (
                    <InputAdornment
                      position="end"
                      sx={{ cursor: "pointer" }}
                      title="Clear"
                    >
                      <ClearIcon
                        sx={{ color: "rgba(255, 255, 255, 0.5)" }}
                        onClick={handleOnClear}
                      />
                    </InputAdornment>
                  ),
                }}
                onClick={() => {
                  location.pathname !== RoutePath.hypeCastSearch &&
                    router("hype-cast/search");
                }}
                onKeyDown={(e: any) => {
                  if (e.code === "Enter") {
                    dispatch(actionSearch(e.target.value));
                  }
                }}
                onChange={(e: any) => {
                  dispatch(actionSearch(e.target.value));
                }}
              />
            </Box>
          </Box>
          {/* navbar button */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              sx={{ ...styles.marginX1 }}
              onClick={() => {
                router(RoutePath.home);
              }}
              title={"Hype Social"}
            >
              <SocialIcon
                color={
                  navIndex === 1 ? PatterColor.white : PatterColor.thirdGray
                }
              />
            </IconButton>
            <IconButton
              sx={{ ...styles.marginX1 }}
              aria-describedby={id}
              onClick={(event) => {
                handleClick(event);
                setNavIndex(5);
                setReadNotification(true);
              }}
              title={"Notification"}
            >
              <StyledBadgeNotification
                badgeContent={count?.notification_count}
                color="warning"
                variant="dot"
                overlap="circular"
                sx={{
                  "& .MuiBadge-dot": {
                    width: "12px", // Adjust the width of the dot
                    height: "12px", // Adjust the height of the dot
                    borderRadius: "50%", // Ensure the dot is circular
                  },
                }}
              >
                <NotificationIcon
                  color={
                    navIndex === 5 ? PatterColor.white : PatterColor.thirdGray
                  }
                />
              </StyledBadgeNotification>
            </IconButton>
            <Notification notificationProps={notificationProps} />
          </Box>
        </Box>
      </AppBar>
      <Sidebar />
    </>
  );
}
