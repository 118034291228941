import { createSlice } from "@reduxjs/toolkit";

interface createPostProps {
  uploadedFiles: File[];
  croppedFiles: File[];
}

const initialStates: createPostProps = {
  uploadedFiles: [],
  croppedFiles: [],
};

const createPost = createSlice({
  name: "language",
  initialState: initialStates,
  reducers: {
    updateUploadedFiles(state: any, action: any) {
      state.uploadedFiles = action.payload;
    },
    updateCroppedFiles(state: any, action: any) {
      state.croppedFiles = action.payload;
    },
  },
});

export const { updateUploadedFiles, updateCroppedFiles } = createPost.actions;
export default createPost.reducer;
