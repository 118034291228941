import { Close, Send } from "@mui/icons-material";
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ServiceDashBoard } from "../../../api";
import { actionPlayerPlay } from "../../../store/reducer/player";
import { styles } from "../../../theming/Style/Styles";
import { PatterColor } from "../../../theming/Style/color.styles";
import { AppStringUtils } from "../../../utils";
import { Plaintextinput, Spinner } from "../../common";
import Loader from "../../common/loader";
import { PostCastContext } from "../post-card-item";
import CommentBox from "./components/CommentBox";
import PostIcon from "./post-icons";
import PostImage from "./post-image";
import PostUserInfo from "./post-user-info";
import PostContent from "./post-content";

export function getEmojiFromUnicode(unicode: any) {
  if (unicode.length === 0) return "";

  if (unicode.includes("U+")) {
    let codePoints = unicode.trim().split(" ");
    let emoji = "";
    for (let i = 0; i < codePoints.length; i++) {
      let value = parseInt(codePoints[i].substring(2), 16);
      if (
        !isNaN(value) &&
        Number.isInteger(value) &&
        value >= 0 &&
        value <= 0x10ffff
      ) {
        emoji += String.fromCodePoint(value);
      } else {
        console.error("Invalid code point:", value);
      }
    }

    return emoji;
  }
}

export default function CommentPopup(props: any) {
  const t = AppStringUtils();
  const { showPopup, setShowPopup, values } = props;
  const { postData, setPostData }: any = useContext(PostCastContext);
  let [pageSize, pageNumber] = [20, 1];
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [commentList, setCommentList] = useState({
    commentData: new Array(),
    hasNextPageCommentList: undefined,
  });
  // const commentData = commentList.commentData;
  const hasNextPageCommentList = commentList.hasNextPageCommentList;

  const { token } = useSelector((state: any) => state?.rLogin?.infoLogin || {});
  const [errMessage, setErrMessage] = useState<string>("");
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [commentData, setCommentData] = useState(commentList.commentData);

  useEffect(() => {
    setCommentData(commentList.commentData);
  }, [commentList.commentData]);

  useEffect(() => {
    if (showPopup) {
      apiCallGetCommentByPostId(currentPage, props?.uuid);
    }
  }, [showPopup, currentPage]);

  const fetchMoreData = () => {
    hasNextPageCommentList && setCurrentPage(currentPage + 1);
    hasNextPageCommentList && setPage(page + 1);
  };

  const formik = useFormik({
    initialValues: {
      comment: "",
      comment_uuid: "",
      postId: props?.uuid,
      username: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().required(),
    }),
    onSubmit: async (values: any) => {
      if (
        isLoader ||
        values.comment.length === 0 ||
        values.comment.includes("\u3000") ||
        values.comment.trim().length === 0
      ) {
        return;
      }
      setIsDisabled(true);
      let infoValue = { ...values };
      let postId = infoValue.postId;
      delete infoValue.postId;
      if (!infoValue.comment_uuid) {
        delete infoValue.comment_uuid;
      }
      if (!infoValue.comment.includes(infoValue.username)) {
        delete infoValue.comment_uuid;
      }
      if (infoValue.comment.includes(infoValue.username)) {
        infoValue.comment = infoValue.comment.replace(infoValue.username, "");
      }
      delete infoValue.username;
      try {
        await addComment(postId, infoValue);
      } catch (error) {
        console.error("Error submitting comment:", error);
      } finally {
        setIsLoader(false);
        formik.resetForm();
      }
      return;
    },
  });
  const handleClosePopup = () => {
    dispatch(actionPlayerPlay(false));
    setShowPopup(false);
    setCommentList({
      commentData: new Array(),
      hasNextPageCommentList: undefined,
    });
    setCurrentPage(1);
    pageNumber = 1;
  };
  const addComment = (postId: any, reqBody: any) => {
    if (!isDisabled && reqBody.comment) {
      ServiceDashBoard.apiCallPostAddComment(postId, reqBody, token)
        .then((res: any) => {
          formik.resetForm();
          apiCallGetCommentByPostId(currentPage, props?.uuid, true);
          setIsDisabled(false);
        })
        .catch((err) => {
          // setErrMessage(err);
          setIsDisabled(false);
        });
    }
    return;
  };
  const replyOnComment = (commentData: any) => {
    document.getElementById("comment-input")?.focus();
    formik.setFieldValue("comment_uuid", commentData.uuid);
    formik.setFieldValue("comment_content", commentData.content);
    formik.setFieldValue(
      "username",
      "@" + commentData.commented_by_data.username + " ",
    );
    formik.setFieldValue(
      "comment",
      "@" + commentData.commented_by_data.username + " ",
    );
  };
  const handleClickEdit = () => {
    formik.handleSubmit();
  };
  const apiCallGetCommentByPostId = (
    pageNumber: number,
    postId: string,
    isCommentAdded = false,
  ) => {
    !isCommentAdded && setIsLoader(true);
    ServiceDashBoard.apiCallGetCommentOnPost(
      pageNumber,
      pageSize,
      postId,
      token,
    )
      .then((res: any) => {
        setIsLoader(false);
        setPostData({
          ...postData,
          commentCount: res?.count,
        });
        setCommentList({
          commentData: hasNextPageCommentList
            ? [...commentList.commentData, ...res.data]
            : [...res.data],
          hasNextPageCommentList: res.links.next,
        });
      })
      .catch((err) => {
        setIsLoader(false);
        setErrMessage(err);
        setTimeout(() => {
          setErrMessage("");
        }, 3000);
      });
  };
  const commentLikeUnlike = (
    commentId: any,
    isCommentLike: any,
    setIsCommentLike: any,
    setLikeCount: any,
  ) => {
    ServiceDashBoard.apiCallPostCommentLikeUnlike(commentId, token)
      .then((res: any) => {
        isCommentLike
          ? setLikeCount((prev: number) => prev - 1)
          : setLikeCount((prev: number) => prev + 1);
        setIsCommentLike((prevIsLike: boolean) => !prevIsLike);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };
  const textFieldValue1 = {
    inputType: "text",
    placeholder: t.addComment,
    name: "comment",
    value: formik.values?.comment,
    handleChange: formik.handleChange,
    handleBlur: formik.handleBlur,
    icon: <Send />,
    handleClickEdit: handleClickEdit,
  };

  return (
    <Dialog open={showPopup} maxWidth={"md"} onClose={handleClosePopup}>
      <Container maxWidth="lg" sx={{ ...styles.dialogBox, overflow: "hidden" }}>
        <DialogTitle sx={{ ...styles.textInformation3, fontWeight: "bold" }}>
          <IconButton
            aria-label="close"
            sx={styles.closeIcon}
            onClick={handleClosePopup}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={4} sx={{ px: 5, py: 2 }}>
          <Grid item xs={7} lg={7} sm={7} md={7}>
            <PostImage
              postFiles={props?.post_files}
              postType={props?.post_type}
              popup={true}
              showCommentPopup={showPopup}
            />
            <Box sx={{ ...styles.borderCard1 }}></Box>
          </Grid>
          <Grid item xs={5} lg={5} sm={5} md={5}>
            <Box>
              <PostUserInfo
                postedByData={props?.posted_by_data}
                postLocation={props?.post_location}
                postCaption={props?.post_caption}
                profileImage={true}
                show={false}
              />
              {/* <PostContent
                postedByData={props?.posted_by_data}
                postText={props?.post_caption}
                postTags={props?.post_tags}
              /> */}
              {/* <Box sx={{ ...styles.textInformation6 }}>
                {props?.post_tags &&
                  props?.post_tags.map((item: any) => (
                    <Box sx={{ mr: 1 }}>{item && "#" + item}</Box>
                  ))}
              </Box> */}
              <Divider
                color="grey"
                sx={{
                  ...styles.autoHW,
                  my: 1,
                }}
              />
              <Box sx={styles.commentListBox}>
                <Box id="comments-container">
                  {commentData?.length === 0 && !isLoader ? (
                    <Box sx={styles.commentListBox}>
                      <Typography sx={styles.textInformation2}>
                        {t.noCommentsYet}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={styles.commentPopupScrollable} id="scrollableDiv">
                      <InfiniteScroll
                        dataLength={commentData.length}
                        next={fetchMoreData}
                        hasMore={hasNextPageCommentList ? true : false}
                        loader={isLoader && pageNumber > 1 && <Loader />}
                        scrollableTarget="scrollableDiv"
                      >
                        {(isLoader
                          ? Array.from(new Array(10))
                          : commentData
                        ).map((item: any, index: any) => (
                          <>
                            <CommentBox
                              {...item}
                              replyOnComment={replyOnComment}
                              key={item?.uuid}
                              apiCallGetCommentByPostId={() => {
                                apiCallGetCommentByPostId(1, props?.uuid, true);
                              }}
                              pageNumber={page}
                              postUuid={props?.uuid}
                            />
                          </>
                        ))}
                      </InfiniteScroll>
                      <Box>
                        {isLoader && pageNumber > 1 && (
                          <Spinner isShow={isLoader} />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider
                color="grey"
                sx={{
                  ...styles.autoHW,
                  my: 1,
                }}
              />
              <PostIcon
                info={props?.info}
                isComment={false}
                postData={postData}
                setPostData={setPostData}
              />
              <Box
                sx={{
                  ...styles.textInformation8,
                  fontSize: "12px",
                  color: PatterColor.thirdGray,
                  marginTop: "0px",
                }}
              >
                {props?.readable_time}
              </Box>
              {props?.is_comment_allowed && (
                <Box>
                  <Divider
                    color="grey"
                    sx={{
                      ...styles.autoHW,
                      my: 1,
                    }}
                  />
                  <form onSubmit={formik.handleSubmit}>
                    <Plaintextinput
                      textFieldValue={textFieldValue1}
                      borderCurved={true}
                      // errosShow={true}
                      isDisabled={isDisabled}
                    />
                  </form>
                </Box>
              )}
              {!props?.is_comment_allowed && <Box sx={{ py: 6 }}></Box>}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
