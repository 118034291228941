import { createSlice } from "@reduxjs/toolkit";

const SavedPostsSlice = createSlice({
  name: "search",
  initialState: {
    isSaved: false,
    postIndex: 0,
    isClosed: false,
  },
  reducers: {
    actionIsRemoved(state: any, action) {
      state.isSaved = action.payload;
    },
    actionSetPostIndex(state: any, action) {
      state.postIndex = action.payload;
    },
    actionsetIsClosed(state: any, action) {
      state.isClosed = action.payload;
    },
  },
});

export const { actionIsRemoved, actionSetPostIndex, actionsetIsClosed } =
  SavedPostsSlice.actions;
export default SavedPostsSlice.reducer;
