import { Opacity } from "@mui/icons-material";

const PatterColor = {
  white: "#fff",
  secondaryBlack: "#282829",
  secondaryGary: "#6c6b6b",
  thirdGray: "#707070",
  fourthGray: "#1b1a1b",
  Gray: "#C4C4C4",
  blueGrey: "#607D8BFF",
  secondaryWhite: "#FFFFFFB3",
};

export { PatterColor };
