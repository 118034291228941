import { useSelector } from "react-redux";

export const AppStringUtils: any = function () {
  const { appString } = useSelector((state: any) => state.appLanguage);
  return appString;
};

/***
 * How To Used
 * import AppStringUtils
 * then declare
 * const t = AppStringUtils();
 * used:
 * t.language
 * language: key name of en.json
 */
