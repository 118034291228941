import { Button, Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import hypeImage from "../../../assets/Images/hype1.jpg";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";
type Anchor = "top" | "left" | "bottom" | "right";
const useStyles = makeStyles((theme) => ({
  boxStyle: {
    padding: "8px 0px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "10px",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    background: "rgb(28, 26, 26)",
    width: "100%",
  },
}));
export default function MobileDeletePopup(props: any) {
  const { infoLogin } = useSelector((state: any) => state?.rLogin || {});
  const { user_details } = infoLogin;
  const classes = useStyles();
  const { postedByData } = props;
  const userId = postedByData?.user_uuid;
  const t = AppStringUtils();
  const { token } = useSelector((state: any) => state?.rLogin?.infoLogin || {});
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const [deleteIsLoading, setDeleteIsLoading] = React.useState(false);
  const apiCallForDeletePost = () => {
    setDeleteIsLoading(true);

    ServiceDashBoard.apiCallForDeletePost(props.uuid, token)
      .then((res: any) => {
        setDeleteIsLoading(false);
        setOpen(false);
        props?.setShowPopup && props?.setShowPopup(false);
        props?.setData(
          props?.data?.filter((item: any, i: number) => props?.index !== i)
        );
        dispatch(showSocialSnackbar({ message: res?.message }));
      })
      .catch((err) => {
        setDeleteIsLoading(false);
      });
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const ref: any = React.useRef();
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        handleClose();
      }
    };

    if (setOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={open}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundImage: `url(${hypeImage})`,
            }}
            ref={ref}
          >
            <Typography
              sx={{
                color: "white",
                width: "100%",
                textAlign: "center",
                marginTop: "30px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              {t.deletePost}
            </Typography>

            <Typography
              sx={{
                color: "white",
                width: "100%",
                textAlign: "center",
                margin: "10px 0px 30px 0px",
              }}
            >
              {t.sureDeleteThisPost}
            </Typography>
            <Divider sx={styles.deletePost} />
            <Button
              sx={{ color: "red", width: "100%", height: "45px" }}
              onClick={() => {
                apiCallForDeletePost();
              }}
              disabled={deleteIsLoading}
            >
              {t.buttonTextDelete}
            </Button>
            <Divider sx={styles.deletePost} />
            <Button
              sx={{ color: "white", width: "100%", height: "50px" }}
              onClick={() => setOpen(false)}
            >
              {t.buttonTextCancel}
            </Button>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
