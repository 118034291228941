import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Box } from "@mui/material";
import { styles } from "../../../theming/Style/Styles";

const ReelsPlayButton = ({ isPlaying, setIsPlaying, index }: any) => {
  const handlePlayButtonClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      {!isPlaying && (
        <Box
          id={"play"}
          onClick={handlePlayButtonClick}
          sx={styles.ReelsPlayButton}
        >
          <PlayArrowRoundedIcon
            fontSize="large"
            sx={{ color: "white", height: "45px", width: "45px" }}
          />
        </Box>
      )}
    </>
  );
};

export default ReelsPlayButton;
