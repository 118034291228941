import { Box } from "@mui/material";
import { AppStringUtils } from "../../../utils";
const containerStyle = {
  color: "gray",
  height: "60vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 650,
};
const NoRecordFound = ({ style, text }: any) => {
  const t = AppStringUtils();
  return (
    <Box sx={{ ...containerStyle, ...style }}>
      {text ? text : t.noRecordFound}
    </Box>
  );
};

export default NoRecordFound;
