export enum NotificationTypes {
  NOTIFICATION_FOLLOW_REQUEST = "user_follow_request_notification",
  NOTIFICATION_FOLLOW_REQUEST_ACCEPT = "user_follow_request_accept_notification",
  NOTIFICATION_USER_STARTED_FOLLOWING = "user_started_following_notification",
  NOTIFICATION_POST_LIKE = "post_liked_notification",
  NOTIFICATION_POST_COMMENT = "post_comment_notification",
  NOTIFICATION_POST_CREATE = "post_create_notification",
  NOTIFICATION_POST_COMMENT_ON_COMMENT = "post_comment_on_comment_notification",
  NOTIFICATION_POST_SHARE = "post_share_notification",
  NOTIFICATION_POST_USER_TAGGED = "post_user_tagged_notification",
  NOTIFICATION_POST_COMMENT_MENTIONED = "post_comment_user_mentioned_notification",
  NOTIFICATION_POST_NEW_POST_UPLOAD = "post_new_post_upload_notification",
  NOTIFICATION_POST_COMMENT_LIKE = "post_comment_like_notification",
  NOTIFICATION_POST_USER_POKE = "user_poke_notification",
}
