import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Button, FormHelperText } from "@mui/material";
import imageCompression from "browser-image-compression";
import { styles } from "../../theming/Style/Styles";

export default function CommonUploadButton(props: any) {
  const { buttonValue, icon, buttonStyle, isMultiple, setIsLoading } = props;
  const stylesSelect = buttonValue?.stylesSelect;

  const handleCompressedUpload = async (e: any) => {
    setIsLoading(true);
    const files = e.target.files;
    const compressedFiles = new DataTransfer();
    const options = {
      // maxSizeMB: 1,
      useWebWorker: true,
      maxSizePercent: 80,
    };
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        let newName;
        if (file instanceof File && file.type.includes("image")) {
          const compressedResult = await imageCompression(file, options);

          const fileExtension = file.name.split(".").pop();
          newName = `hypeathelete.${fileExtension}`;
          const compressedFile = new File([compressedResult], newName, {
            type: file.type,
          });
          compressedFiles.items.add(compressedFile);
        } else if (file.type.includes("video")) {
          const fileExtension = file.name.split(".").pop();
          newName = `hypeatheletenetwork.${fileExtension}`;
          const renamedFile = new File([file], newName, {
            type: file.type,
          });
          compressedFiles.items.add(renamedFile);
        }
      }
    }

    buttonValue.formik.setFieldValue(buttonValue.name, compressedFiles.files);
    if (buttonValue.fileUploader) {
      buttonValue.fileUploader(compressedFiles.files);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button
        sx={buttonStyle ? buttonStyle : styles.uploadButton}
        fullWidth
        color="inherit"
        startIcon={!icon ? "" : <FileUploadOutlinedIcon />}
        component="label"
      >
        {buttonValue.buttonText}
        <input
          hidden
          accept="application/pdf, image/*,video/mp4 "
          type="file"
          id={buttonValue.name}
          name={buttonValue.name}
          onChange={handleCompressedUpload}
          multiple={isMultiple ? true : false}
        />
      </Button>
      {buttonValue?.errorsValue &&
        buttonValue?.touchedValue &&
        buttonValue?.handleBlur && (
          <FormHelperText
            style={
              stylesSelect
                ? {
                    ...stylesSelect.helperText,
                    ...styles.helperText,
                    paddingLeft: 13,
                  }
                : { ...styles.alignCenter, ...styles.helperText }
            }
            error
          >
            {buttonValue?.touchedValue && buttonValue?.errorsValue}
          </FormHelperText>
        )}
    </>
  );
}
