import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImagePath } from "../../assets/Images";
import { hideSnackbar } from "../../store/reducer/Snackbar";

const Snackbar = () => {
  const dispatch = useDispatch();
  const { show, message } = useSelector((state: any) => state.snackbar);
  useEffect(() => {
    show &&
      setTimeout(() => {
        dispatch(hideSnackbar());
      }, 3000);
  }, [show]);
  return (
    <>
      {show && (
        <Box
          sx={{
            position: "fixed",
            top: "113px",
            left: "50%",
            width: "311px",
            backgroundColor: "#FFFFFF",
            borderRadius: "75px",
            boxShadow: "0px 4px 9px rgba(53, 142, 12, 0.15);",
            padding: "8px 24px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            zIndex: 999,
          }}
        >
          <img src={ImagePath.successfulIcon} loading="lazy" />
          <Typography
            sx={{ fontSize: "14px", color: "#181818", fontWeight: 400 }}
          >
            {message}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Snackbar;
