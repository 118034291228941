import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ServiceUserProfile } from "../../../../api";
import { CommonButton } from "../../../../components/common";
import Loader from "../../../../components/common/loader";
import { showSocialSnackbar } from "../../../../store/reducer/Snackbar";
import { styles } from "../../../../theming/Style/Styles";
import UserBlockPopup from "../../../user-profile/components/user-block-popup";

const BlockedUsers = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [isUnblock, setUnblock] = useState<boolean>(false);
  const [isShowUnblock, setIsShowUnblock] = useState<boolean>(false);
  const { infoLogin } = useSelector((state: any) => state.rLogin);
  const [blockedUsers, setBlockedUsers] = useState<any>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState<any>([]);
  const [userUuid, setUserUuid] = useState<any>("");
  const apiCallSeeAllEpisodes = (page: any) => {
    if (isLoading) return;
    setIsLoading(true);
    ServiceUserProfile.apiCallGetBlockUserList(
      { page: page, page_size: 10 },
      infoLogin.token,
    )
      .then((res: any) => {
        setBlockedUsers((prevEpisodes: any) => [...blockedUsers, ...res?.data]);
        setHasNextPage(!!res.links.next);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const apiCallDeleteUnblockUser = (userUid: string) => {
    if (!deleteLoading.includes(userUid)) {
      setDeleteLoading([...deleteLoading, userUid]);
      ServiceUserProfile.apiCallDeleteUnBlockUser(userUid, infoLogin.token)
        .then((res: any) => {
          setBlockedUsers(
            blockedUsers.filter(
              (item: any) => item.user_data?.user_uuid !== userUid,
            ),
          );
          closeUnblockUser();
          dispatch(showSocialSnackbar({ message: res?.message }));
          setDeleteLoading(
            deleteLoading.filter((item: string) => item !== userUid),
          );
        })
        .catch((err: any) => {
          dispatch(showSocialSnackbar({ message: err?.message }));
        });
    }
  };
  const trackScrolling = () => {
    if (hasNextPage) {
      apiCallSeeAllEpisodes(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };
  const openUnblockUser = () => {
    setIsShowUnblock(true);
  };
  const closeUnblockUser = () => {
    setIsShowUnblock(false);
    setUserUuid("");
    setUnblock(false);
  };

  useEffect(() => {
    apiCallSeeAllEpisodes(currentPage);
  }, []);

  useEffect(() => {
    if (isUnblock && userUuid && isShowUnblock) {
      apiCallDeleteUnblockUser(userUuid);
    }
  }, [isUnblock, userUuid, isShowUnblock]);

  return (
    <Box sx={{ mt: 5, color: "white" }}>
      {!isLoading && blockedUsers?.length === 0 ? (
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            fontWeight: 600,
            color: "#57595B",
            fontSize: { xs: 16, sm: 18 },
          }}
        >
          No blocked users
        </Typography>
      ) : (
        <InfiniteScroll
          dataLength={blockedUsers?.length}
          next={trackScrolling}
          hasMore={hasNextPage ? true : false}
          loader={isLoading && <Loader />}
          className="infinite-scroll"
        >
          {blockedUsers?.map((item: any, index: number) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                  cursor: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img
                    src={item.user_data?.profile_image}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <Box>
                    <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                      {item.user_data?.first_name +
                        " " +
                        item.user_data?.last_name}
                    </Typography>
                    <Typography
                      sx={{
                        ...styles.lowercase,
                        fontSize: 14,
                        color: "#57595B",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        router(`/user-profile/${item?.user_data?.user_uuid}`)
                      }
                    >
                      {"@" + item.user_data?.username}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <CommonButton
                    buttonText={
                      deleteLoading.includes(item.user_data?.user_uuid) ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CircularProgress color="inherit" size={20} />
                        </Box>
                      ) : (
                        "Unblock"
                      )
                    }
                    buttonStyle={{ padding: 0, height: "30px", width: "50px" }}
                    buttonType={""}
                    onClicked={() => {
                      setUserUuid(item.user_data?.user_uuid);
                      openUnblockUser();
                      // apiCallDeleteUnblockUser(item.user_data?.user_uuid);
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </InfiniteScroll>
      )}
      <UserBlockPopup
        isShowUnblock={isShowUnblock}
        setUnblock={() => setUnblock(true)}
        handleClose={closeUnblockUser}
      />
    </Box>
  );
};

export default BlockedUsers;
