import { Box, Card, CardContent } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { styles } from "../../theming/Style/Styles";
import { PostContent, PostIcon, PostImage, PostUserInfo } from "./post-card";
import DeletePost from "./post-card/DeletePost";
import MobileDeletePopup from "./post-card/MobileDeletePopup";

interface PostCardProps {
  info?: any;
  index?: number;
  data?: any;
  setData?: any;
  apiCallGetGalleryStories?: any;
}

export const PostCastContext = createContext({});

function PostCardItem(props: PostCardProps) {
  const {
    posted_by_data,
    post_location,
    post_tags,
    post_files,
    post_caption,
    post_type,
    is_like,
    uuid,
    likes_count,
    is_following,
    is_saved,
    comment_count,
  } = props?.info;
  const [showDeletePostPopup, setShowDeletePostPopup] = useState(false);
  const [postData, setPostData] = useState({
    isLike: is_like,
    likesCount: likes_count,
    isSaved: is_saved,
    commentCount: comment_count,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showMobileDeletePostPopup, setShowMobileDeletePostPopup] =
    useState(false);

  useEffect(() => {
    setPostData({
      isLike: is_like,
      likesCount: likes_count,
      isSaved: is_saved,
      commentCount: comment_count,
    });
  }, [props?.info]);

  return (
    <PostCastContext.Provider
      value={{
        postData: postData,
        setPostData: setPostData,
      }}
    >
      <Card
        sx={{
          ...styles.borderCard,
          width: { xs: "100%", md: 500 },
        }}
      >
        <CardContent sx={{ px: 0 }}>
          <Box
            sx={{
              px: { xs: 1.5, sm: 2 },
              pt: { xs: 1.5, sm: 0 },
            }}
          >
            <PostUserInfo
              postedByData={posted_by_data}
              postLocation={post_location}
              show={true}
              setShowDeletePostPopup={
                document?.body?.clientWidth > 900
                  ? setShowDeletePostPopup
                  : setShowMobileDeletePostPopup
              }
              isOpen={showPopup}
              setIsOpen={setShowPopup}
              uuid={props?.info?.uuid}
              isFollow={is_following}
              index={props?.index}
              data={props?.data}
              setData={props?.setData}
              apiCallGetGalleryStories={props?.apiCallGetGalleryStories}
            />
          </Box>
          <Box sx={styles.postBody}>
            <PostImage postFiles={post_files} postType={post_type} />
          </Box>
        </CardContent>
        <Box sx={{ px: { xs: 2, sm: 4 }, pt: { xs: 2, sm: 0 }, zIndex: 1 }}>
          <PostIcon
            {...props}
            isComment={true}
            postData={postData}
            setPostData={setPostData}
          />
        </Box>

        {post_caption && (
          <PostContent
            postedByData={posted_by_data}
            postText={post_caption}
            postTags={post_tags}
            width="100%"
          />
        )}
      </Card>
      <DeletePost
        open={showDeletePostPopup}
        setOpen={setShowDeletePostPopup}
        uuid={uuid}
        index={props?.index}
        data={props?.data}
        setData={props?.setData}
        setShowPopup={setShowPopup}
      />
      <MobileDeletePopup
        open={showMobileDeletePostPopup}
        setOpen={setShowMobileDeletePostPopup}
        uuid={uuid}
        index={props?.index}
        data={props?.data}
        setData={props?.setData}
        setShowPopup={setShowPopup}
      />
    </PostCastContext.Provider>
  );
}

export default PostCardItem;
