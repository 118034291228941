import { DialogContent } from "@material-ui/core";
import {
  AnnouncementOutlined,
  DeleteOutline,
  InsertLinkOutlined,
  MoreHoriz,
  PersonRemove,
  RemoveCircleOutline,
  RepeatOutlined,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  ListItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceDashBoard, ServiceUserProfile } from "../../../api";
import hypeImage from "../../../assets/Images/hype1.jpg";
import EditPost from "../../../container/create-post/EditPost";
import { RoutePath } from "../../../routes/route-path";
import { actionSetPostIndex } from "../../../store/reducer/SavedPost";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { actionSetFlag } from "../../../store/reducer/story";
import { primaryColor, styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";
import MobilePostMenuPopup from "./MobilePostMenuPopup";
import ReportPostDialog from "./report-post-dialog";
import PostContent from "./post-content";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "25%",
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "start",
    backdropFilter: " blur(250px)",
    overflow: "hidden",
    borderRadius: "30px",
  },
  boxStyle: {
    padding: "8px 0px",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "10px",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  },
}));

export default function PostUserInfo(props: any) {
  const t = AppStringUtils();
  const dispatch = useDispatch();
  const { infoLogin } = useSelector((state: any) => state?.rLogin || {});
  const { postIndex } = useSelector((state: any) => state.savedPost);
  const { user_details } = infoLogin;
  const classes = useStyles();
  const {
    postedByData,
    profileImage,
    postLocation,
    postCaption,
    isOpen,
    setIsOpen,
    uuid,
    index,
    data,
    setData,
  } = props;
  const router = useNavigate();
  const userId = postedByData?.user_uuid;
  const routeUser = RoutePath.userProfile;

  const [isShow, setIsShow] = useState(false);
  const [textReport, setTextReport] = useState("");
  const [isFollow, setIsFollow] = useState(props?.isFollow);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupEditPost, setShowPopupEditPost] = useState<any>(false);
  const [openPostText, setOpenPostText] = React.useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsFollow(props?.isFollow);
  }, [props?.isFollow]);
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClose2 = () => {
    setIsShow(false);
  };

  const handleClick = () => {
    setIsOpen(false);
    setIsShow(true);
  };

  const handleClickStory = () => {
    let formdata = new FormData();
    formdata.append("post_uuid", props?.uuid);
    formdata.append("post_index ", postIndex);
    ServiceDashBoard.apiCallAddStory(formdata, infoLogin?.token)
      .then((res: any) => {
        setIsOpen(false);
        dispatch(actionSetFlag(true));
        dispatch(showSocialSnackbar({ message: res.message }));
        dispatch(actionSetPostIndex(0));
      })
      .catch((err: any) => {
        setIsOpen(false);
        dispatch(actionSetFlag(false));
        dispatch(showSocialSnackbar({ message: err.message }));
        dispatch(actionSetPostIndex(0));
      });
  };

  const apiCallPostFollowUnfollowUser = () => {
    let requested_by_id = props?.postedByData?.user_uuid;
    ServiceUserProfile.apiCallPostFollowUnfollowUser(
      { requested_by_id },
      infoLogin.token,
    )
      .then((res: any) => {
        setIsFollow(!isFollow);
        if (res?.data?.is_follow) {
          dispatch(
            showSocialSnackbar({
              message: t.requestSent,
              type: "success",
            }),
          );
        } else {
          dispatch(
            showSocialSnackbar({
              message: t.requestRevoked,
              type: "success",
            }),
          );
        }
      })
      .catch((err) => {});
  };
  const apiCallPostNotInterested = () => {
    let dictObj = { post_uuid: uuid };
    ServiceDashBoard.apiCallPostNotInterestedInPost(dictObj, infoLogin?.token)
      .then((res: any) => {
        if (!res.error) {
          handleClose();
          props?.setIsModalOpen && props?.setIsModalOpen(false);
          data.length > 0 &&
            setData(data?.filter((item: any, i: number) => i !== index));
          dispatch(showSocialSnackbar({ message: res?.message }));
        }
      })
      .catch((err: any) => {
        handleClose();
        dispatch(showSocialSnackbar({ message: err?.message }));
      });
  };
  const editPostData = {
    showPopupEditPost: showPopupEditPost,
    setShowPopupEditPost: setShowPopupEditPost,
    isEdit: true,
    uuid: uuid,
    setIsModalOpen: props?.setIsModalOpen,
    data: data,
    setData: setData,
    info: props?.info,
    setInfo: props?.setInfo,
  };

  const iconData = [
    {
      icon: <RepeatOutlined />,
      text: t.repostAsStory,
      onClicked: handleClickStory,
    },
    {
      icon: <InsertLinkOutlined />,
      text: t.sharePostAsLink,
      onClicked: () => {
        const link = `https://backend.hypeathletenetwork.com/view-post/${uuid}`;
        navigator.clipboard.writeText(link);
        dispatch(showSocialSnackbar({ message: "Link Copied!" }));
        setIsOpen(false);
        setIsShow(false);
      },
    },
  ];
  const iconData1 = [
    {
      icon: <EditIcon />,
      text: t.buttonTextEdit,
      onClicked: () => {
        if (document.body.clientWidth > 900) {
          setShowPopupEditPost(true);
        } else {
          router("/edit-post/" + uuid);
        }
        handleClose();
      },
    },
    {
      icon: <DeleteOutline />,
      text: t.buttonTextDelete,
      onClicked: () => {
        props?.setShowDeletePostPopup(true);
      },
    },
    ...iconData,
  ];
  const iconData2 = {
    icon: <PersonRemove />,
    text: isFollow ? t.unfollow : t.follow,
    onClicked: apiCallPostFollowUnfollowUser,
  };
  const iconData3 = [
    location.pathname === "/reels" && { ...iconData2 },
    // { ...iconData2 },
    {
      icon: <RemoveCircleOutline />,
      text: t.notInterested,
      onClicked: apiCallPostNotInterested,
    },
    {
      icon: <AnnouncementOutlined />,
      text: t.reportToPost,
      onClicked: handleClick,
    },
    ...iconData,
  ];

  const maxLength = 1;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box sx={{ ...styles.alignStart }}>
          <Box>
            <Avatar
              onClick={() => router(`${routeUser + userId}`)}
              imgProps={{ loading: "lazy" }}
              sx={
                profileImage
                  ? { ...styles.profileImage1, ...styles.pointer }
                  : { ...styles.profileImage, ...styles.pointer }
              }
              src={postedByData?.profile_image}
            />
          </Box>
          <Box sx={{ ml: 2 }}>
            <Box>
              <Typography
                onClick={() => router(`${routeUser + userId}`)}
                variant="h6"
                component="div"
                sx={{
                  ...styles.postNameText1,
                  ...styles.pointer,
                  fontSize: "16px",
                }}
              >
                {postedByData &&
                  postedByData?.first_name + " " + postedByData?.last_name}
              </Typography>
              <Typography variant="body2" sx={styles.postNameText2}>
                {!openPostText &&
                  postLocation?.location_name &&
                  postLocation?.location_name?.length >= maxLength && (
                    <Box
                      component="span"
                      sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                    >
                      <LocationOnIcon sx={{ fontSize: "18px" }} />
                      {`${postLocation?.location_name?.split(",")[0]?.trim()}`}
                    </Box>
                  )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          {(postCaption || props?.info?.post_tags) && (
            <PostContent
              postedByData={postedByData}
              postText={postCaption}
              postTags={props?.info?.post_tags}
              width="17rem"
            />
          )}
        </Box>
        {/* {postCaption && (
          <PostContent
            postedByData={postedByData}
            postText={postCaption}
            width="17rem"
          />
        )} */}
      </Box>
      <MobilePostMenuPopup
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        iconData1={iconData1}
        iconData2={iconData3}
        postedByData={postedByData}
      />
      <Box>
        {props?.show && (
          <IconButton
            sx={{
              color: primaryColor,
              fontSize: "16px",
            }}
            onClick={() => {
              document.body.clientWidth > 900
                ? setIsOpen(true)
                : setShowPopup(true);
            }}
          >
            <MoreHoriz />
          </IconButton>
        )}
      </Box>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="xs"
        PaperProps={{ className: classes.drawer }}
      >
        <DialogContent
          style={{
            color: primaryColor,
            padding: "20px 0",
          }}
        >
          <Box maxWidth="xs">
            <Box sx={styles.alignCenter}>
              <Box>
                {userId === user_details?.user_uuid && props?.show ? (
                  <>
                    {iconData1.map((item: any, index: number) => {
                      return (
                        <>
                          <ListItem
                            className={classes.boxStyle}
                            key={index}
                            onClick={item.onClicked}
                          >
                            {item.icon}
                            {item.text}
                          </ListItem>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {iconData3.map((item: any, index: number) => {
                      return (
                        <>
                          {item && (
                            <ListItem
                              key={index}
                              className={classes.boxStyle}
                              onClick={item.onClicked}
                            >
                              {item.icon}
                              {item.text}
                            </ListItem>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ReportPostDialog
        isShow={isShow}
        setIsShow={setIsShow}
        handleClose2={handleClose2}
        textReport={textReport}
        uuid={props?.uuid}
        setTextReport={setTextReport}
      />
      <EditPost {...editPostData} />
    </Box>
  );
}
