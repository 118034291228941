import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceUserProfile } from "../../../api";

export const getApiCallForGetMutualFriendList: any = createAsyncThunk(
  "Userprofile/mutualList",
  async (params: any, thunkAPI) => {
    try {
      const { param, token } = params;
      const response = await ServiceUserProfile.apiCallForGetMutualFriendList(
        param,
        token,
      );
      return response;
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getApiCallGetFollowerFollowingList: any = createAsyncThunk(
  "Userprofile/followingList/followerList",
  async (params: any, thunkAPI) => {
    try {
      const { param, token } = params;

      const response = await ServiceUserProfile.apiCallGetFollowerFollowing(
        param,
        token,
      );
      return response;
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const UserListSlice = createSlice({
  name: "userlistSlice",
  initialState: {
    mutualList: { isLoading: false, result: [], isRejected: false },
    followerList: { isLoading: false, result: [], isRejected: false },
    followingList: { isLoading: false, result: [], isRejected: false },
  },
  reducers: {},
  extraReducers: {
    [getApiCallForGetMutualFriendList.pending]: (state: any) => {
      state.mutualList.isLoading = true;
      state.mutualList.isRejected = false;
    },
    [getApiCallForGetMutualFriendList.fulfilled]: (
      state: any,
      { payload }: any,
    ) => {
      state.mutualList.isLoading = false;
      state.mutualList = { ...payload, result: payload?.result };
      state.mutualList.isRejected = false;
    },
    [getApiCallForGetMutualFriendList.rejected]: (state: any) => {
      state.mutualList.isLoading = false;
      state.mutualList.isRejected = false;
    },
  },
});

export const {} = UserListSlice.actions;

export default UserListSlice.reducer;
