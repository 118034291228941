import { grey } from "@mui/material/colors";
import hypeImage from "../../assets/Images/hype1.jpg";
import hypeImage2 from "../../assets/Images/hype2.jpg";
import hypeImage3 from "../../assets/Images/hype3.jpg";
import { PatterColor } from "./color.styles";

export const primaryColor = "#F6F6F9";
const errorColor = "#F8BABA";
const fontSizes = { xs: 14, sm: 16, md: 16, lg: 16, xl: 16 };

export const styles = {
  badge: {
    mb: 1,
    borderRadius: 8,
    Color: primaryColor,
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.17),rgba(233, 0, 255, 1)), linear-gradient(240deg, #16e8ff, #e900ff)",
  },
  badge1: { p: -5 },
  bold: { fontWeight: "bold" },
  textInput: {
    minHeight: 70,
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        textAlign: "start",
        opacity: 0.7,
      },
      color: primaryColor,
    },
  },
  textInput1: {
    minHeight: 70,
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        opacity: 1,
        textAlign: "start",
        fontSize: 15,
        "-webkit-input-placeholder": {
          textAlign: "left",
        },
        "-moz-placeholder": {
          textAlign: "left",
        },
      },
      color: primaryColor,
      textAlign: "start",
    },
  },
  textInput2: {
    width: 400,
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        opacity: 0.9,
        textAlign: "start",
        fontSize: 15,
        "-webkit-input-placeholder": {
          textAlign: "left",
        },
        "-moz-placeholder": {
          textAlign: "left",
          color: primaryColor,
        },
      },
      color: primaryColor,
      textAlign: "start",
    },
  },
  textInput3: {
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        opacity: 0.9,
        textAlign: "start",
        fontSize: 15,
        "-webkit-input-placeholder": {
          textAlign: "left",
        },
        "-moz-placeholder": {
          textAlign: "left",
          color: primaryColor,
        },
      },
      color: primaryColor,
      textAlign: "start",
    },
  },

  textinputpopup: {
    width: 400,
    minHeight: 70,
    mt: -3.5,
    justifyContent: "center",
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        opacity: 0.9,
        textAlign: "start",
        fontSize: 15,
        "-webkit-input-placeholder": {
          textAlign: "left",
        },
        "-moz-placeholder": {
          textAlign: "left",
          color: primaryColor,
        },
      },
      color: primaryColor,
      textAlign: "start",
    },
  },
  textInputProps: {
    minHeight: 70,
    input: {
      mr: -3,
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        textAlign: "left",
        opacity: 0.7,
      },
      "&::input": {
        mr: -4,
        color: "white",
      },
      color: primaryColor,
    },

    svg: {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #5b5a5a",
      },
      "&:hover fieldset": {
        border: "1px solid #5b5a5a",
      },
      "&.Mui-disabled fieldset": {
        border: "1px solid #808080",
      },
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "white",
    },
  },
  textInputPropsPhone: {
    input: {
      "&::input": {
        color: primaryColor,
      },
      color: primaryColor,
    },
    svg: {
      color: primaryColor,
    },
  },
  textInputPropsLeft: {
    minHeight: 70,
    input: {
      "&::label": {
        textOverflow: "ellipsis",
        color: primaryColor,
        opacity: 0.7,
        textAlign: "left",
      },
      color: primaryColor,
    },
    svg: {
      color: primaryColor,
    },
  },
  textInputProps1: {
    // minHeight: 50,
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        textAlign: "left",
      },
      "&::input": {},
      color: primaryColor,
    },
    svg: {
      color: primaryColor,
    },
  },
  pointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  colorWhite: { color: primaryColor, position: "relative" },
  colorWhite2: { color: grey, position: "relative" },
  alignStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignStart1: {
    display: "flex",
    justifyContent: "flex-start",
    pt: 2,
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  alignEnd: { display: "flex", justifyContent: "flex-end" },
  alignSpaceBetween: { display: "flex", justifyContent: "space-between" },
  textSignup: {
    color: primaryColor,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontSize: fontSizes,
  },
  hypeCastCommonCard: {
    position: "relative",
    width: { md: "325px", xs: "295px" },
    minHeight: { xs: "100px", md: "117px" },
    background: "#181818",
    marginBottom: 0,
    cursor: "pointer",
    "&:hover": {
      background: {
        md: "linear-gradient(93.61deg, rgba(108, 131, 249, 0.15) 10.03%, rgba(171, 53, 237, 0) 100.04%)",
      },
    },
    "&:hover .icon": {
      display: { xs: "none", md: "block" },
    },
    overflow: "hidden",
  },
  hypeCastCommonCardTitle: {
    color: "#E9E9E9",
    fontFamily: "Poppins",
    fontSizes: { md: 16, xs: 14 },
    width: { md: "180px", xs: "150px" },
  },
  hypeCastCommonCardSubTitle: {
    color: "#707070",
    fontFamily: "Poppins",
    width: { md: "180px", xs: "150px" },
    fontSizes: { md: 14, xs: 12 },
  },
  horizontalScrollArrow: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    backgroundColor: "#181818",
    color: "white",
    boxShadow: "0 0 3px #f7f6f5",
  },
  hypeCastCategoryCard: {
    height: { sm: 100, xs: 90 },
    width: { md: 200, xs: 130 },
    paddingY: { md: "33px", xs: "15px" },
    paddingX: { md: "13px", xs: "5px" },
    borderRadius: "10px",
  },
  horizontalScrollArrowRightContainer: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    opacity: 1,
    height: "100%",
    display: "none",
    alignItems: "center",
  },
  horizontalScrollArrowLeftContainer: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    opacity: 1,
    height: "100%",
    display: "none",
    alignItems: "center",
  },
  horizontalScrollStackContainer: {
    position: "relative",
    "&:hover .scroll-btn": { display: { xs: "none", md: "flex" } },
  },
  horizontalScrollStack: {
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    gap: 2,
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollBehavior: "smooth",
  },
  underline: {
    mx: 1,
    color: PatterColor.secondaryWhite,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
    fontFamily: "Poppins",
    fontSize: fontSizes,
  },
  backText: {
    color: PatterColor.secondaryWhite,
    fontFamily: "Poppins",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
    fontSize: fontSizes,
  },
  width90: { width: 90, height: "auto", ml: 3, my: 1 },
  width50: { width: { md: 100, sm: 70, xs: 70 }, my: 0.7 },
  widthStore: { width: { xs: 100, md: 150, sm: 150 }, mt: 2 },
  userProfile: {
    width: { xs: 50, sm: 50, md: 60, xl: 60 },
    height: { xs: 50, sm: 50, md: 60, xl: 60 },
    borderRadius: "100px",
    alignItems: "center",
    ml: 0.2,
    my: 1,
  },
  userProfileStory: {
    width: { xs: 50, md: 60 },
    height: { xs: 50, md: 60 },
    borderRadius: "100px",
    alignItems: "center",
    ml: 0.2,
    my: 1,
    // border: "2px solid red",
  },
  boxStore: {
    display: "flex",
    justifyContent: "center",
    mt: 5,
  },
  mainBox1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  mainBox2: {
    px: { xs: 1, md: 4, sm: 2, xl: 4 },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: "1px solid blue",
  },
  mainBox3: {
    display: "flex",
    justifyContent: "center",
  },
  mainBox4: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  height100VH: { height: "100vh" },
  height500: { height: 500 },
  group: {
    backgroundColor: "#000000",
    fontSize: {
      xl: "6rem",
      md: "5rem",
      sm: "4rem",
      xs: "3.2rem",
    },
  },
  hypeImage: {
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  hypeImage1: {
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "285%",
    backgroundPosition: "30%",
  },
  hypeImage2: {
    backgroundImage: `url(${hypeImage2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  hypeImage3: {
    backgroundImage: `url(${hypeImage3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    overflowY: "auto",
  },
  heading: {
    textTransform: "uppercase",
    color: primaryColor,
    fontFamily: "Test Sohne Schmal",
    letterSpacing: 4,
    fontSize: { xl: "6rem", md: "5rem", sm: "4rem", xs: "2.5rem" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logobar1: {
    background: "transparent",
    zIndex: (theme: any) => theme.zIndex.drawer + 1,
    boxShadow: "none",
  },
  logobar2: {
    // background: "#020202",
    background: "black",
    backdropFilter: "blur(220px)",
    boxShadow: "none",
    pl: { xs: 2, md: 0 },
    width: { xs: "100%" },
  },
  mt4: { mt: 4 },
  ml1: { ml: 1 },
  pt10: { pt: 10 },
  snackbarPosition: { vertical: "top", horizontal: "center" },
  width100: { width: "100%" },
  helperText: {
    color: errorColor,
  },
  textInformation: {
    color: primaryColor,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",

    // border: "1px solid red",
  },
  textInformation1: {
    color: "gray",
    fontSize: { md: "16px", xs: "12px" },
    fontFamily: "Poppins",
    width: { xs: "70px", sm: "auto" },
  },
  FAtextInformation1: {
    color: "gray",
    fontSize: { md: "16px", xs: "12px" },
    fontFamily: "Poppins",
    width: { xs: "auto", sm: "auto" },
  },

  textInformation2: {
    color: "white",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Poppins",
    textTransform: "none",
  },
  textInformation3: {
    color: primaryColor,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
  },
  textinfoBlock: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
  },

  textInformation4: {
    color: primaryColor,
    fontSize: { md: "16px", xs: "12px" },
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
  textInformation5: {
    color: "gray",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Poppins",
    gap: "5px",
    fontSize: { md: "14px", xs: "12px" },
  },
  textInformation50: {
    color: "gray",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Poppins",
    gap: "5px",
    fontSize: { md: "14px", xs: "12px" },
    wordBreak: "break-word", // Ensure long words break and wrap
    whiteSpace: "pre-wrap",
  },
  fontSize: {
    fontSize: { md: "22px", xs: "18px" },
  },
  textInformation6: {
    background: "linear-gradient(270.04deg, #16e8ff 0.03%, #e900ff 99.97%)",
    "-webkit-text-fill-color": "transparent",
    "-webkit-background-clip": " text",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Poppins",
    ml: 1,
    mt: 1,
    textTransform: "none",
    flexWrap: "wrap",
  },

  textInformation8: {
    color: primaryColor,
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Poppins",
    ml: 1,
    mt: 1,
    textTransform: "lowercase",
  },
  textInformation7: {
    color: primaryColor,
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
  contentBox1: { mt: 4, px: 1 },
  contentBox2: { mb: 4 },
  contentBox3: { ml: 5, px: 2 },
  text: {
    color: primaryColor,

    fontFamily: "Poppins",
  },
  categoryText: {
    color: "#000",
    fontWeight: "bold",
    font: { xs: 12, sm: 14, md: 16 },
    fontFamily: "Poppins",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  textBold: {
    color: primaryColor,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    cursor: "pointer",
  },
  userTypeButton1: {
    width: { xs: 110, md: 150, sm: 130, xl: 150 },
    height: { xs: 110, md: 150, sm: 130, xl: 150 },
    margin: { xs: 1, md: 2, sm: 2, xl: 2 },
    borderRadius: 3,
    fontWeight: "bold",
    fontFamily: "IBM Plex Mono",
    padding: "0.4rem 3rem",
    backgroundColor:
      "linear-gradient(270.04deg, #16e8ff 0.03%, #e900ff 99.97%)",
    border: "solid 0.1em transparent",
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.17),rgba(233, 0, 255, 1)),linear-gradient(240deg, #16e8ff, #e900ff)",
    backgroundOrigin: "border-box",
    color: primaryColor,
    textTransform: "none",
  },

  userTypeButton2: {
    width: { xs: 110, md: 150, sm: 130, xl: 150 },
    height: { xs: 110, md: 150, sm: 130, xl: 150 },
    margin: { xs: 1, md: 2, sm: 2, xl: 2 },
    borderRadius: 3,
    fontWeight: "bold",
    fontFamily: "IBM Plex Mono",
    padding: "0.4rem 3rem",
    backgroundColor: "rgba(0, 0, 0, 0.36)",
    background: "rgba(0, 0, 0, 0.36)",
    border: "solid 0.1em transparent",
    backgroundImage: "linear-gradient(240deg, #16e8ff, #e900ff)",
    backgroundOrigin: "border-box",
    boxShadow: "2px 1000px 1px #000000 inset",
    color: primaryColor,
    textTransform: "none",
  },
  boxColumn: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  userProfileAppBar: {
    background: "#020202",
    display: "flex",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    p: 1,
  },
  numberInput: {
    minHeight: 70,
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis",
        color: primaryColor,
        textAlign: "center",
        opacity: 0.7,
        letterSpacing: 0,
      },
      letterSpacing: 15,
      color: primaryColor,
    },
  },
  textSelect: {
    color: primaryColor,
    fontFamily: "Poppins",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
  },
  textSelectLeft: {
    color: primaryColor,
    fontFamily: "Poppins",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "flex-start",
  },
  customOutline: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: primaryColor,
    },
  },
  textPlaceholder: {
    textOverflow: "ellipsis",
    fontFamily: "IBM Plex Mono",
    color: primaryColor,
    textAlign: "center",
    opacity: 0.7,
    letterSpacing: 0,
    ml: 3,
  },
  textPlaceholderLeft: {
    textOverflow: "ellipsis",
    fontFamily: "IBM Plex Mono",
    color: primaryColor,
    textAlign: "left",
    // opacity: 0.7,
    letterSpacing: 0,
  },
  textPlaceholderBio: {
    textOverflow: "ellipsis",
    fontFamily: "IBM Plex Mono",
    color: primaryColor,
    textAlign: "left",
    opacity: 1,
  },
  headingMargin: { mt: { md: -6, sm: -4, xs: -2 } },
  radioButton: {
    color: primaryColor,
    display: "flex",
    justifyContent: "flex-start",
    ml: -5,
    mt: -1,
    "&, &.Mui-checked": {
      color: primaryColor,
    },
  },
  roleIcons: { width: { xs: 35, sm: 60, md: 70, xl: 70 }, mt: -1 },
  textWhite: {
    color: primaryColor,
    textTransform: "capitalize",
    textAlign: "center",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #5b5a5a",
      },
      "&:hover fieldset": {
        border: "1px solid #5b5a5a",
      },
    },
  },
  textWhiteLeft: {
    color: primaryColor,
    textTransform: "capitalize",
    textAlign: "left",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #5b5a5a",
      },
      "&:hover fieldset": {
        border: "1px solid #5b5a5a",
      },
    },
  },
  resendTextTimer: {
    color: primaryColor,
    textTransform: "none",
    mb: 1,
  },
  resendText: {
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      cursor: "pointer",
    },
    mb: 1,
    color: primaryColor,
  },
  uploadButton: {
    borderRadius: 1,
    my: 1,
    fontFamily: "IBM Plex Mono",
    border: "solid 0.1em rgba(233, 0, 255, 1)",
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.35),rgba(255, 255, 255, 0.35))",
    color: primaryColor,
    textTransform: "none",
    py: 2,
  },
  uploadButton2: {
    borderRadius: 10,
    px: 5,
    fontFamily: "IBM Plex Mono",
    border: "solid 0.1em rgba(233, 0, 255, 1)",
    backgroundImage: "",
    color: primaryColor,
    textTransform: "none",
  },
  fileUploadBox1: {
    width: "100%",
    wordBreak: "break-all",
    mt: 2,
    display: "flex",
    justifyContent: "space-between",
  },
  fileUploadBox2: {
    display: "flex",
    justifyContent: "flex-start",
    ml: 2,
    p: 0,
  },
  textFileUpload1: {
    color: primaryColor,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    ml: 0,
  },
  textFileUpload2: {
    color: primaryColor,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    fontSize: 12,
  },
  messageIcon: { width: 25, cursor: "pointer" },
  likeIcon: {
    width: 23,
    height: 24,
    cursor: "pointer",
  },
  saveIcon: {
    width: 22,
    height: 22,
    cursor: "pointer",
  },
  savedIcon: { width: 30, mt: -1, color: primaryColor },
  LikeCommentContainer: {
    display: "flex",
    alignItems: "center",
    gap: 3,
  },
  shareSaveContainer: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    gap: 0.8,
  },

  postNameText1: {
    color: primaryColor,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
  },
  tagUserNameText1: {
    color: primaryColor,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  tagUserNameText2: {
    color: PatterColor.secondaryGary,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    fontSize: "10px",
  },
  postNameText2: {
    color: "rgba(255, 255, 255, 0.64)",
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    ml: 0,
    fontSize: {
      xs: "12px",
      md: "14px",
    },
  },
  postTagsText1: {
    color: "#8797FF",
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    ml: 1,
  },
  postContentText1: {
    color: primaryColor,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
    pt: 1,
  },
  podcastContentText1: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
  },
  postIconsText1: {
    color: primaryColor,
    display: "flex",
    justifyContent: "start",
    fontFamily: "Poppins",
  },
  borderCard: {
    background: "transparent",
    border: "2px solid #202020",
    borderRadius: 2,
    marginBottom: 4,
    paddingBottom: 3,
  },
  borderCard1: {
    width: 500,
    background: "transparent",
    borderRadius: 2,
    mb: 1,
  },
  borderCard2: {
    width: "10rem",
    height: "10rem",
    background: "transparent",
    borderRadius: 2,
  },
  profileImage: {
    width: "5vh",
    height: "5vh",
    borderRadius: "50px 50px 50px 0px",
  },
  profileImage1: {
    width: 40,
    height: 40,
  },
  profileImage3: {
    width: 50,
    height: 50,
  },
  profileImage4: {
    width: 35,
    height: 35,
  },
  postBody: {
    mt: 2,
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: 1,
    width: "100%",
    height: "60vh",
    maxHeight: { md: 500 },
  },
  postBody1: {
    mt: 2,
    borderRadius: 1,
    width: "25vh",
    height: "auto",
  },
  postBodyNone: {
    m: 1,
    p: 2,
    borderRadius: 1,
    width: { xs: "90vw", sm: 450 },
  },
  postImage: {
    width: "100%",
    height: "60vh",
    maxHeight: { md: 500 },
    objectFit: "contain",
  },
  postImage1: {
    minWidth: { xs: "100%" },
    maxHeight: { md: 500 },
    objectFit: "contain",
  },
  postImage2: {
    width: 175,
    height: "auto",
    objectFit: "cover",
  },
  postCreateImage1: {
    width: "70vw",
    height: "70vh",
  },
  postCreateImage2: {
    width: 350,
    height: 350,
    borderRadius: 10,
  },
  postCreateImage3: {
    objectFit: "contain",
    width: "100%",
  },
  postIconBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  taggedUserContainer: {
    position: "absolute",
    color: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    // px: 2,
    // py: 1,
    background: "rgba(0, 0, 0, 0.8)",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
  },
  taggedUserArrow: {
    width: 0,
    height: 0,
    position: "absolute",
    top: "-10px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: "10px solid rgba(0, 0, 0, 0.8)",
  },
  ReelsPostIconBox: {
    height: { md: "82.5" },
    padding: { xs: "5px 5px", sm: "10px 10px", md: "10px 34px" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: { xs: "20px", sm: "28px", md: "36px" },
    pointerEvent: "none",
    position: { xs: "absolute", md: "static" },
    bottom: 32,
    right: 0,
    marginBottom: "15px",
  },
  ReelsPostIconText: {
    color: "white",
  },
  ReelsContainer: {
    marginTop: { xs: "0px", md: "30px" },
    height: { xs: "100vh", md: "90vh" },
    overflowX: "hidden",
    overflowY: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    width: { xs: "100%", md: "110%" },
  },
  scrollIconButton: {
    color: "#000000",
    alignSelf: "center",
    borderRadius: 50,
    background: "#FFFFFF",
    padding: 1,
    width: 25,
    height: 25,
    fontWeight: "bold",
  },
  scrollIconButton1: {
    color: PatterColor.thirdGray,
    alignSelf: "center",
    borderRadius: 50,
    background: PatterColor.fourthGray,
    padding: 1,
    width: 25,
    height: 25,
    fontWeight: "bold",
  },
  // #413F42
  profileBox: {
    p: 1,
    paddingLeft: 0,
    width: 300,
    // border: "1px solid #413F42",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  sidemenu: {
    fontSize: "large",
    mr: 2,
  },
  appBarContainer: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    color: "white",
    zIndex: 3,
    padding: { md: "12px", lg: "18px", xl: "16px" },
    backgroundColor: "#181818",
    display: { xs: "none", md: "block" },
  },
  sideBarDrawer: {
    width: { xs: "96vw", sm: "98vw" },
    background: "#181818",
    height: "100%",
    alignContent: "end",
    padding: "10px",
  },
  HypeCastAppBar: {
    background: "#010102",
    backdropFilter: "blur(220px)",
    zIndex: 2,
    boxShadow: "none",
    padding: {
      xs: "5px 10px 5px 10px",
      md: "20px 20px 20px 280px",
    },
  },
  hypeCastLogo: {
    display: { xs: "block", md: "none" },
  },
  recentlyPlayedContainer: {
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    gap: 2,
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollBehavior: "smooth",
  },
  recentlyPlayedCard: {
    minWidth: { md: "325px", xs: "295px" },
    minHeight: "80px",
    background: "#181818",
    marginBottom: 0,
    cursor: "pointer",
    borderRadius: "8px",
  },

  recentlyPlayedCardAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingX: "16px",
  },
  keyboardArrowContainer: {
    position: "absolute",
    display: "none",
    zIndex: 1,
    top: "30%",
    opacity: 0.4,
  },
  keyboardArrow: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    backgroundColor: "#181818",
    color: "white",
  },
  playBtnStyle: {
    backgroundImage:
      "linear-gradient(273.19deg, #16E8FF -18.02%, #E900FF 88.1%)",
    color: "white",
    marginTop: "10px",
    height: { md: "25px", xs: "20px" },
    fontSize: { md: 14, xs: 12 },
    letterSpacing: "1px",
    fontWeight: 500,
    width: { md: "117px", xs: "90px" },
    borderRadius: "38px",
  },
  getStartedBtnStyle: {
    backgroundImage:
      "linear-gradient(273.19deg, #16E8FF -18.02%, #E900FF 88.1%)",
    color: "white",

    borderRadius: "38px",
  },
  playBtnSkeletonStyle: {
    marginTop: "10px",
    height: { md: "25px", xs: "20px" },
    width: { md: "117px", xs: "90px" },
    borderRadius: "38px",
  },
  audioPlayer: {
    height: { md: "30%" },
    mt: { xs: 3, sm: 5, md: 0 },
    padding: { xs: "0px 0px 10px 0px", sm: "20px 0px" },
    position: { md: "fixed" },
    width: "100%",
    bottom: 0,
    left: 0,
    background: "#020303",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
  },
  storyModal: {
    width: {
      xs: "100%",
      sm: "100%",
      md: "100%",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    "& .MuiDialog-paper": {
      backgroundColor: "transparent",
      maxHeight: "100vh",
    },
  },
  storySkeleton: {
    width: { xs: "100vw", md: "38vw", lg: "27vw" },
    height: { xs: "100vh", md: "92vh" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "red",
  },
  episodeDataContainer: {
    display: { xs: "none", md: "block" },
    position: "absolute",
    top: "20%",
    color: "white",
    left: "20%",
  },
  playerButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "50px",
    marginTop: { xs: "0px", md: "20px" },
  },
  playerTimerText: {
    color: "white",
    fontWeight: 600,
    fontSize: { xs: "12px", sm: "14px", md: "16px" },
  },
  sponsorText: {
    fontSize: { xs: "16px", md: "20px", lg: "24px" },
    fontWeight: 500,
    color: "#E9E9E980",
  },
  playerBanner: {
    display: { xs: "flex", md: "block" },
    justifyContent: "center",

    position: "relative",
    height: { xs: "50vh", md: "80vh" },
    width: "100%",
    overflow: "hidden",
  },
  playerSpinnerContainer: {
    background: "white",
    height: { xs: 30, md: "64px" },
    width: { xs: 30, md: "64px" },
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playerPlayPauseIcon: {
    background: "white",
    height: { xs: 30, md: "64px" },
    width: { xs: 30, md: "64px" },
    borderRadius: "50%",
    color: "black",
  },

  playerForwardBackwardIcon: {
    cursor: "pointer",
    height: { xs: 24, md: 30 },
    width: { xs: 24, md: 30 },
  },
  episodeUsersText: {
    fontSize: "14px",
    fontWeight: 300,
  },
  podCastTitleText: {
    fontSize: { xs: "30px", md: "50px", lg: "60px" },
    margin: "10px 0px",
    fontWeight: 900,
    color: "white",
  },
  episodeTitleText: {
    fontSize: { xs: "16px", md: "20px", lg: "24px" },
    fontWeight: 500,
    color: "##E9E9E9",
  },
  profiletab: {
    borderColor:
      "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.68) 0%, rgba(255, 255, 255, 0) 100%)",
    borderBottom: " 2px solid transparent",
    borderImageSlice: 1,
  },
  savebtn: { width: 200, ml: 2 },
  dialogBox: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(40,41,86)",
    // backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: " blur(250px)",
    paddingY: 5,
    margin: 0,
    width: "100%",
  },
  dialogBoxMedia: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: " blur(250px)",
    height: "100%",
  },
  dialogbtn: {
    color: primaryColor,
    fontColor: primaryColor,
    // fontFamily: "Poppins",
    fontWeight: "bold",
    textTransform: "capitalize",
    m: -0.5,
  },
  dialogBox1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: 200,
  },
  dialogBox2: {
    display: "flex",
    flexDirection: "row",
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: " blur(250px)",
    flexGrow: 1,
    overflow: "auto",
    // border: "2px solid gray",
  },

  spinnerBox: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    display: "flex",
    my: 2,
  },
  iconColor: {
    color: "linear-gradient(270.04deg, #16E8FF 0.03%, #E900FF 99.97%)",
  },
  marginX1: {
    mx: 0.5,
  },
  fileListBox: {
    borderRadius: 2,
    borderColor: "drop-shadow(2px 1px 12px rgba(255, 255, 255, 0.2))",
    filter: "drop-shadow(2px 1px 12px rgba(255, 255, 255, 0.2))",
    fontFamily: "IBM Plex Mono",
    color: primaryColor,
    textTransform: "none",
    py: 2,
    "&:hover": {
      boxShadow: "2px 1px 12px rgba(255, 255, 255, 0.2)",
    },
  },
  listBox: {
    fontFamily: "Poppins",
    color: PatterColor.secondaryGary,
    textTransform: "none",
    py: 2,
  },

  listBox1: {
    fontFamily: "Poppins",
    color: PatterColor.secondaryGary,
    textTransform: "none",
  },

  width200: { width: { md: 200, xs: "100%" } },
  notificationWeekHeading: {
    color: PatterColor.white,
    pt: 1,
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  notificationUsername: {
    color: PatterColor.white,
    pt: 1,
    px: 1,
    fontFamily: "Poppins",
  },
  searchUsername: {
    color: PatterColor.white,
    px: 1,
    fontFamily: "Poppins",
  },
  followUsername: {
    color: PatterColor.white,
    px: 1,
    fontFamily: "Poppins",
    textTransform: "capitalize",
    fontSize: {
      xs: "12px",
      md: "16px",
    },
  },
  notificationBox: {
    display: "flex",
    pb: 1,
  },
  notificationUserImg: { width: 50, height: 50, cursor: "pointer" },
  followUserImg: { width: { md: 50, xs: 35 }, height: { md: 50, xs: 35 } },
  notificationFollowButton: {
    width: { xs: "90%", sm: "90%", md: "50%", lg: "50%" },
  },
  followButton: {
    pt: "-10px",
    width: 40,
    height: 30,
  },

  notificationPostImage: {
    width: 60,
    height: 60,
    borderRadius: 2,
  },
  checkBoxHeading: {
    color: primaryColor,
    fontFamily: "Poppins",
    justifyContent: "initial",
    fontWeight: "600",
    m: 1,
    ml: 0,
    mt: 2,
  },
  checkBox: {
    background: "green",
    borderRadius: "50%",
    color: primaryColor,
    backgroundSize: "fit-content",
  },
  chatbadge: {
    height: 25,
    minWidth: 25,
    maxWidth: "auto",
    borderRadius: 30,
    Color: primaryColor,
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.17),rgba(233, 0, 255, 1)), linear-gradient(240deg, #16e8ff, #e900ff)",
  },
  selfStory: {
    padding: "20px",
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: "blur(110px);",
  },
  LeftUserNameSubHeading: {
    fontSize: "15px",
    margin: "4px 16px",
    fontStyle: "normal",
    color: "gray",
    fontFamily: "Poppins",
    alignSelf: "flex-start",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  LeftUserNameHeading: {
    margin: "4px 5px",
    fontSize: 15,
    alignSelf: "flex-start",
    fontWeight: "bold",
    textTransform: "capitalize",
    cursor: "pointer",
    profileButton1: {
      margin: 1,
      borderRadius: 3,
      fontWeight: "bold",
      // border: "1px solid red",
      fontFamily: "IBM Plex Mono",
      borderColor: primaryColor,
      color: primaryColor,
      textTransform: "none",
    },
  },
  profileButtonWidth: {
    width: { sm: "100%", xs: "100%" },
    height: 30,
  },
  profileImage2: {
    width: { md: 100, xs: 70, sm: 100 },
    height: { md: 100, xs: 70, sm: 100 },
    borderRadius: 5,
    alignItems: "center",
  },
  commentListBox: {
    height: "30vh",
    maxHeight: "30vh",
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  tagUserListBox: {
    height: "60vh",
    maxHeight: "60vh",
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },

  profileImage5: {
    width: 40,
    height: 40,
    position: "relative",
  },
  like: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    color: "red",
  },
  unlike: {
    color: primaryColor,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
  },
  hideScrollBar: {
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  suggestionListBox: {
    height: { xs: "65vh", sm: "70vh", md: "50vh", xl: "50vh" },
    maxHeight: { xs: "65vh", sm: "70vh", md: "50vh", xl: "50vh" },
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },

  notificationListBox: {
    height: "90vh",
    maxHeight: "90vh",
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  followListBox: {
    height: "50vh",
    overflowY: "scroll",
    overflowX: "hidden",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  postMediaImage: {
    width: { xs: 100, md: 150, sm: 150, lg: 300 },
    height: { xs: 100, md: 150, sm: 150, lg: 300 },
    mt: 2,
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    top: 10,
    color: primaryColor,
    background: "#003333",
  },
  autoHW: {
    height: "auto",
    width: "auto",
  },
  shareIcon: { width: 25, color: primaryColor },
  likeStoryIcon: { width: 25 },
  storyView: {
    m: "5px 2px 5px 2px",
  },
  aboutHeadline: {
    fontFamily: "IBM Plex Mono",
    fontSize: "0.875rem",
  },
  storyStyleMain: {
    background: "transparent",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: "blur(110px);",
  },
  storyLikeBox: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    cursor: "pointer",
  },

  photoCardLike: {
    marginTop: "10px",
    fontSize: "10px",
    cursor: "pointer",
  },
  inputLabel: {
    color: primaryColor,
    fontFamily: "IBM Plex Mono",
  },
  textNewMessage: {
    color: primaryColor,
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "20px",
    position: "relative",
  },
  keyboardArrowButton: {
    width: 30,
    height: 30,
    backgroundColor: PatterColor.fourthGray,
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    display: "flex",
  },

  messageMain2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chatUserListTabs: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #1B1B1B",
    backgroundColor: "transparent",
    color: PatterColor.white,
    marginTop: "10px",
  },
  requestChatMainBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxheight: 300,
    width: "100%",
  },
  requestChatPaperComp: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    border: "2px solid #1B1B1B",
    borderRadius: "15px",
    backgroundColor: "transparent",
    color: PatterColor.white,
    gap: "10px",
    padding: "7px",
  },
  requestChatIconBlock: {
    textTransform: "capitalize",
    borderRadius: "10px",
    fontSize: "16px",
    color: "red",
  },
  requestChantIconDelete: {
    fontSize: "16px",
    borderRadius: "10px",
    color: "red",
    border: "1px solid red",
  },
  requestChatAccept: {
    backgroundColor: "black",
    boxShadow:
      "0 0 6px 0 rgba(157, 96, 212, 0.5), 2px 1000px 1px #000000 inset",
    border: "solid 2px transparent",
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.17), rgba(233, 0, 255, 1)), linear-gradient(240deg, #16e8ff, #e900ff)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    color: "#ffffff",
    fontSize: "16px",
    borderRadius: "10px",
    padding: "10px 20px",
  },
  chatInputBox: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        outline: "none",
      },
      "&:hover fieldset": {
        border: "none",
        outline: "none",
        // padding: "12px 0px",
      },
      "&.Mui-focused fieldset": {
        border: "none",
        outline: "none",
        // padding: "12px 0px",
      },
      "& .MuiInputBase-input": {
        color: "white",
        // padding: "12px 0px",
      },
    },
    width: "100%",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid ",
    borderImage: "linear-gradient(to right, #E604FF , #7E75FE, #1BE2FF) 1 ",
    background:
      " linear-gradient(black, black) padding-box,linear-gradient(to right, #E604FF , #7E75FE, #1BE2FF)border-box ",
    borderRadius: "50px",
    // maxHeight: "80px",
    height: "100%",
  },
  multipleFileStyle: {
    width: "100%",
    position: "absolute",
    bottom: "55px",
    right: "0px",
    display: "flex",
    gap: "10px",
  },
  dialogBoxVal: {
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: " blur(250px)",
  },
  videoPlayerContainer: {
    background: "#3d3d3d ",
    overFlow: "clip",
    color: "white",
    position: "relative",
  },
  ReelsVolumeButton: {
    cursor: "pointer",
    borderRadius: "50%",
    display: "flex",
    background: "rgba(126, 124, 122, 0.3)",
    ":hover": {
      background: "rgba(126, 124, 122, 0.3)",
    },
  },
  ReelsMenuItem: {
    display: "flex",
    gap: 1,
    p: 1,
    // background: "rgb(60, 60, 60, 0.2)",
    "&:hover": {
      background: "rgb(60, 60, 60, 0.5)",
    },
    borderRadius: "8px",
    color: "white",
    cursor: "pointer",
  },
  ReelsMenuText: {
    fontWeight: 500,
  },
  ReelsCommentPopupContainer: {
    height: "365px",
    overflowY: "scroll",
    marginBottom: "20px",
    padding: "10px",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  ReelsSharePopup: {
    height: "365px",
    overflowY: "scroll",
    marginBottom: "20px",
    padding: "10px",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  ReelsVideoPlayer: {
    borderRadius: "8px",
    objectFit: "cover",
    cursor: "pointer",
    overflow: "clip",
  },
  ReelsPlayButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    padding: "20px",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    display: "flex",
  },
  ReelsChildContainer: {
    display: "flex",
    marginBottom: "5vh",
    cursor: "pointer",
    position: "relative",
  },
  ReelsDetailsContainer: {
    position: "absolute",
    bottom: { xs: 5, sm: 20 },
    padding: { xs: 2, sm: "30px" },
  },
  ReelsUserDetailsText: {
    color: "white",
    maxWidth: { xs: "40vw", sm: "20vw", md: "10" },
    fontSize: { xs: 12, sm: 14, md: 16 },
    fontWeight: 520,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  ReelsUserDetailsContainer: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    height: 20,
    maxWidth: { xs: "45vw" },
    // border: "1px solid red",
  },
  popoverPost: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2px",
    // padding: "0px 20px",
    boxShadow:
      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
    borderRadius: "20px",
    cursor: "pointer",
  },
  sendNewMessageDiallog: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    padding: "5px 20px",
    margin: "20px 0",
  },
  deleteMsgTooltip: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    cursor: "pointer",
    padding: "8px",
    fontSize: "14px",
    // backgroundColor: "#090907",
    // border: "1px solid red",
  },
  postShareDialog: {
    display: "flex",
    color: PatterColor.white,
    alignItems: "center",
    marginLeft: "20px",
    gap: "30px",
  },
  storyContainerStyless: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#111111",
    // padding: { md: "10px 0", sm: "10px 0", xs: "0" },
    boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  styleContent: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeStoryButton: {
    marginTop: "30px",
    color: PatterColor.white,
    display: "flex",
    justifyContent: "end",
  },
  closeButtonfont: {
    fontSize: "22px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  dialogTitle: {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
  dialogContent: {
    color: "white",
    fontSize: "14px",
    textAlign: "center",
  },
  dialogCommon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  autoCompleteSearch: {
    borderRadius: 5,
    backgroundColor: PatterColor.secondaryBlack,
    "& .MuiChip-label": {
      color: primaryColor,
    },
    "&:hover .MuiSvgIcon-root": {
      color: primaryColor,
    },
    "& .MuiSvgIcon-root": {
      color: primaryColor,
    },
    color: primaryColor,
    position: "relative",
  },
  storyShareButton: {
    color: PatterColor.white,
    padding: "10px 0",
    textTransform: "none",
    margin: "auto",
    marginTop: "10px",
    width: "80%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "2px",
    boxShadow:
      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
    borderRadius: "20px",
    cursor: "pointer",
    overflow: "hidden",
  },
  createStoryBadge: {
    borderRadius: "50px",
    width: "20px",
    height: "20px",
    color: "white",
    backgroundColor: "#1976D2",
  },
  textInformationSuggestionList: {
    color: "gray",
    display: "flex",
    justifyContent: "flex-start",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  suggestionListUsers: {
    height: "80vh",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  storyContainerBottomStyle: {
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    background: "#111111",
    alignItems: "center",
    padding: "0 10px",
    width: "100%",
    bottom: 0,
    zIndex: 1,
  },
  friendListMain: {
    position: "absolute",
    display: "none",
    zIndex: 1,
    top: "30%",
    opacity: 0.4,
  },
  keyBoardArrowLeft: {
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    background: "white",
    color: "black",
  },
  arrowBtnNext: {
    position: "absolute",
    display: "none",
    zIndex: 1,
    top: "30%",
    right: "0",
    opacity: 0.4,
  },
  rowBoxStyle: {
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    gap: { xs: 1, md: 2 },
    px: 1,
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollBehavior: "smooth",
  },
  menuItem: {
    fontSize: "13px",
    lineHeight: "12px",
    px: 2,
    margin: 0,
    gap: 1,
  },
  storiesMain: {
    display: "flex",
  },
  borderSquare: { border: "0.1px solid #171717" },
  sharedProfileStyle: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "10px",
    padding: "0px 10px",
    gap: "10px",
    alignItems: "center",
  },
  deletePost: { background: "#6a6a6a", height: "0.1em" },
  tagChips: {
    "& .MuiChip-label": {
      color: primaryColor,
    },
    "&:hover .MuiSvgIcon-root": {
      color: primaryColor,
    },
    "& .MuiSvgIcon-root": {
      color: primaryColor,
    },
    color: primaryColor,
    position: "relative",
  },
  borderOutline: {
    "& label.Mui-focused": {
      color: "#5b5a5a",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5b5a5a",
      },
      "&:hover fieldset": {
        borderColor: "#5b5a5a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main",
      },
    },
  },
  commentPopupScrollable: {
    maxWidth: "300px",
    height: "365px",
    overflowY: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  commentPopupScrollable2: {
    // maxWidth: "300px",
    height: "365px",
    overflowY: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  userDetailsBox: {
    height: "100%",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      display: "none",
    },
    width: "100%",
  },
  dialogUserDetail: {
    display: "flex",
    flexDirection: "column",
    width: "25em",
  },
  unfollowDialog: {
    color: "white",
    width: "100%",
    margin: "30px 0px",
    padding: "0px 40px",
    textAlign: "center",
  },
  dialogBoxUserDetail2: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "30px 0px",
  },
  userDetailProfile: {
    borderRadius: "50%",
    height: "100px",
    width: "100px",
  },
  unfollowBtn: {
    color: "red",
    width: "100%",
    height: "45px",
  },
  cancelBtn: {
    color: "white",
    width: "100%",
    height: "50px",
  },
  socialHeading: {
    textTransform: "capitalize",
    color: PatterColor.white,
    fontWeight: "bold",
  },
  imageCropBox: {
    display: "flex",
    marginTop: "10px",
    gap: "20px",
    color: "#FFF",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
  },
  chatBoxStyle: {
    width: {
      xs: "100%",
      sm: "100%",
    },
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  notification2: {
    color: "#FFF",
    display: "flex",
    gap: "20px",
    marginLeft: "20px",
    marginTop: "20px",
  },
  notificationBox1: {
    width: "100%",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  notifiicationSkeleton: {
    width: "400px",
    display: "flex",
    gap: "10px",
    marginBottom: "15px",
  },
  notificationCommonStyle: {
    borderRadius: "10px",
    padding: "5px",
  },
  userMenuItem: {
    display: "flex",
    gap: 1,
    p: 1,
    "&:hover": {
      background: "rgb(60, 60, 60, 0.5)",
    },
    borderRadius: "8px",
    color: "white",
    cursor: "pointer",
  },
  userMenuText: {
    fontWeight: 550,
    fontFamily: "Poppins",
  },
  lowercase: { textTransform: "lowercase" },
  textFlowWraps: {
    overflowWrap: "break-word",
    inlineSize: { xs: "85vw", sm: "85vw", md: "45vw", lg: "31vw", xl: "18vw" },
  },
  btnCreateAccount: {
    backgroundColor: "#9775fa",
    color: "#F6F6F9",
    textTransform: "none",
    borderRadius: "100rem",
    padding: "0.4rem 2.7rem",
    fontSize: "14px",
    fontFamily: "IBM Plex Mono",
    px: 1,
    "&:hover": {
      backgroundColor: "#9775fa",
    },
  },
  textIBM: {
    color: "#F6F6F9",
    display: "flex",
    justifyContent: "center",
    fontFamily: "IBM Plex Mono",
    fontSize: "14px",
  },
  textLearnMore: {
    mt: 1,
    textTransform: "uppercase",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundColor: "#9775FA",
    fontFamily: "Test Sohne Schmal",
    letterSpacing: 2,
    fontSize: {
      md: "4.5rem",
      sm: "4rem",
      xs: "3.5rem",
    },
    fontWeight: "bold",
    lineHeight: {
      xl: "4.5rem",
      lg: "4.5rem",
      md: "4.5rem",
      sm: "4rem",
      xs: "3.5rem",
    },
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  profileInfoContainer: {
    padding: "px",
    width: { xs: "100%", sm: 480 },
    color: "white",
    position: "relative",
  },
  profileInfoAppBar: {
    py: { xs: 0.5, md: 2 },
    px: { xs: 0.5, md: 1 },
    alignItems: "start",
    justifyContent: "space-between",
    display: "flex",
    color: "white",
  },
  profileInfoButtonContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: { xs: 1, sm: 2 },
    bottom: { xs: 5, sm: 10 },
    width: "100%",
    fontFamily: "IBM Plex Mono",
    background: "black",
    py: 1,
  },
  profileInfoNextButton: {
    borderRadius: "38px",
    background: "#9775FA",
    fontFamily: "IBM Plex Mono",
    textTransform: "none",
    color: "white",
    py: { xs: 1, sm: 1 },
    fontSize: { xs: 14, sm: 16 },
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(151,117,250,0.8)",
    },
    width: "100%",
  },
  profileInfoSkipButton: {
    color: "#F6F6F9",
    textTransform: "none",
    fontSize: { xs: 12, sm: 14 },
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      background: "none",
    },
    width: "100%",
    p: 0,
  },
  profileInfoHeading: {
    color: "#9775FA",
    fontSize: { xs: 70, sm: 100 },
    fontFamily: "Test Sohne Schmal",
    fontWeight: 500,
    lineHeight: { xs: "80px", sm: "100px" },
  },
  profileInfoPlayHeading: {
    color: "#82F877",
    fontSize: { xs: 40, sm: 60 },
    fontFamily: "Test Sohne Schmal",
    fontWeight: 600,
    lineHeight: { xs: "80px", sm: "100px" },
  },
  profileInfoAddButton: {
    width: "100%",
    color: "white",
    py: "5px",
    borderRadius: "24px",
    font: "IBM Plex Mono",
    fontSize: 12,
    textTransform: "none",
  },
  createBioInfo: {
    fontSize: { xs: 12, sm: 14, md: 16 },
    fontFamily: "Poppins",
    fontWeight: 300,
    mt: { xs: 3, sm: 5 },
    marginBottom: "10px",
  },
  profileInfoBio: {
    border: "2px solid",
    borderRadius: "16px",
    outline: "none",
    width: "100%",
    maxWidth: "100%",
    fontFamily: "Poppins",
    marginTop: 20,
    padding: 20,
    fontSize: 14,
    fontWeight: 400,
  },
  profileInfoCardImage: {
    width: "100%",
    aspectRatio: "1/1",
    objectFit: "cover",
    borderRadius: "6px",
  },
  profileInfoMostPopularText: {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 20,
    px: 2,
  },
  profileInfoCardText: {
    fontFamily: "IBM Plex Mono",
    fontWeight: 400,
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  choseProfilePhotoButton: {
    textTransform: "none",
    background: "#232334",
    cursor: "pointer",
    border: "2px solid #9775FA",
    borderRadius: "38px",
    textAlign: "center",
    fontFamily: "IBM Plex Mono",
    fontSize: 16,
    py: 1.5,
    mt: 3,
  },
  profileInfoFormContainer: {
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&-ms-overflow-style:": {
      display: "none",
    },
  },
  profilePhotoClear: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#9775FA",
    display: "flex",
    alignItems: "center",
    p: 0.5,
    borderRadius: "50%",
    cursor: "pointer",
  },
  textLearnMoreFont: {
    fontSize: {
      md: "3.5rem",
      sm: "3rem",
      xs: "2.5rem",
    },
  },
  mobileStepper: {
    backgroundColor: "transparent",
    "& .MuiMobileStepper-dots": {
      "& .MuiMobileStepper-dot": {
        backgroundColor: "#232334",
      },
      "& .MuiMobileStepper-dotActive": {
        backgroundColor: "#82F877",
      },
    },
  },
  reelsContainer2: {
    position: "fixed",
    display: { xs: "flex", md: "none" },
    alignItems: "center",
    color: "white",
    gap: 2,
    mx: 2,
    my: 1.5,
    top: 0,
    left: 0,
    zIndex: 10,
    cursor: "pointer",
  },
  reelsBackBtn: {
    width: 28,
    height: 28,
    my: 0.5,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    display: "flex",
    color: "white",
  },
  sharePopUp: {
    margin: "auto",
    height: "35vh",
    overflow: "scroll",
    "::-webkit-scrollbar": {
      display: "none",
    },
    paddingTop: "20px",
  },
  reportBugMain: {
    width: 25,
    height: 25,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    display: { md: "none", xs: "flex" },
    marginRight: 1,
  },
  uploadImagestyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    ml: 1,
    mb: 4,
    flexWrap: "wrap",
  },
  uploadImageAvatar: {
    width: 60,
    height: 60,
    alignItems: "center",
    ml: 0.2,
    my: 1,
  },
  storyViewPost: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    zIndex: 999999,
    cursor: "pointer",
    backgroundImage: `linear-gradient(
      rgb(243 231 231 / 50%), rgb(237 225 225 / 27%), rgb(247 240 240 / 26%)
    )`,
    borderRadius: "5px",
  },
  iconButtonStory: {
    color: "#FFF",
    fontSize: "12px",
    zIndex: 999999,
  },
  profillingSelectDiv: {
    minHeight: "65px",
    // mb: "15px",
    padding: "8px 0",
  },
  profileInfoAvatar: {
    width: "250px",
    height: "250px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  mobileCommentBox: {
    p: 2,
    height: "100vh",
    width: "auto",
    background: "black",
    color: "white",
  },
  mobileCommentBox2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  mobileCommentBox3: {
    width: 30,
    height: 30,
    backgroundColor: PatterColor.fourthGray,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    display: "flex",
    marginRight: 1,
  },
  mobileCommentBox4: {
    width: 30,
    height: 30,
    backgroundColor: PatterColor.fourthGray,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    display: "flex",
    marginRight: 1,
  },
  mobileCommentBox5: {
    mt: 2,
    px: 2,
    height: "82vh",
    overflowY: "scroll",
    width: "auto",
    background: "black",
    color: "white",
    zIndex: 99999,
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },
  mobilePostSharePopup: {
    mt: 2,
    height: "70vh",
    overflowY: "scroll",
    width: "auto",
    background: "black",
    color: "white",
    zIndex: 99999,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mobileShareBox: {
    p: 2,
    height: "100vh",
    width: "auto",
    background: "black",
    color: "white",
  },
  mobileShareBox2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  mobileShareCloseIcon: {
    width: 30,
    height: 30,
    backgroundColor: PatterColor.fourthGray,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    display: "flex",
  },
  postImageTypogragphy: {
    fontSize: "14px",
    color: "#FFF",
    bgcolor: "rgba(0,0,0,.5)",
    opacity: 0.7,
    borderRadius: 10,
    width: 45,
    m: 0.5,
    textAlign: "center",
  },
  chatUserListBox: {
    textTransform: "capitalize",
    width: { xs: "97vw", md: "310px" },
    color: PatterColor.white,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatUserComponentBox1: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
  },
  chatUserComponentBox2: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  chatRequestedBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  videoPlayersItemBar: {
    background: "transparent",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  videoPlayerSpinner: {
    bgcolor: "grey.800",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoPlayersBox: {
    width: "100%",
    height: "60vh",
    maxHeight: { md: "500px" },
  },
  postShareRecordStyle: {
    color: "gray",
    height: "30vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 650,
  },
};
