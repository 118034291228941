import axios from "axios";
import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";

const apiCallGetBasicAccountSetting = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.accountSetting.basicAccountSetting + reqBody}`,
      {},
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPutBasicAccountSetting = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      `${endPoint.accountSetting.basicAccountSetting + reqBody.user_uuid}`,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetPrivacyAccountSetting = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.accountSetting.privacyAccountSetting + reqBody}`,
      {},
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPutPrivacyAccountSetting = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      `${endPoint.accountSetting.privacyAccountSetting + reqBody.user_uuid}`,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetUserPermissionSetting = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.accountSetting.userPermissionSetting + reqBody}`,
      {},
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPutUserPermissionSetting = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      `${endPoint.accountSetting.userPermissionSetting + reqBody.user_uuid}`,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetFeedControl = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.accountSetting.feedControlSetting + reqBody}`,
      {},
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPutFeedControl = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      `${endPoint.accountSetting.feedControlSetting + reqBody.user_uuid}`,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPostAddMediaReportBug = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      `${endPoint.accountSetting.reportBugAddMedia}`,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostReportBug = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      `${endPoint.accountSetting.reportBug}`,
      reqBody,
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

export const ServiceAccountSetting = {
  apiCallGetBasicAccountSetting,
  apiCallPutBasicAccountSetting,
  apiCallGetPrivacyAccountSetting,
  apiCallPutPrivacyAccountSetting,
  apiCallGetUserPermissionSetting,
  apiCallPutUserPermissionSetting,
  apiCallGetFeedControl,
  apiCallPutFeedControl,
  apiCallPostAddMediaReportBug,
  apiCallPostReportBug,
};
