import axios from "axios";
import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";

const apiCallGetGalleryPost = (
  page: number,
  pageSize: number,
  token: string,
) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.getGalleryPost}?page_size=${pageSize}&page=${page}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetFeedsOfFollowingUsers = (
  page: number,
  pageSize: number,
  token: string,
) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.feedsOfFollowingUsers}?page_size=${pageSize}&page=${page}&is_refresh=true`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostLikePost = (postId: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.likePost + postId}/`;
    ServiceRequest.PostApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetCommentOnPost = (
  page: number,
  pageSize: number,
  postId: string,
  token: string,
) => {
  return new Promise((resolve, reject) => {
    let url = `${
      endPoint.dashboard.commentOnPost + postId
    }/?page_size=${pageSize}&page=${page}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPostCommentOnPost = (postId: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.likePost + postId}/`;
    ServiceRequest.PostApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPostCommentLikeUnlike = (commentId: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.commentLikeUnlike + commentId}/`;
    ServiceRequest.PostApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostAddComment = (postId: string, reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.commentOnPost + postId}/`;
    ServiceRequest.PostApiRequest(url, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetStoriesList = (
  token: string,
  pageNumber: any,
  pageSize: any,
) => {
  let url = `${endPoint.dashboard.getStoriesList}?page=${pageNumber}&page_size=${pageSize}&is_refresh=true`;
  ServiceRequest.GetApiRequest(url, {}, token);
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallCommentOnStory = (token: string, uuid: string, comment: any) => {
  let url = `${endPoint.dashboard.commentOnStory}${uuid}/`;
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(url, { comment: comment }, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSendMessageList = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      `${endPoint.dashboard.sendMessageUserList} `,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetOwnStories = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(`${endPoint.dashboard.getOwnStory}`, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallStorySeenLiked = (storyId: string, storyLike: any, token: any) => {
  return new Promise((resolve, reject) => {
    let url =
      `${endPoint.dashboard.storiesSeenLike + storyId}` +
      `/?is_like=${storyLike}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForAddMediaForPost = (info: any, token: any) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.addMedia;
    ServiceRequest.PostApiRequest(url, info, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForCreatePost = (info: any, token: any) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.createPost;
    ServiceRequest.PostApiRequest(url, info, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallForEditPost = (uuid: any, info: any, token: any) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.createPost + uuid + "/";
    ServiceRequest.PutApiRequest(url, info, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallForAddNewLocation = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.dashboard.newLocation,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetUserList = (userName: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.explore + userName}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (res.count > 0) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallExploreMessage = (userName: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.msgExplore + userName}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (res.count > 0) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallDeleteTag = (uuid: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.DeleteApiRequest(
      `${endPoint.dashboard.tagPeopleMediaFiles + uuid + "/"}`,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPutAddTag = (mediaId: any, reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      `${endPoint.dashboard.tagPeopleMediaFiles + mediaId + "/"}`,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetLocationList = (locationName: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.location + locationName}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (res.count > 0) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetPopularUsersList = (
  page: number,
  pageSize: number,
  token: string,
) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.popularUsersList}?page_size=${pageSize}&page=${page}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallPostSavePost = (postId: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.savePost + postId}/`;
    ServiceRequest.PostApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetSavedPost = (postId: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.savePost + postId}/`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallDeleteSavedPost = (postId: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.savePost + postId}/`;
    ServiceRequest.DeleteApiRequest(url, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetAthleteList = (token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.guardianAthleteList;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallForDeletePost = (uuid: string, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.deletePost}${uuid}/`;
    ServiceRequest.DeleteApiRequest(url, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallAddStory = (request: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.addStory;
    ServiceRequest.PostApiRequest(url, request, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForDeleteStory = (storyUuuid: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.deleteStory + `${storyUuuid}/`;
    ServiceRequest.DeleteApiRequest(url, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForReportOnPost = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.reportOnPost;
    ServiceRequest.PostApiRequest(url, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallPostNotInterestedInPost = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.postNotInterested;
    ServiceRequest.PostApiRequest(url, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetPostById = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.getPostById;
    ServiceRequest.GetApiRequest(url, params, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetSports = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.searchSport;
    ServiceRequest.GetApiRequest(url, params, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetCategories = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.searchCategories;
    ServiceRequest.GetApiRequest(url, params, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallOnboardingFlow = (body: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.dashboard.onboardingFlow;
    ServiceRequest.PutApiRequest(url, body, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSearchHastags = (searchParam: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.exploreHastags + searchParam}`;

    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSearchLocation = (searchParam: any, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.dashboard.exploreLocation + searchParam}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

export const ServiceDashBoard = {
  apiCallGetGalleryPost,
  apiCallGetFeedsOfFollowingUsers,
  apiCallPostLikePost,
  apiCallPostCommentOnPost,
  apiCallGetCommentOnPost,
  apiCallPostCommentLikeUnlike,
  apiCallForAddMediaForPost,
  apiCallForCreatePost,
  apiCallGetUserList,
  apiCallPutAddTag,
  apiCallGetLocationList,
  apiCallPostAddComment,
  apiCallGetStoriesList,
  apiCallGetOwnStories,
  apiCallSendMessageList,
  apiCallStorySeenLiked,
  apiCallGetPopularUsersList,
  apiCallPostSavePost,
  apiCallGetSavedPost,
  apiCallDeleteSavedPost,
  apiCallGetAthleteList,
  apiCallForDeletePost,
  apiCallAddStory,
  apiCallForDeleteStory,
  apiCallForReportOnPost,
  apiCallPostNotInterestedInPost,
  apiCallGetPostById,
  apiCallCommentOnStory,
  apiCallForEditPost,
  apiCallDeleteTag,
  apiCallGetSports,
  apiCallGetCategories,
  apiCallOnboardingFlow,
  apiCallForAddNewLocation,
  apiCallSearchHastags,
  apiCallSearchLocation,
  apiCallExploreMessage,
};
