import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";
import { getCleanQueryString } from "../user-profile";
const apiCallGetNotificationList = (token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.notification.notificationList}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallForAcceptReject = (params: any, token: string) => {
  return new Promise((resolve, reject) => {
    const queryString = getCleanQueryString(params);
    let url = `${endPoint.notification.acceptReject}?${queryString}`;
    ServiceRequest.PutApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetNotificationCount = (token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.notification.notificationCount}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetNotificationsRead = (token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.notification.notificationsRead}`;
    ServiceRequest.GetApiRequest(url, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
export const ServiceNotification = {
  apiCallGetNotificationList,
  apiCallForAcceptReject,
  apiCallGetNotificationCount,
  apiCallGetNotificationsRead,
};
