import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ServiceUserProfile } from "../../../api";
import { CommonButton } from "../../../components/common";
import {
  FOLLOW_STATUS,
  getFollowButtonLabel,
} from "../../../helper/methods/methods";
import { useDispatch } from "react-redux";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";

const ReelsFollowButton = ({ data, handleFollow }: any) => {
  const { token } = useSelector((state: any) => state.rLogin.infoLogin);
  const dispatch = useDispatch();
  const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState("");
  const apiCallPostFollowUnfollowUser = (userUid: any) => {
    setIsFollowLoading(true);
    let requested_by_id = userUid;
    ServiceUserProfile.apiCallPostFollowUnfollowUser({ requested_by_id }, token)
      .then((res: any) => {
        setIsFollowLoading(false);
        if (res?.data?.is_follow) {
          dispatch(
            showSocialSnackbar({
              message: "Request Sent Successfully",
              type: "success",
            }),
          );
        } else {
          dispatch(
            showSocialSnackbar({
              message: "Request is Revoked Successfully",
              type: "success",
            }),
          );
        }
      })
      .catch((err) => {
        setIsFollowLoading(false);
      });
  };

  useEffect(() => {
    setButtonText(
      getFollowButtonLabel(data?.request_status, data?.follow_status),
    );
  }, [data]);

  return (
    <>
      {buttonText === FOLLOW_STATUS.FOLLOW && (
        <CommonButton
          buttonText={
            <Typography
              textTransform={"capitalize"}
              sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, fontWeight: 520 }}
              onClick={(e) => {
                e.stopPropagation();
                const label =
                  // buttonText === FOLLOW_STATUS.FOLLOWING ||
                  // buttonText === FOLLOW_STATUS.REQUESTED
                  //   ? FOLLOW_STATUS.FOLLOW
                  //   :
                  FOLLOW_STATUS.REQUESTED;
                setButtonText(label);
                handleFollow(data.posted_by_data?.user_uuid);
                apiCallPostFollowUnfollowUser(data?.posted_by_data?.user_uuid);
              }}
            >
              {buttonText}
            </Typography>
          }
          buttonType="text"
          buttonSize="small"
          buttonStyle={{ padding: "0px 15px 0px 15px !important", width: 100 }}
          onClicked={() => {
            const label =
              // buttonText === FOLLOW_STATUS.FOLLOWING ||
              // buttonText === FOLLOW_STATUS.REQUESTED
              //   ? FOLLOW_STATUS.FOLLOW
              //   :
              FOLLOW_STATUS.REQUESTED;
            setButtonText(label);
            apiCallPostFollowUnfollowUser(data?.posted_by_data?.user_uuid);
          }}
        />
      )}
    </>
  );
};

export default ReelsFollowButton;
