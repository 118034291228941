import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ServiceDashBoard, ServiceUserProfile } from "../../api";
import { Plaintextinput } from "../../components/common";
import AutoCompleteInput from "../../components/common/auto-complete-input";
import AutoCompleteSelect from "../../components/common/auto-complete-select";
import Loader from "../../components/common/loader";
import CommonSwitch from "../../components/common/switch";
import { PostImage } from "../../components/dashboard/post-card";
import { showSocialSnackbar } from "../../store/reducer/Snackbar";
import { styles } from "../../theming/Style/Styles";
import { AppStringUtils } from "../../utils";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const renderBioCharacterCount = (count: any) => {
  return (
    <Box sx={{ mt: 1, ...styles.alignEnd }}>
      <Typography sx={styles.textInformation1}>
        {count?.length ? [...count].length : 0}
        {"/150"}
      </Typography>
    </Box>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function EditPost(props: any) {
  const { user_uuid, token } = useSelector(
    (state: any) => state.rLogin.infoLogin,
  );
  const dispatch: any = useDispatch();
  const location = useLocation();

  const { showPopupEditPost, setShowPopupEditPost } = props;
  const t = AppStringUtils();
  const [postData, setPostData] = useState<any>({});
  const isMobileOrTablet = useMediaQuery("(max-width: 901px)");
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagPeopleList, setTagPeopleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [tagPeopleValue, setTagPeopleValue] = React.useState([]);
  const [locationId, setLocationId] = React.useState<any>({});
  const [initialTaggedValue, setInitialTaggedValue] = useState<any>([]);
  const FILE_SIZE = 5242880;

  const handleClosePopup = () => {
    setShowPopupEditPost(false);
    setTagPeopleList([]);
    setLocationList([]);
    formik.resetForm();
  };

  const formik: any = useFormik({
    initialValues: {
      file_name: "",
      user_uuid: user_uuid,
      file_url: null,
      file_type: "",
      file_description: "",
      caption: "postData?.post_caption",
      is_liked_allowed: postData?.is_liked_allowed,
      is_comment_allowed: postData?.is_comment_allowed,
      is_post_share_as_msg_allowed: postData?.is_post_share_as_msg_allowed,
      is_saved_allowed: postData?.is_saved,
    },
    validationSchema: Yup.object({
      file_name: Yup.string().required(t.WM_FILENAME_REQUIRED),
      file_url: Yup.mixed()
        .required(t.WM_FILEURL_REQUIRED)
        .test(
          "FILE_SIZE",
          t.WM_FILEURL_SIZE,
          (value) => !value || (value && value[0].size <= FILE_SIZE),
        ),
      file_type: Yup.string().required(t.WM_FILETYPE_REQUIRED),
      file_description: Yup.string().required(t.WM_FILEDESCRIPTION_REQUIRED),
      caption: Yup.string()
        .typeError(t.WM_CAPTION_MAX)
        .test("FILE_FORMAT", t.WM_CAPTION_MAX, (value) =>
          value ? [...value]?.length <= 150 : true,
        ),
    }),
    onSubmit: (values: any) => {
      values.file_url = values.file_url[0];
      let formData: any = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
    },
  });
  useEffect(() => {
    if (isMobileOrTablet) {
      handleClosePopup();
    }
  }, [isMobileOrTablet]);

  const advanceSetting = [
    {
      label: t.allowLike,
      name: "is_liked_allowed",
      value: formik.values.is_liked_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_liked_allowed,
      formik: formik,
    },
    {
      label: t.allowComments,
      name: "is_comment_allowed",
      value: formik.values.is_comment_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_comment_allowed,
      formik: formik,
    },
    {
      label: t.allowSharePostAsMessage,
      name: "is_post_share_as_msg_allowed",
      value: formik.values.is_post_share_as_msg_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_post_share_as_msg_allowed,
      formik: formik,
    },
    {
      label: t.allowSavePost,
      name: "is_saved_allowed",
      value: formik.values.is_saved_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_saved_allowed,
      formik: formik,
    },
  ];

  const handleBioChange = (event: any) => {
    const inputValue = [...event.target.value];
    inputValue.filter((e: any) => {
      return e > 1;
    });
    const truncatedValue =
      inputValue.length > 150 ? inputValue.slice(0, 150) : inputValue;
    formik.setFieldValue(
      event.target.name,
      [...truncatedValue].join("").toString(),
    );
  };

  let newValueBio = [];

  if (formik.values.caption) {
    newValueBio = [...formik?.values?.caption]?.filter((e: any, index: any) => {
      return e !== null;
    });
  }

  const apiCallEditPost = (listUuid: any) => {
    let postTagsData = formik.values.caption;
    let words = ["#"];
    postTagsData = postTagsData.split(" ");

    // postTagsData = postTagsData.filter((v: any) => v.match("#")).join(" ");
    // while (postTagsData.match(/[^#]#/)) {
    //   postTagsData = words.reduce((r, w) => r.replace(w, ""), postTagsData);
    // }
    // postTagsData = postTagsData.split(" ");
    // postTagsData = postTagsData.filter(
    //   (item: any, index: any) => postTagsData.indexOf(item) === index,
    // );

    postTagsData = postTagsData
      .flatMap((tag: any) => tag.match(/#[^\s#]+\b|\d+\b/g)) // Include \d+\b for matching numbers
      .join(" ") // Join into a single string
      .match(/[a-zA-Z\d]+/g); // Include \d in the character class

    if (!postTagsData || postTagsData.length === 0) {
      postTagsData = [];
    }

    let finalArr: any = postData?.post_files.map((item: any) => item.uuid);

    let dict = {
      post_type: postData?.post_type,
      is_liked_allowed: formik.values.is_liked_allowed,
      is_comment_allowed: formik.values.is_comment_allowed,
      is_post_share_as_msg_allowed: formik.values.is_post_share_as_msg_allowed,
      is_saved_allowed: formik.values.is_saved_allowed,
      is_post_share_on_other_platform_allowed:
        formik.values.is_post_share_on_other_platform_allowed,
      post_tags: postTagsData,
      post_caption: formik.values.caption,
      is_publish: true,
      media_uuid: finalArr,
      location_uuid: locationId?.uuid || "",
    };

    // if (dict.post_tags[0] === "") {
    //   delete dict.post_tags;
    // }

    if (tagPeopleValue) {
      tagPeopleValue.forEach((tpValue: any, index: any) => {
        if (
          !initialTaggedValue
            .map((el: any) => el.tagged_user.user_uuid)
            .includes(tpValue.user_uuid)
        ) {
          finalArr.forEach((media_id: string) => {
            apiCallTagPeople(media_id, tpValue.user_uuid);
          });
        }
      });
    }

    initialTaggedValue.forEach((tag: any) => {
      if (
        !tagPeopleValue
          .map((item: any) => item.user_uuid)
          .includes(tag.tagged_user.user_uuid)
      ) {
        apiCallDeleteTagPeople(tag.uuid);
      }
    });

    return ServiceDashBoard.apiCallForEditPost(props?.uuid, dict, token)
      .then((res: any) => {
        setIsLoading(false);
        if (location?.pathname?.includes("/view-post")) {
          window.location.reload();
        } else {
          if (props.info !== undefined) {
            props?.setInfo(res?.data);
          }
          props?.setData((prevData: any) => {
            return prevData?.map((item: any) => {
              if (item.uuid === props?.uuid) {
                return { ...item, ...res.data };
              }
              return item;
            });
          });

          handleClosePopup();
          props?.setIsModalOpen && props?.setIsModalOpen(false);
          dispatch(
            showSocialSnackbar({
              message: t.updateMessage,
              bgColor: "#4DAE50",
            }),
          );
        }
        // router(0);
      })
      .catch((err: any) => {
        setIsLoading(false);
        dispatch(showSocialSnackbar({ message: err, bgColor: "#4DAE50" }));
      });
  };

  const getTagPeopleList = (userName: string) => {
    let dataParam = {
      uuid: user_uuid,
      search: userName === "" ? "" : userName,
      list_type: "follower",
      page_size: 20,
      page: 1,
    };
    ServiceUserProfile.apiCallGetFollowerFollowing(dataParam, token)
      .then((res: any) => {
        if (res.data) {
          setIsLoading(false);
          let dataList = res.data.map((item: any) => {
            return { ...item.requested_by_data };
          });
          setTagPeopleList(dataList);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const apiCallTagPeople = (mediaId: any, user_uuid: any) => {
    setIsLoading(true);
    let reqBody = { user_uuid: user_uuid, x_axis: 120, y_axis: 120 };
    ServiceDashBoard.apiCallPutAddTag(mediaId, reqBody, token)
      .then((res: any) => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  const apiCallDeleteTagPeople = (tag_uuid: any) => {
    setIsLoading(true);
    ServiceDashBoard.apiCallDeleteTag(tag_uuid, token)
      .then((res: any) => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  const getLocationList = async (location: any) => {
    if (location) {
      await axios(
        `https://nominatim.openstreetmap.org/?addressdetails=&format=json&q=${location}`,
      )
        .then((res: any) => {
          setIsLoading(false);
          setLocationList(res.data);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    } else if (location === "") {
      setTagPeopleList([]);
    }
  };

  const apiCallGetPostById = () => {
    setPostIsLoading(true);
    ServiceDashBoard.apiCallGetPostById({ post_uuid: props?.uuid }, token)
      .then((res: any) => {
        setPostIsLoading(false);
        if (res) {
          setPostData({
            ...res.data,
            post_files: res?.data?.post_files?.map((item: any) => ({
              ...item,
              tagged_users: [],
            })),
          });
          setTagPeopleValue(
            res.data?.post_files[0]?.tagged_users?.map(
              (item: any) => item.tagged_user,
            ),
          );
          setInitialTaggedValue(
            res?.data?.post_files[0]?.tagged_users?.map((item: any) => item),
          );

          setLocationId(res?.data?.post_location);
          formik.values.caption =
            res.data?.post_caption !== null && res.data?.post_caption.length > 0
              ? res.data?.post_caption
              : Array.isArray(res?.data?.post_tags) &&
                res?.data?.post_tags.length > 0
              ? "#" + res?.data?.post_tags
              : "";

          formik.values.is_liked_allowed = res.data?.is_liked_allowed;
          formik.values.is_comment_allowed = res.data?.is_comment_allowed;
          formik.values.is_post_share_as_msg_allowed =
            res.data?.is_post_share_as_msg_allowed;
          formik.values.is_saved_allowed = res.data?.is_saved_allowed;
        }
      })
      .catch(() => {
        setPostIsLoading(false);
        showPopupEditPost(false);
      });
  };

  useEffect(() => {
    showPopupEditPost && apiCallGetPostById();
    showPopupEditPost && getTagPeopleList("");
  }, [showPopupEditPost]);

  const captionFieldValue = {
    inputType: "text",
    placeholder: t.textPlaceholderCaption,
    name: "caption",
    //  formik?.values?.caption,
    value: newValueBio.join("").toString(),
    handleBlur: formik.handleBlur,
    handleChange: handleBioChange,
    touchedValue: formik.touched.caption,
    errorsValue: formik.errors.caption,
    multiline: true,
    rows: 3,
  };
  return (
    <Dialog
      fullScreen
      open={showPopupEditPost}
      onClose={handleClosePopup}
      TransitionComponent={Transition}
    >
      <Box sx={styles.dialogBoxMedia}>
        <DialogTitle sx={{ ...styles.textInformation3, fontWeight: "bold" }}>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              left: 14,
              top: 10,
              color: "white",
              ...styles.pointer,
            }}
            onClick={handleClosePopup}
          >
            <Close />
          </IconButton>
          {t.buttonTextEdit}

          <Box>
            <Typography
              sx={{
                position: "absolute",
                right: 25,
                top: 20,
                color: "white",
                fontFamily: "Poppins",
                ...styles.pointer,
              }}
              onClick={apiCallEditPost}
            >
              {t.textUpdate}
            </Typography>
          </Box>
        </DialogTitle>
        <Divider color="grey" sx={styles.autoHW} />

        {!postIsLoading && (
          <Grid container spacing={2} sx={{ py: 4, px: 5 }}>
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "35vw",
                }}
              >
                <PostImage
                  postFiles={postData?.post_files}
                  postType={postData?.post_type}
                  handleClosePopup={handleClosePopup}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ mx: 2, flex: 1, textAlign: "right" }}
            >
              <Box sx={{ px: 2 }}>
                <Box>
                  <Plaintextinput
                    textFieldValue={captionFieldValue}
                    borderCurved={true}
                  />
                  {renderBioCharacterCount(captionFieldValue.value)}
                </Box>
                <Box
                  sx={{
                    ...styles.alignSpaceBetween,
                    mt: 3,
                    mb: 1,
                  }}
                >
                  <Box>
                    <Typography sx={styles.textInformation7}>
                      {t.textAddLocation}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <AutoCompleteSelect
                    onClickCall={getLocationList}
                    listData={locationList}
                    selectedValues={setLocationId}
                    optionValue={"display_name"}
                    defaultValue={postData.post_location}
                  />
                </Box>
                <Box sx={{ my: 3 }}>
                  <Box
                    sx={{
                      ...styles.alignSpaceBetween,
                      my: 1,
                    }}
                  >
                    <Box>
                      <Typography sx={styles.textInformation7}>
                        {t.textAdvancedSetting}
                      </Typography>
                    </Box>
                  </Box>
                  {advanceSetting.map((item: any, index: number) => (
                    <Box sx={{ ...styles.alignSpaceBetween, pb: 1.5 }}>
                      <Box>
                        <Typography sx={styles.textInformation5}>
                          {item?.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={styles.listBox1}>
                          <Box sx={{ px: 2, ...styles.pointer }}>
                            <CommonSwitch switchValue={item} />
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  <Typography sx={styles.textInformation5}>
                    {t.textChangePost}
                  </Typography>
                </Box>
                <Box sx={{ my: 1, textAlign: "start" }}>
                  <Typography sx={styles.textInformation7}>
                    {t.textTagPeople}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "start" }}>
                  <AutoCompleteInput
                    onClickCall={getTagPeopleList}
                    tagPeopleList={tagPeopleList}
                    onValueSelectCall={apiCallTagPeople}
                    selectedValues={setTagPeopleValue}
                    optionValue1={"first_name"}
                    optionValue2={"last_name"}
                    defaultValue={tagPeopleValue}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        <Box>{(isLoading || postIsLoading) && <Loader />}</Box>
      </Box>
    </Dialog>
  );
}
