import { symlink } from "fs";
import React from "react";

const ProfileImage = ({ imageUrl }: { imageUrl: string | null }) => {
  const handleImageError = (event: any) => {
    event.target.src = "/fallback-image.png";
  };

  const imageSrc =
    imageUrl ||
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  return (
    <img
      src={imageSrc}
      onError={handleImageError}
      alt="Image"
      height={28}
      width={28}
      style={{ objectFit: "cover", borderRadius: "50%" }}
    />
  );
};

export default ProfileImage;
