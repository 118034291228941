import { createSlice } from "@reduxjs/toolkit";

interface languageProps {
  appString: any | "en";
  languageCode: string;
}

const initalStates: languageProps = {
  appString: require(`../../../strings/en.json`),
  languageCode: "en",
};

const languageSlice = createSlice({
  name: "language",
  initialState: initalStates,
  reducers: {
    actionChangeLanguage(state: any, action: any) {
      state.appString = require(`../../../strings/${action.payload}.json`);
    },
  },
});

export const { actionChangeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
