import { Skeleton } from "@mui/material";
import React from "react";

const CommonSkeleton = (props: any) => {
  const { styles, height, width, variant } = props;
  return (
    <Skeleton
      animation="none"
      sx={{ ...styles, bgcolor: "grey.800" }}
      variant={variant}
      height={height}
      width={width}
      {...props}
    />
  );
};

export default CommonSkeleton;
