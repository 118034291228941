import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageUtils } from "../../../helper";
import { KEY_LOCAL_STORAGE } from "../../../helper/local-storage";
interface UserLoginReducerProps {
  userInfo: any | {};
}
const UserLoginSlice = createSlice({
  name: "userLoginSlice",
  initialState: {
    userInfo: {},
  },
  reducers: {
    actionUserLogin(state: UserLoginReducerProps, action) {
      LocalStorageUtils.SET_LOCAL_STORAGE_DATA(
        KEY_LOCAL_STORAGE.browserToken,
        JSON.stringify(action.payload),
      );
      state.userInfo = action.payload;
    },
  },
});

export const { actionUserLogin } = UserLoginSlice.actions;

export default UserLoginSlice.reducer;
