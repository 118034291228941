import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/route-path";
import { styles } from "../../../theming/Style/Styles";
import VideoPlayers from "../../common/video-players";
import TagUser from "./tag-user";
import { useDispatch, useSelector } from "react-redux";
import { actionSetPostIndex } from "../../../store/reducer/SavedPost";

export default function PostImage(props: any) {
  const { isClosed } = useSelector((state: any) => state.savedPost);
  const { postFiles, postType, popup, showCommentPopup, chat } = props;
  const router = useNavigate();
  const isMobile = useMediaQuery("(max-width: 500px)");
  const [mediaDimensions, setMediaDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [image, video, reels, carousel] = [
    "image",
    "video",
    "reels",
    "carousel",
  ];
  const [showPopup, setShowPopup] = useState<Boolean>(false);
  const [showTags, setShowTags] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [db1, setDB] = useState(0);

  const openPopup = () => {
    setShowPopup(!showPopup);
  };

  const ImageItemBar = (itemData: any) => {
    return (
      <>
        <ImageListItemBar
          sx={{
            marginLeft: "50px",
            background: "transparent",
          }}
          position="top"
          actionIcon={
            <IconButton
              sx={{ color: "white" }}
              onClick={() => {
                openPopup();
              }}
            >
              <AccountCircle />
            </IconButton>
          }
          actionPosition="left"
        />
      </>
    );
  };
  const TaggedUser = ({ user, index }: any) => {
    const separationFactorX = 10;
    const separationFactorY = 30;

    const adjustedX =
      (user?.x_axis / mediaDimensions.width) * 100 + index * separationFactorX;
    const adjustedY =
      (user?.y_axis / mediaDimensions.height) * 100 + index * separationFactorY;

    const style = {
      position: "absolute",
      left: `${adjustedX}px`,
      top: `${adjustedY}px`,
      zIndex: 100, // Ensure tags are above the image
    };
    return (
      <Box
        sx={{
          ...styles.taggedUserContainer,
          ...style,
        }}
        onClick={(e) => {
          e.stopPropagation();
          props?.setIsModalOpen(false);
          router(RoutePath.userProfile + user.tagged_user.user_uuid);
        }}
      >
        <Box sx={styles.taggedUserArrow}></Box>
        <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>
          @{user.tagged_user?.username}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (isClosed) {
      props?.setIsModalOpen(false);
    }
  }, [isClosed, props]);

  useEffect(() => {
    dispatch(actionSetPostIndex(0));
    setIsLoading(true); // Set isLoading to true when getImageDimensions is called
    if (mediaUrl) {
      getImageDimensions(mediaUrl)
        .then((dimensions: any) => {
          setMediaDimensions(dimensions);
          setIsLoading(false); // Set isLoading to false when dimensions are set
        })
        .catch((error) => {
          console.error("Error loading image dimensions:", error);
          setIsLoading(false); // Set isLoading to false in case of error
        });
    }
  }, [mediaUrl]);

  const getImageDimensions = (imageUrl: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <ImageListItem>
      {postFiles?.length > 1 ? (
        <>
          <Carousel
            autoPlay={false}
            cycleNavigation={false}
            swipe={true}
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            sx={{
              zIndex: 0,
            }}
            animation={"slide"}
            onChange={(index: any) => {
              setDB(index);
              dispatch(actionSetPostIndex(index));
            }}
          >
            {postFiles?.map((item: any, index: any) => (
              <>
                {item?.media_type.toLowerCase() === image && (
                  <Box
                    key={index}
                    onClick={() => {
                      setMediaUrl(item.media_url);
                      setShowTags((prev) => !prev);
                    }}
                    sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        ...styles.alignCenter,
                        position: "absolute",
                        top: 8,
                        right: 10,
                      }}
                    >
                      <Typography sx={styles.postImageTypogragphy}>
                        {`${index + 1}/${postFiles?.length}`}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      loading="lazy"
                      sx={popup ? styles.postImage1 : styles.postImage}
                      image={item?.media_url}
                    />
                    {postFiles[db1]?.tagged_users &&
                      postFiles[db1]?.tagged_users?.length > 0 && (
                        <ImageItemBar item={postFiles[db1]?.tagged_users} />
                      )}
                    {!chat && (
                      <TagUser
                        showPopup={showPopup}
                        setShowPopup={setShowPopup}
                        tagUserList={postFiles[db1]?.tagged_users}
                        tagType="image"
                        setIsModalOpen={props?.setIsModalOpen}
                      />
                    )}
                    {!isLoading &&
                      showTags &&
                      !chat &&
                      !showPopup &&
                      postFiles[db1]?.tagged_users?.map(
                        (user: any, index: number) => {
                          return <TaggedUser user={user} index={index} />;
                        },
                      )}
                  </Box>
                )}
              </>
            ))}
          </Carousel>
        </>
      ) : (
        ""
      )}
      {postFiles?.length === 1 &&
        (postType.toLowerCase() === image ||
          postType.toLowerCase() === video ||
          postType.toLowerCase() === reels) &&
        postFiles.map((item: any, index: number) => (
          <>
            {item?.media_type.toLowerCase() === image ? (
              <Box
                onClick={() => {
                  setMediaUrl(item.media_url);
                  setShowTags((prev) => !prev);
                }}
                sx={{ position: "relative" }}
              >
                <CardMedia
                  component="img"
                  loading="lazy"
                  sx={
                    popup
                      ? styles.postImage1
                      : chat
                      ? styles.postImage2
                      : styles.postImage
                  }
                  image={item?.media_url}
                />
                {item?.tagged_users && item?.tagged_users?.length > 0 && (
                  <ImageItemBar item={item?.tagged_users} />
                )}
                {!chat && (
                  <TagUser
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    tagUserList={item?.tagged_users}
                    tagType="image"
                    setIsModalOpen={props?.setIsModalOpen}
                  />
                )}
                {!isLoading &&
                  showTags &&
                  !chat &&
                  !showPopup &&
                  item?.tagged_users?.map((user: any, index: number) => {
                    return <TaggedUser user={user} index={index} />;
                  })}
              </Box>
            ) : item?.media_type.toLowerCase() === video ||
              item?.media_type.toLowerCase() === reels ? (
              <>
                <VideoPlayers
                  thumbnailURL={item?.media_thumbnail_url}
                  videoUrl={item?.media_url}
                  showIconButton={true}
                  showPopup={showCommentPopup}
                />
                {/* <VideoPlayer
                  src={item?.media_url}
                  width={420}
                  height={420}
                  showIconButton={true}
                  play={true}
                  popup={popup}
                /> */}
                {item?.tagged_users && item?.tagged_users?.length > 0 && (
                  <ImageItemBar item={item?.tagged_users} />
                )}
                {!chat && (
                  <TagUser
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    tagUserList={item?.tagged_users}
                    tagType="video"
                  />
                )}
              </>
            ) : (
              <Box sx={{ mt: -2 }}></Box>
            )}
          </>
        ))}
    </ImageListItem>
  );
}
