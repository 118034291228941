import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, SwipeableDrawer } from "@mui/material";
import { styles } from "../../../theming/Style/Styles";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useDispatch } from "react-redux";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { ServiceDashBoard, ServiceUserProfile } from "../../../api";
import { useSelector } from "react-redux";
import ReportPostDialog from "../../../components/dashboard/post-card/report-post-dialog";
import { RemoveCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BlockIcon from "@mui/icons-material/Block";
import {
  FOLLOW_STATUS,
  getFollowButtonLabel,
} from "../../../helper/methods/methods";
import LinkIcon from "@mui/icons-material/Link";
import RepeatIcon from "@mui/icons-material/Repeat";
import { actionSetFlag } from "../../../store/reducer/story";
import EditIcon from "@mui/icons-material/Edit";
import EditPost from "../../create-post/EditPost";

export default function ReelsMenu(props: any) {
  const dispatch = useDispatch();
  const { token, user_uuid } = useSelector(
    (state: any) => state?.rLogin?.infoLogin || {},
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const router = useNavigate();
  const [showPopupEditPost, setShowPopupEditPost] = React.useState<any>(false);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isShow, setIsShow] = React.useState<boolean>(false);
  const [textReport, setTextReport] = React.useState(false);
  const ref: any = React.useRef();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const editPostData = {
    showPopupEditPost: showPopupEditPost,
    setShowPopupEditPost: setShowPopupEditPost,
    isEdit: true,
    uuid: props.uuid,
    setIsModalOpen: props?.setIsModalOpen,
  };

  const apiCallPostNotInterested = () => {
    let dictObj = { post_uuid: props?.uuid };
    ServiceDashBoard.apiCallPostNotInterestedInPost(dictObj, token)
      .then((res: any) => {
        if (!res.error) {
          dispatch(showSocialSnackbar({ message: res?.message }));
          router(0);
        }
      })
      .catch((err: any) => {
        dispatch(showSocialSnackbar({ message: err?.message }));
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    document.body.clientWidth > 900
      ? setAnchorEl(event.currentTarget)
      : setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };
  const handleReportClose = () => {
    setIsShow(false);
  };
  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = () => {
    ServiceDashBoard.apiCallForDeletePost(props.uuid, token).then(
      (res: any) => {
        props?.setData(
          props?.data?.filter((item: any, i: number) => props?.index !== i),
        );
        dispatch(showSocialSnackbar({ message: res?.message }));
      },
    );
  };
  const handleReport = () => {
    setIsShow(true);
  };

  const handleUnfollowUser = (user_uuid: any) => {
    let requested_by_id = props?.item?.posted_by_data?.user_uuid;
    ServiceUserProfile.apiCallPostFollowUnfollowUser({ requested_by_id }, token)
      .then((res: any) => {
        if (res?.data?.is_follow) {
          dispatch(
            showSocialSnackbar({
              message: "Request Sent Successfully",
              type: "success",
            }),
          );
        } else {
          dispatch(
            showSocialSnackbar({
              message: "Request is Revoked Successfully",
              type: "success",
            }),
          );
        }
        props?.setData(
          props?.data.map((item: any) => {
            if (
              item.posted_by_data.user_uuid ===
              props?.item.posted_by_data.user_uuid
            ) {
              return {
                ...item,
                request_status: null,
                follow_status: "unfollow",
              };
            } else {
              return item;
            }
          }),
        );
      })
      .catch((err) => {});
  };

  const handleClickStory = () => {
    let formdata = new FormData();
    formdata.append("post_uuid", props?.uuid);
    formdata.append("post_index ", "0");
    ServiceDashBoard.apiCallAddStory(formdata, token)
      .then((res: any) => {
        dispatch(actionSetFlag(true));
        dispatch(showSocialSnackbar({ message: res.message }));
      })
      .catch((err: any) => {
        dispatch(actionSetFlag(false));
        dispatch(showSocialSnackbar({ message: err.message }));
      });
  };

  const handleSharePostAsLink = () => {
    const link = `https://backend.hypeathletenetwork.com/view-post/${props?.uuid}`;
    navigator.clipboard.writeText(link);
    dispatch(showSocialSnackbar({ message: "Link Copied!" }));
  };

  const onClicked = () => {
    if (document.body.clientWidth > 900) {
      setShowPopupEditPost(true);
    } else {
      router("/edit-post/" + props.uuid);
    }
  };

  const getOptions = () => {
    if (props?.userId === user_uuid) {
      return (
        <>
          <Box sx={styles.ReelsMenuItem} onClick={onClicked}>
            <EditIcon />
            <Typography sx={styles.ReelsMenuText}>Edit</Typography>
          </Box>
          <Box sx={styles.ReelsMenuItem} onClick={handleDelete}>
            <DeleteIcon />
            <Typography sx={styles.ReelsMenuText}>Delete</Typography>
          </Box>
          <Box sx={styles.ReelsMenuItem} onClick={handleClickStory}>
            <RepeatIcon />
            <Typography sx={styles.ReelsMenuText}>Repost as Story</Typography>
          </Box>
          <Box sx={styles.ReelsMenuItem} onClick={handleSharePostAsLink}>
            <LinkIcon />
            <Typography sx={styles.ReelsMenuText}>
              Share post as link
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          {getFollowButtonLabel(
            props?.item.request_status,
            props?.item.follow_status,
          ) === FOLLOW_STATUS.REQUESTED && (
            <Box sx={styles.ReelsMenuItem} onClick={handleUnfollowUser}>
              <BlockIcon />
              <Typography sx={styles.ReelsMenuText}>Unfollow</Typography>
            </Box>
          )}
          <Box sx={styles.ReelsMenuItem} onClick={handleReport}>
            <ReportGmailerrorredIcon />
            <Typography sx={styles.ReelsMenuText}>Report</Typography>
          </Box>
          <Box sx={styles.ReelsMenuItem} onClick={apiCallPostNotInterested}>
            <RemoveCircleOutline />
            <Typography sx={styles.ReelsMenuText}>Not Interested</Typography>
          </Box>
          <Box sx={styles.ReelsMenuItem} onClick={handleClickStory}>
            <RepeatIcon />
            <Typography sx={styles.ReelsMenuText}>Repost as Story</Typography>
          </Box>
          <Box sx={styles.ReelsMenuItem} onClick={handleSharePostAsLink}>
            <LinkIcon />
            <Typography sx={styles.ReelsMenuText}>
              Share post as link
            </Typography>
          </Box>
        </>
      );
    }
  };
  return (
    <div>
      <button
        aria-describedby={id}
        onClick={handleClick}
        style={{
          padding: 0,
          margin: 0,
          width: "fit-content",
          background: "transparent",
          border: "none",
        }}
      >
        <MoreVertIcon sx={{ color: "white" }} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            ...styles.dialogBox,
            // p: 2,
            display: "flex",
            width: 200,
            flexDirection: "column",
            gap: 1,
          }}
        >
          {getOptions()}
        </Box>
      </Popover>
      <SwipeableDrawer
        anchor={"bottom"}
        open={isOpen}
        onClose={toggleDrawer("bottom", false)}
        onOpen={toggleDrawer("bottom", true)}
        onClick={handleClose}
      >
        <Box
          sx={{
            ...styles.dialogBox,
            px: 2,
            py: 2,
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
          ref={ref}
        >
          {getOptions()}
        </Box>
      </SwipeableDrawer>
      <ReportPostDialog
        isShow={isShow}
        setIsShow={setIsShow}
        handleClose2={handleReportClose}
        textReport={textReport}
        uuid={props?.uuid}
        setTextReport={setTextReport}
      />
      <EditPost {...editPostData} />
    </div>
  );
}
