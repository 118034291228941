import { IconButton, InputAdornment, TextField } from "@mui/material";
import { styles } from "../../theming/Style/Styles";
// import Spinner from "./spinner";
import CircularProgress from "@mui/material/CircularProgress";

export default function Plaintextinput(props: any) {
  const { textFieldValue, borderCurved } = props;
  return (
    <TextField
      key={textFieldValue.name}
      className="input-plain-background"
      id={"comment-input"}
      variant="outlined"
      fullWidth
      size="small"
      required={textFieldValue.required}
      label={textFieldValue.placeholder}
      name={textFieldValue.name}
      multiline={textFieldValue.multiline ? true : false}
      rows={textFieldValue.rows ? textFieldValue.rows : 1}
      InputLabelProps={{
        style: { ...styles.inputLabel, color: "white" },
      }}
      style={{ color: "white" }}
      sx={
        borderCurved
          ? {
              ...styles.textInput3,
              ...styles.borderOutline,
            }
          : { ...styles.textInput1, ...styles.borderOutline }
      }
      value={
        isNaN(textFieldValue.value) && textFieldValue?.inputType === "number"
          ? ""
          : textFieldValue.value
      }
      onBlur={textFieldValue.handleBlur}
      onChange={textFieldValue.handleChange}
      contentEditable={textFieldValue.contentEditable}
      onFocus={textFieldValue.handleFocus}
      error={Boolean(textFieldValue.touchedValue && textFieldValue.errorsValue)}
      helperText={textFieldValue.touchedValue && textFieldValue.errorsValue}
      FormHelperTextProps={{
        style: { ...styles.alignStart, ...styles.helperText },
      }}
      type={textFieldValue.inputType}
      InputProps={
        textFieldValue.icon ? (
          {
            endAdornment: (
              <InputAdornment position="end">
                {props?.isDisabled ? (
                  <>
                    {props?.isDisabled && (
                      <CircularProgress sx={{ color: "white" }} size={28} />
                    )}
                  </>
                ) : (
                  <IconButton
                    onClick={textFieldValue.handleClickEdit}
                    onMouseDown={textFieldValue.handleMouseDownPassword}
                    edge="end"
                    sx={styles.colorWhite}
                  >
                    {textFieldValue.icon}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }
        ) : (
          <></>
        )
      }
      inputProps={
        textFieldValue?.maxlength && {
          maxlength: textFieldValue?.maxlength,
        }
      }
      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
        textFieldValue?.inputType === "number" ? (
          (e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, textFieldValue?.maxlength))
        ) : (
          <></>
        );
      }}
      {...props}
    />
  );
}
