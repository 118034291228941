import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceMessage } from "../../../api";

export const getChatUserList: any = createAsyncThunk(
  "message/dashboard",
  async (params: any, thunkAPI) => {
    try {
      const response = ServiceMessage.apiCallGetAllChatsUser(
        params.info,
        params.token,
        params.userType,
      );
      return response;
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getRequestedChatUserList: any = createAsyncThunk(
  "message/dashboards",
  async (params: any, thunkAPI) => {
    try {
      const response = ServiceMessage.apiCallGetAllChatsUser(
        params.info,
        params.token,
        "",
      );
      return response;
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const ChatListSlice = createSlice({
  name: "chatlistslice",
  initialState: {
    chatUserList: { isLoading: false, result: [], isRejected: false },
    requestChatUserList: { isLoading: false, result: [], isRejected: false },
  },
  reducers: {},
  extraReducers: {
    [getChatUserList.pending]: (state: any) => {
      state.chatUserList.isLoading = true;
      state.chatUserList.isRejected = false;
    },
    [getChatUserList.fulfilled]: (state: any, { payload }: any) => {
      state.chatUserList.isLoading = false;
      state.chatUserList = { ...payload, result: payload?.result };
      state.chatUserList.isRejected = false;
    },
    [getChatUserList.rejected]: (state: any) => {
      state.chatUserList.isLoading = false;
      state.chatUserList.isRejected = false;
    },
    [getRequestedChatUserList.pending]: (state: any) => {
      state.requestChatUserList.isLoading = true;
      state.requestChatUserList.isRejected = false;
    },
    [getRequestedChatUserList.fulfilled]: (state: any, { payload }: any) => {
      state.requestChatUserList.isLoading = false;
      state.requestChatUserList = { ...payload, result: payload?.result };
      state.requestChatUserList.isRejected = false;
    },
    [getRequestedChatUserList.rejected]: (state: any) => {
      state.requestChatUserList.isLoading = false;
      state.requestChatUserList.isRejected = false;
    },
  },
});

export const {} = ChatListSlice.actions;

export default ChatListSlice.reducer;
