import { createSlice } from "@reduxjs/toolkit";

const MessageSlice = createSlice({
  name: "messageSlice",
  initialState: {
    messageData: {},
    isSelectedTab: false,
  },
  reducers: {
    actionMessageData(state, action) {
      state.messageData = action.payload;
    },
    updateSelectedUserTab(state, action) {
      state.isSelectedTab = action.payload;
    },
  },
});

export const { actionMessageData, updateSelectedUserTab } =
  MessageSlice.actions;

export default MessageSlice.reducer;
