/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertBox } from "../../components/common";
import { PatterColor } from "../../theming/Style/color.styles";
import { AppStringUtils } from "../../utils";
import {
  About,
  FeedControl,
  Notification,
  Permission,
  Privacy,
  ReportBug,
  Security,
  SideMenu,
  UserDetails,
} from "./components";
import BlockedUsers from "./components/BlockedUsers";

import { styles } from "../../theming/Style/Styles";

function Profile() {
  const t = AppStringUtils();
  const router = useNavigate();
  const [selectedMenuTitle, setSelectedMenuTitle] = useState<string>("Profile");
  const [selectedMenuItem, setSelectedMenuItem] = useState<number>(-1);
  const [errMessage, setErrMessage] = useState<string>("");
  const theme = useTheme();
  const matches: any = useMediaQuery(theme.breakpoints.down("md"));

  const setTitleBaseOnSelectionTab = (index: number) => {
    let strTitle = "Profile";
    if (index === 0) {
      strTitle = t.profile;
    } else if (index === 1) {
      strTitle = t.security;
    } else if (index === 2) {
      strTitle = t.permission;
    } else if (index === 3) {
      strTitle = t.privacy;
    } else if (index === 4) {
      strTitle = t.notification;
    } else if (index === 5) {
      strTitle = t.about;
    } else if (index === 6) {
      strTitle = t.reportBug;
    } else if (index === 7) {
      strTitle = t.feedControl;
    } else if (index === 8) {
      strTitle = t.blockedUsers;
    }
    setSelectedMenuTitle(strTitle);
  };

  const renderViewBaseOnSliderMenu = () => {
    switch (selectedMenuItem) {
      case 0:
        return <UserDetails />;
      case 1:
        return (
          <Security
            onChangeSubItem={(e: any) => {
              setSelectedMenuTitle(e);
            }}
            selectedItem={selectedMenuItem}
            selectedMenuTitle={selectedMenuTitle}
          />
        );
      case 2:
        return <Permission />;

      case 3:
        return <Privacy />;
      case 4:
        return <Notification />;
      case 5:
        return <About />;
      case 6:
        return (
          <Container>
            <ReportBug />
          </Container>
        );
      case 7:
        return <FeedControl />;
      case 8:
        return <BlockedUsers />;
      default:
        return <UserDetails />;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        paddingTop: { md: "5%", xs: 0 },
        px: { md: "20%", xs: 0 },
      }}
    >
      <Grid container spacing={2}>
        <Box
          component={Grid}
          item
          md={5}
          xs={12}
          display={{
            xs: selectedMenuItem !== -1 ? "none" : "block",
            md: "block",
          }}
        >
          <Box>
            <Box sx={matches && styles.userProfileAppBar}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                {document?.body?.clientWidth < 900 ? (
                  <Box
                    sx={{
                      width: 28,
                      height: 28,
                      my: 0.5,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      display: "flex",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIcon onClick={() => router(-1)} />
                  </Box>
                ) : (
                  <Box sx={{ ml: 0.5 }}></Box>
                )}
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    color: PatterColor.white,
                    fontSize: 18,
                    fontWeight: "700",
                  }}
                >
                  {t.setting}
                </Typography>
              </Box>
            </Box>
            {/* side menu */}
            <Box mt={{ xs: 8, md: 0 }}>
              {errMessage.length > 0 && (
                <Box>
                  <AlertBox message={errMessage} alertType="error" />
                </Box>
              )}

              <SideMenu
                selectedItem={selectedMenuItem}
                onChangeMenu={(e: number) => {
                  setTitleBaseOnSelectionTab(e);
                  setSelectedMenuItem(e);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          component={Grid}
          item
          md={7}
          xs={12}
          display={{
            xs: selectedMenuItem === -1 ? "none" : "block",
            md: "block",
          }}
        >
          <Box>
            <Box sx={matches && styles.userProfileAppBar}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box
                  sx={{
                    width: 28,
                    height: 28,
                    my: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    color: "white",
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <ArrowBackIcon
                    onClick={() => {
                      if (
                        selectedMenuTitle === t.textHeading2FA ||
                        selectedMenuTitle === t.passwordUpdate
                      ) {
                        setTitleBaseOnSelectionTab(1);
                      } else {
                        setSelectedMenuItem(-1);
                      }
                    }}
                  />
                </Box>

                <Typography
                  sx={{
                    color: PatterColor.white,
                    fontSize: 16,
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  {selectedMenuTitle}
                </Typography>
              </Box>
            </Box>
            {/* load component base on select item from side menu */}
            <Box mt={{ xs: 8, md: 0 }}>{renderViewBaseOnSliderMenu()}</Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

export default Profile;
