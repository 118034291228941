import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { actionMute } from "../../../store/reducer/reels";
import { styles } from "../../../theming/Style/Styles";

interface VolumeButtonProps {
  muted: boolean;
}
const buttonStyle = {
  width: { xs: 20, sm: 22, md: 24 },
  height: { xs: 20, sm: 22, md: 24 },
  color: "white",
};
const VolumeButton = ({ muted }: VolumeButtonProps) => {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(actionMute());
  };
  return (
    <IconButton sx={styles.ReelsVolumeButton}>
      {muted ? (
        <VolumeOffIcon onClick={handleOnClick} sx={buttonStyle} />
      ) : (
        <VolumeUpIcon onClick={handleOnClick} sx={buttonStyle} />
      )}
    </IconButton>
  );
};

export default VolumeButton;
