export const WM_MATCH_PASSWORD =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#[\]$&*~.])(?=.*\d.*\d)[A-Za-z\d!@#[\]$&*~.]{8,}$/;
export const MATCH_USERNAME = /^[a-z0-9][a-z0-9_.]*[a-z0-9]$/;
// /^[a-z][a-z0-9_.]*[a-z0-9]$/;
// ^[a-z0-9][a-z0-9_.]*[a-z0-9]$
// /^[a-z][a-z0-9_.]{4,14}[a-z0-9]$/;
export const MATCH_ONLY_CHAR = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
export const MATCH_FIRST_LAST = /^[a-zA-Z\s]*$/;
// /^[A-Z][a-z]*$/;
// ^[\s a-zA-Z'-]+$
// /^[a-zA-Z\s]*$/;
export const MATCH_ONLY_EMAIL = /^[a-zA-Z0-9._%+-@~!$^&*='?}{]+$/;
// /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const MATCH_ONLY_NUM2 = /^(?!0\d)(\d{1,2}(\.\d{1,2})?)$/;
export const MATCH_ONLY_NUM3 = /^(?!0$)([1-9]\d{0,3}(\.\d{1,2})?)?$/;
export const MATCH_ONLY_STR = /^[a-zA-Z0-9\s~`!@#$%^&*()-_+={}[\]|;:'",.<>?]+$/;
export const MATCH_ONLY_NUM_DOT = /^\d*\.?\d*$/;
export const MATCH_WEIGHT_VALIDATION = /^\d*\.?\d*$/;
