import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceDashBoard,
  ServiceMessage,
  ServiceUserProfile,
} from "../../../api";
import { actionIsRemoved } from "../../../store/reducer/SavedPost";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { actionPlayerPlay } from "../../../store/reducer/player";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";
import {
  CommentIcon,
  LikeIcon,
  NotLikeIcon,
  PostSaveIcon,
  PostUnSaveIcon,
  SendIcon,
} from "../../common/icons";
import MobileCommentPopup from "./MobileCommentPopup";
import MobileSharePopup from "./MobileSharePopup";
import CommentPopup from "./comment-popup";
import PostSharePopup from "./post-share";
import PostUserInfo from "./post-user-info";

export default function PostIcon(props: any) {
  let [pageSize, pageNumber] = [20, 1];
  const t = AppStringUtils();
  const { isComment } = props;
  const {
    uuid,
    is_liked_allowed,
    is_comment_allowed,
    is_post_share_as_msg_allowed,
    is_saved_allowed,
  } = props?.info;
  const { postData, setPostData } = props;
  const [showPopup, setShowPopup] = useState<any>(false);
  const [mobileShowPopup, setMobileShowPopup] = useState<any>(false);
  const [mobileSharePopup, setMobileSharePopup] = useState<any>(false);
  const { user_uuid, token } = useSelector(
    (state: any) => state.rLogin.infoLogin,
  );
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
  const [data, setData] = React.useState<any>([]);
  const [errMessage, setErrMessage] = useState<string>("");
  const [commentList, setCommentList] = useState<any>([]);
  const [usersList, setUsersList] = useState<any>([]);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openSharePostPopup = () => {
    document.body.clientWidth > 900
      ? setIsOpen(true)
      : setMobileSharePopup(true);
    apiCallGetAllChats("");
  };
  const handleClose = () => {
    setUsersList([]);
    setIsOpen(false);
  };

  const apiCallGetAllChats = (userName: any) => {
    setIsLoader(true);
    let dataParam = {
      user_uuid: user_uuid,
      search: userName === "" ? "" : userName,
      list_type: "follower",
      page_size: 20,
      page: 1,
    };
    ServiceUserProfile.apiCallGetFollowerFollowing(dataParam, token)
      .then((res: any) => {
        setIsLoader(false);
        let dataList = res.data.map((item: any) => {
          return { ...item?.requested_by_data };
        });
        setData(dataList);
      })
      .catch((err: any) => {
        setIsLoader(false);
        setData([]);
      });
  };

  const handleClickLikeCount = () => {
    setPostData &&
      setPostData({
        ...postData,
        isLike: !postData.isLike,
        likesCount: postData.isLike
          ? +postData.likesCount - 1
          : +postData.likesCount + 1,
      });
  };
  const handleClickIsSaved = () => {
    setPostData &&
      setPostData({
        ...postData,
        isSaved: !postData.isSaved,
      });
    let msg = !postData.isSaved
      ? t.textPostAddedMessage
      : t.textPostRemovedMessage;
    dispatch(
      showSocialSnackbar({
        message: msg,
        type: "success",
      }),
    );
  };

  const openPopup = (uuid: string) => {
    dispatch(actionPlayerPlay(true));
    setShowPopup(true);
  };

  const apiCallPostLikePost = (postId: string) => {
    setIsLoader(true);
    ServiceDashBoard.apiCallPostLikePost(postId, token)
      .then((res: any) => {
        setIsLoader(false);
        handleClickLikeCount();
      })
      .catch((err) => {
        setIsLoader(false);
        setErrMessage(err);
        setTimeout(() => {
          setErrMessage("");
        }, 3000);
      });
  };
  const apiCallGetCommentByPostId = (pageNumber: number, postId: string) => {
    setIsLoadingComments(true);
    ServiceDashBoard.apiCallGetCommentOnPost(
      pageNumber,
      pageSize,
      postId,
      token,
    )
      .then((res: any) => {
        setIsLoadingComments(false);
        setCommentList([...commentList, ...res.data.data]);
      })
      .catch((err) => {
        setIsLoadingComments(false);
        setErrMessage(err);
        setTimeout(() => {
          setErrMessage("");
        }, 3000);
      });
  };

  const CommentBox = (commentData: any) => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <PostUserInfo
              postedByData={commentData.commented_by_data}
              profileImage={true}
              show={false}
            />
          </Box>
          <Box onClick={() => commentLikeUnlike(commentData.uuid)}>
            {commentData?.is_like ? <LikeIcon /> : <NotLikeIcon />}
          </Box>
        </Box>
        <Box>
          <Typography sx={styles.textInformation2}>
            {commentData?.comment}
          </Typography>
        </Box>
        {!commentData.subComment ? (
          <Box>
            <Typography sx={styles.textInformation1}>
              <Box sx={{ mr: 1 }}>23 W</Box>
              <Box sx={{ mr: 1 }}>1 Like</Box>
              <Box
                sx={{ mr: 1, ...styles.pointer }}
                onClick={() => replyOnComment(commentData)}
              >
                {t.reply}
              </Box>
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {commentData?.replied_on_comment_data &&
          commentData?.replied_on_comment_data.length !== 0 &&
          commentData.replied_on_comment_data.map((item: any, index: any) => (
            <Box sx={{ ml: 3 }}>
              <CommentBox {...item} subComment={true} />
            </Box>
          ))}
      </Box>
    );
  };
  const formik = useFormik({
    initialValues: {
      comment: "",
      comment_uuid: "",
      postId: uuid,
      username: "",
    },
    onSubmit: (values: any) => {
      if (
        isLoader ||
        values.comment.length === 0 ||
        values.comment.includes("\u3000") ||
        values.comment.trim().length === 0
      ) {
        return;
      }
      let infoValue = { ...values };
      let postId = infoValue.postId;
      delete infoValue.postId;
      if (!infoValue.comment_uuid) {
        delete infoValue.comment_uuid;
      }
      if (!infoValue.comment.includes(infoValue.username)) {
        delete infoValue.comment_uuid;
      }
      if (infoValue.comment.includes(infoValue.username)) {
        infoValue.comment = infoValue.comment.replace(infoValue.username, "");
      }
      delete infoValue.username;
      addComment(postId, infoValue);
    },
  });
  const addComment = (postId: any, reqBody: any) => {
    ServiceDashBoard.apiCallPostAddComment(postId, reqBody, token)
      .then((res: any) => {
        formik.resetForm();
        // apiCallGetCommentByPostId(uuid);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };
  const replyOnComment = (commentData: any) => {
    formik.setFieldValue("comment_uuid", commentData.uuid);
    formik.setFieldValue("comment_content", commentData.content);
    formik.setFieldValue(
      "username",
      "@" + commentData.commented_by_data.username + " ",
    );
    formik.setFieldValue(
      "comment",
      "@" + commentData.commented_by_data.username,
    );
  };
  const commentLikeUnlike = (commentId: any) => {
    ServiceDashBoard.apiCallPostCommentLikeUnlike(commentId, token)
      .then((res: any) => {
        // apiCallGetCommentByPostId(uuid);
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };
  const savePost = (postId: any) => {
    ServiceDashBoard.apiCallPostSavePost(postId, token)
      .then((res: any) => {
        dispatch(actionIsRemoved(true));
        handleClickIsSaved();
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };

  const removeSavedPost = (postId: any) => {
    ServiceDashBoard.apiCallDeleteSavedPost(postId, token)
      .then((res: any) => {
        dispatch(actionIsRemoved(true));
        handleClickIsSaved();
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };

  return (
    <>
      <Box sx={styles.postIconBox}>
        <Box sx={styles?.LikeCommentContainer}>
          {is_liked_allowed && (
            <Box onClick={() => apiCallPostLikePost(uuid)} sx={styles?.iconBox}>
              {postData?.isLike ? <LikeIcon /> : <NotLikeIcon />}
              <Typography
                variant="body2"
                sx={{ ...styles.postIconsText1, width: 2 }}
              >
                {postData?.likesCount}
              </Typography>
            </Box>
          )}
          {!isComment ? (
            ""
          ) : (
            <>
              {is_comment_allowed && (
                <Box
                  onClick={() => {
                    document.body.clientWidth > 900
                      ? openPopup(uuid)
                      : setMobileShowPopup(true);
                  }}
                  sx={styles?.iconBox}
                >
                  <CommentIcon />
                  <Typography variant="body2" sx={styles.postIconsText1}>
                    {postData?.commentCount}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box>
          <CommentPopup
            {...props.info}
            info={props.info}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
          />
          <MobileCommentPopup
            {...props.info}
            postId={uuid}
            info={props.info}
            showPopup={mobileShowPopup}
            setShowPopup={setMobileShowPopup}
            // apiCallGetCommentByPostId={apiCallGetCommentByPostId}
            postData={postData}
            setPostData={setPostData}
          />
          <MobileSharePopup
            info={props.info}
            showPopup={mobileSharePopup}
            uuid={uuid}
            setShowPopup={setMobileSharePopup}
            // apiCallForSharedPost={apiCallForSharedPosts}
            usersList={usersList}
            setUsersList={setUsersList}
            data={data}
            apiCallGetAllChats={apiCallGetAllChats}
            isLoader={isLoader}
          />
          <PostSharePopup
            uuid={uuid}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            apiCallGetAllChats={apiCallGetAllChats}
            data={data}
            isLoader={isLoader}
            postInfo={props?.info?.post_files}
          />
        </Box>
        <Box sx={styles?.shareSaveContainer}>
          {is_post_share_as_msg_allowed && (
            <Box onClick={openSharePostPopup}>
              <SendIcon />
            </Box>
          )}
          {is_saved_allowed && (
            <>
              {postData?.isSaved ? (
                <Box onClick={() => removeSavedPost(uuid)}>
                  <PostSaveIcon />
                </Box>
              ) : (
                <Box onClick={() => savePost(uuid)}>
                  <PostUnSaveIcon />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
