import { AppBar } from "@mui/material";
import * as React from "react";
import { styles } from "../../theming/Style/Styles";
import { Logo } from "./icons";

export default function Logobar(props: any) {
  return (
    <AppBar
      sx={{
        ...props,
        position: { xs: "absolute", md: "fixed" },
      }}
      id={"logo-bar"}
    >
      <Logo style={styles.width90} />
    </AppBar>
  );
}
