import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CreatePost from "../../../../container/create-post";
import Notification from "../../../../container/notification";
import { RoutePath } from "../../../../routes/route-path";
import { styles } from "../../../../theming/Style/Styles";
import { PatterColor } from "../../../../theming/Style/color.styles";
import { AppStringUtils, NavigationUtils } from "../../../../utils";
import { CommonButton } from "../../../common";
import { UserTypes } from "../../../common/enums/user-types";
import { Logo } from "../../../common/icons";
import { Navigates } from "../../AppBarData";
import { StyledBadgeNotification } from "../../LabelBottomNavigation";
import SearchInput from "../searc/SearchInput";

const DesktopAppBar = (props: any) => {
  const { setReadNotification } = props;
  const t = AppStringUtils();
  const { message_count, notification_count } = props.count;
  const { infoProfile } = useSelector((state: any) => state.rLogin || {});
  const { user_details, user_type } = useSelector(
    (state: any) => state?.rLogin?.infoLogin || {},
  );

  const router = useNavigate();
  const navigation = NavigationUtils();
  const location = useLocation();
  const [navIndex, setNavIndex] = useState<number>(1);
  const [screenStatus, setScreenStatus] = useState(
    parseInt(localStorage.getItem("profile-info-screen") || "0"),
  );

  const routePath = RoutePath.userProfile;
  let profileImage = "";
  let userData: any = {};
  if (infoProfile?.profile_image) {
    userData = infoProfile;
    profileImage = userData?.profile_image;
  } else {
    userData = user_details;
    profileImage = userData?.profile_image;
  }
  const [showPopupCreatePost, setShowPopupCreatePost] = useState<any>(false);
  const createPostData = {
    showPopupCreatePost: showPopupCreatePost,
    setShowPopupCreatePost: setShowPopupCreatePost,
  };

  const [showPopup, setShowPopup] = useState<any>(false);

  const isMobileOrTablet = useMediaQuery("(max-width: 900px)");

  const handleClose2 = () => {
    setShowPopup(false);
    setShowPopupCreatePost(false);
    if (screenStatus === 5) return;
  };

  useEffect(() => {
    const screenStatus = localStorage.getItem("profile-info-screen");
    setScreenStatus(parseInt(screenStatus ?? "0"));
  }, [showPopup]);

  useEffect(() => {
    handleClose();
  }, [isMobileOrTablet]);

  const btnNavClicked = (event: any, pathName: any, index: number) => {
    pathName && navigation.navigate({ pathName });
    switch (index) {
      case 4:
        handleClick(event);
        setReadNotification(true);
        setNavIndex(4);
        break;
      case 5:
        setShowPopupCreatePost(true);
        setShowPopup(true);
        break;
    }
  };
  const getPath = () => {
    switch (location.pathname) {
      case RoutePath.home:
        setNavIndex(1);
        break;
      case RoutePath.reels:
        setNavIndex(2);
        break;
      case RoutePath.explore:
        setNavIndex(3);
        break;
      case RoutePath.notification:
        setNavIndex(4);
        break;
      case RoutePath.createPost:
        setNavIndex(5);
        break;
      case RoutePath.messageGeneral:
        setNavIndex(6);
        break;
      case RoutePath.profile:
        setNavIndex(7);
        break;
      case RoutePath.userProfileById:
        setNavIndex(8);
        break;
    }
  };

  useEffect(() => {
    getPath();
  }, [location]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setReadNotification(false);
  };

  const ids = "basic-button";

  const notificationProps = {
    anchorEl: anchorEl,
    handleClose: handleClose,
    open: open,
    id: ids,
    setReadNotification: setReadNotification,
  };

  let notificationPopupOpen = notificationProps.open;
  useEffect(() => {
    if (notificationProps.open) {
      setNavIndex(4);
    } else {
      getPath();
    }
  }, [notificationPopupOpen]);

  const handleRoutes = () => {
    if (screenStatus === 5) return;
    setShowPopup(false);
    setShowPopupCreatePost(false);
    router(RoutePath.profileInfo);
  };

  return (
    <Box
      sx={{
        ...styles.alignCenter,
        justifyContent: {
          xs:
            location.pathname === RoutePath.explore
              ? "center"
              : "space-between",
          md: "center",
        },
        ml: { xs: -0.5, sm: 0.5 },
      }}
    >
      <Box onClick={() => router(RoutePath.home)}>
        <Logo style={styles.width50} />
      </Box>
      <Box
        sx={{
          width: 400,
          mx: 1,
        }}
      >
        <SearchInput />
      </Box>
      {Navigates.map((item: any, index: number) => {
        return (
          <>
            {item.navIndex === 4 || item.navIndex === 6 ? (
              <IconButton
                onClick={(event) => {
                  btnNavClicked(event, item.route, item.navIndex);
                }}
                sx={styles.marginX1}
                title={item.title}
                key={index}
              >
                <StyledBadgeNotification
                  badgeContent={
                    (item.navIndex === 4 && notification_count > 0
                      ? notification_count
                      : 0) ||
                    (item.navIndex === 6 && message_count > 0
                      ? message_count
                      : 0)
                  }
                  color="warning"
                  variant={item.navIndex === 4 ? "dot" : undefined}
                  overlap={"circular"}
                  sx={{
                    "& .MuiBadge-dot": {
                      width: "12px", // Adjust the width of the dot
                      height: "12px", // Adjust the height of the dot
                      borderRadius: "50%", // Ensure the dot is circular
                    },
                  }}
                >
                  <item.Icon
                    color={
                      navIndex === item.navIndex
                        ? PatterColor.white
                        : PatterColor.thirdGray
                    }
                    sx={{
                      color:
                        navIndex === item.navIndex
                          ? PatterColor.white
                          : PatterColor.thirdGray,
                      "&:hover": {
                        color: PatterColor.white,
                      },
                    }}
                  />
                </StyledBadgeNotification>
              </IconButton>
            ) : (
              <IconButton
                onClick={(event) => {
                  btnNavClicked(event, item.route, item.navIndex);
                }}
                sx={styles.marginX1}
                title={item.title}
              >
                <item.Icon
                  color={
                    navIndex === item.navIndex
                      ? PatterColor.white
                      : PatterColor.thirdGray
                  }
                  sx={{
                    color:
                      navIndex === item.navIndex
                        ? PatterColor.white
                        : PatterColor.thirdGray,
                    "&:hover": {
                      color: PatterColor.white,
                    },
                  }}
                />
              </IconButton>
            )}
          </>
        );
      })}
      {user_type === UserTypes.athlete || screenStatus === 5 ? (
        <CreatePost {...createPostData} />
      ) : (
        <Dialog maxWidth={"sm"} open={showPopup} onClose={handleClose2}>
          <Box sx={{ backgroundColor: "rgb(40,41,86)" }}>
            <DialogContent>
              <p style={{ color: "#FFF" }}>
                Please complete onboarding flow first
              </p>
              <Typography
                sx={{
                  fontFamily: "IBM Plex Mono",
                  fontWeight: 500,
                  fontSize: 12,
                  color: "#FFF",
                }}
              >
                {t.progress} {screenStatus}/5
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "70%" }}>
                    <LinearProgress
                      variant="determinate"
                      value={screenStatus * 20}
                      sx={{
                        background: "#222235",
                        height: 7,
                        borderRadius: "4px",
                        "& .MuiLinearProgress-bar1Determinate": {
                          backgroundColor: "#FFF066",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "IBM Plex Mono",
                        fontWeight: 500,
                        fontSize: 12,
                        color: "#FFF",
                      }}
                    >{`${screenStatus * 20}%`}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <CommonButton
                  buttonText={"Ok"}
                  onClicked={handleRoutes}
                  buttonStyle={{
                    width: "100px",
                  }}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}
      <Notification notificationProps={notificationProps} />
      <Box
        onClick={() => {
          router(`${routePath + user_details.user_uuid}`);
          setNavIndex(8);
        }}
        sx={{
          mt: 0,
        }}
      >
        <IconButton title={"Profile"}>
          <Avatar
            sx={{
              border: navIndex === 8 ? 2 : 0,
              borderColor: navIndex === 8 ? PatterColor.white : "none",
              width: 25,
              height: 25,
              marginTop: "5px",
              mx: 1,
              cursor: "pointer",
              "&:hover": {
                color: "white",
              },
            }}
            src={profileImage}
            imgProps={{ loading: "lazy" }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DesktopAppBar;
