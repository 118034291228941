import React, { useEffect, useRef, useState } from "react";
import { TextField, Popper, Paper, Stack } from "@mui/material";
import { primaryColor } from "../../../../theming/Style/Styles";
import SearchBarPage from "../../../dashboard/SearchBar";
import { useSearchParams } from "react-router-dom";

const SearchInput = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const popperRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      event.target instanceof Node &&
      !popperRef.current.contains(event.target)
    ) {
      setSearchParams({});
      setAnchorEl(null);
    }
  };

  const handleSearch = (e: any) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleToggle = () => {
    setSearchParams({ modal: "open" });
    setAnchorEl(anchorEl ? null : document.getElementById("search-input"));
  };

  let open = Boolean(anchorEl);

  useEffect(() => {
    searchParams.get("modal") === "open" &&
      setAnchorEl(document.getElementById("search-input"));
  }, [anchorEl, searchParams]);

  return (
    <div style={{ margin: "1px 0" }}>
      <Stack>
        <TextField
          className="input-plain-background"
          variant="outlined"
          fullWidth
          size="small"
          id="search-input"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search..."
          sx={{
            backgroundColor: "#282829",
            borderRadius: "17px",
            "& .MuiChip-label": {
              color: primaryColor,
            },
            "&:hover .MuiSvgIcon-root": {
              color: primaryColor,
            },
            "& .MuiSvgIcon-root": {
              color: primaryColor,
            },
            color: primaryColor,
            position: "relative",
          }}
          onClick={handleToggle}
        />
      </Stack>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          overflowY: "scroll",
          maxHeight: "500px",
          marginBottom: "50px",
          zIndex: "99999999999",
        }}
      >
        <Paper
          sx={{
            background: "black",
            overFlowY: "scroll",
          }}
          ref={popperRef}
        >
          <SearchBarPage
            searchTerm={searchTerm}
            setAnchorEl={setAnchorEl}
            setSearchTerm={setSearchTerm}
          />
        </Paper>
      </Popper>
    </div>
  );
};

export default SearchInput;
