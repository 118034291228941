import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ServiceNotification } from "../../api/notification";
import { RoutePath } from "../../routes/route-path";
import { styles } from "../../theming/Style/Styles";
import { Logobar } from "../common";
import { Layout } from "./HypeCastAppbar";
import LabelBottomNavigation from "./LabelBottomNavigation";
import { HeaderDashboard } from "./SocialAppbar";
import { webSocketNotificationUrl } from "../../helper/rest-api/url-schema";
import { useDispatch } from "react-redux";
import { UserTypes } from "../common/enums/user-types";
import { getChatUserList } from "../../store/reducer/chats";

export const getIsRouteHypeCast = () => {
  return window.location.href.includes("hype-cast");
};

function Header() {
  const location = useLocation();
  const [valuelocation, setValuelocation] = useState(false);
  const { isLogin, infoLogin, user_uuid } = useSelector(
    (state: any) => state?.rLogin,
  );
  const dispatch = useDispatch();
  const paths = [RoutePath.login];
  const up = `/user-profile/${infoLogin?.user_uuid}`;
  const BottomBarRoutes = ["/user-profile", "/notification"];
  const [count, setCount] = useState<any>({
    message_count: 0,
    notification_count: 0,
  });
  const [readNotification, setReadNotification] = useState<any>(false);

  useEffect(() => {
    if (
      !up.includes(location.pathname) ||
      paths.some((path) => location.pathname.includes(path))
    ) {
      setValuelocation(true);
    } else {
      setValuelocation(false);
    }
  }, [location]);
  const isReel =
    location.pathname === RoutePath.reels ||
    location.pathname.includes("/view-reel");

  const getChatAllUserList = () => {
    let param = {
      info: {
        conversation_status: "accepted",
        page: 1,
        page_size: 20,
      },
      token: infoLogin.token,
      userType: UserTypes.athlete,
    };
    dispatch(getChatUserList(param));
  };

  useEffect(() => {
    let socket: any;
    if (isLogin) {
      socket = new WebSocket(
        webSocketNotificationUrl + "/" + infoLogin?.user_uuid + "/",
      );

      socket.onopen = () => {};

      socket.onmessage = (event: any) => {
        const receivedNotification = JSON.parse(event.data);

        receivedNotification.data.messaging_count && getChatAllUserList();

        setCount({
          notification_count: receivedNotification.data.notifications_count,
          message_count: receivedNotification.data.messaging_count,
        });
      };
    }
    if (isLogin) {
      return () => {
        socket.close();
      };
    }
  }, [infoLogin?.user_uuid, isLogin]);

  useEffect(() => {
    if (isLogin) return apiCallGetNotificationCount();
  }, [isLogin]);

  const apiCallGetNotificationCount = () => {
    ServiceNotification.apiCallGetNotificationCount(infoLogin?.token)
      .then((res: any) => {
        setCount(res.data);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    if (readNotification) return apiCallGetReadNotification();
  }, [readNotification]);

  const apiCallGetReadNotification = () => {
    ServiceNotification.apiCallGetNotificationsRead(infoLogin.token)
      .then((res: any) => {
        setReadNotification(true);
      })
      .catch((err: any) => {});
  };

  const getIsBottomBar = () => {
    const isHomePage =
      location.pathname === "/" ||
      location.pathname === "/hype-cast" ||
      location.pathname === "/hype-cast/";
    if (
      BottomBarRoutes.some((item) => location.pathname.includes(item)) ||
      isHomePage
    ) {
      if (
        location.pathname.includes("/user-profile") &&
        location.pathname.split("/")[2] !== infoLogin?.user_uuid
      ) {
        return false;
      } else return true;
    }
    return false;
  };
  return isLogin ? (
    <>
      <Box>
        {getIsRouteHypeCast() ? (
          <Layout count={count} setReadNotification={setReadNotification} />
        ) : (
          <HeaderDashboard
            count={count}
            setReadNotification={setReadNotification}
          />
        )}
      </Box>
      {getIsBottomBar() ? (
        <LabelBottomNavigation
          count={count}
          setReadNotification={setReadNotification}
        />
      ) : (
        <></>
      )}
    </>
  ) : location.pathname.includes("profile-info") ? (
    <></>
  ) : (
    <>
      <Logobar
        {...(valuelocation ? styles.logobar1 : { ...styles.logobar2, pl: 0 })}
      />
    </>
  );
}

export default Header;
