import { Box, Modal } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { PatterColor } from "../../theming/Style/color.styles";

export default function Loader(props: any) {
  return (
    <Box sx={{ background: "transparent" }}>
      <Modal
        open={true}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
          padding: "-50px",
          boxShadow: "none",
        }}
        {...props}
      >
        <ClipLoader size={30} color={PatterColor.white} />
      </Modal>
    </Box>
  );
}
