import { lazy } from "react";
import Reels from "../container/Reels/Reels";
import Profile from "../container/profile";
import { CommonRouteProps } from "./route-interface.props";
import { RoutePath } from "./route-path";
// import PhoneVerified from "../container/auth/signup/PhoneVerified";
/**
 * import container/screen
 */
const PhoneVerified = lazy(
  () => import("../container/auth/signup/PhoneVerified"),
);
const EmailVerified = lazy(
  () => import("../container/auth/signup/EmailVerified"),
);
const ExploreSearch = lazy(
  () => import("../components/dashboard/ExploreSearch"),
);
const SearchBarPage = lazy(() => import("../components/dashboard/SearchBar"));
const VerifyEmail = lazy(() => import("../container/auth/signup/VerifyEmail"));
const VerifyPhone = lazy(() => import("../container/auth/signup/VerifyPhone"));
const EmailPhoneVerified = lazy(
  () => import("../container/auth/signup/EmailPhoneVerified"),
);
const UserPostReels = lazy(() => import("../container/Reels/user-reels"));
const Stories = lazy(() => import("../container/dashboard/components/stories"));
const Home = lazy(() => import("../container/dashboard"));
const HypeCast = lazy(() => import("../container/hype-cast"));
const Notification = lazy(
  () => import("../container/notification/notification-route"),
);
const MobileCreatePost = lazy(
  () => import("./../container/create-post/MobileCreatePost"),
);
const MobileEditPost = lazy(
  () => import("../container/create-post/MobileEditPost"),
);
const SuggestionListUsers = lazy(
  () =>
    import(
      "../container/dashboard/components/suggestion-list/suggestion-users"
    ),
);
const HypeCastPodcasts = lazy(
  () => import("../container/hype-cast/pages/podcasts"),
);
const HypeCastChannel = lazy(
  () => import("../container/hype-cast/pages/Channel"),
);
const HypeCastSeeAllChannels = lazy(
  () => import("../container/hype-cast/pages/SeeAllChannels"),
);
const HypeCastEpisodes = lazy(
  () => import("../container/hype-cast/pages/episodes"),
);
const HypeCastCategories = lazy(
  () => import("../container/hype-cast/pages/categories"),
);
const HypeCastPlayer = lazy(
  () => import("../container/hype-cast/pages/Player"),
);
const HypeCastSearch = lazy(
  () => import("../container/hype-cast/pages/Search"),
);
const HypeCastSavedEpisodes = lazy(
  () => import("../container/hype-cast/pages/savedEpisodes"),
);
const HypeCastFollowedPodcast = lazy(
  () => import("../container/hype-cast/pages/followedPodcast"),
);
const HypeCastEpisodesByCat = lazy(
  () => import("../container/hype-cast/pages/episodesByCat"),
);
const HypeCastPodcastByCat = lazy(
  () => import("./../container/hype-cast/pages/podcastByCat"),
);
const Categories = lazy(() => import("../container/categories"));
const Episode = lazy(() => import("../container/hype-cast/pages/episode"));
const Login = lazy(() => import("../container/auth/login"));
const LearnMore = lazy(() => import("../container/auth/learn-more"));
const ForgotPasswod = lazy(() => import("../container/auth/forgot-password"));
const ResetPasswod = lazy(
  () => import("../container/auth/forgot-password/resetpassword"),
);
const ChangePassword = lazy(
  () => import("../container/account-setting/changes-password"),
);
const Signup = lazy(() => import("../container/auth/signup/Signup"));

const Checkyourmail = lazy(
  () => import("../container/auth/forgot-password/checkemail"),
);
const UserProfile = lazy(() => import("../container/user-profile"));
const NoPageFound = lazy(() => import("../container/no-page-found"));
const Chating = lazy(() => import("../container/message"));
const Explore = lazy(() => import("../container/explore"));
const Reeels = lazy(() => import("../container/Reels/Reels"));
const Episodes = lazy(() => import("../container/podcast-episodes"));
const ReportABug = lazy(() => import("../container/auth/report-a-bug"));
const UserPosts = lazy(() => import("../container/user-posts"));
const ViewSingleStory = lazy(
  () => import("../components/dashboard/ViewSingleStory"),
);
const ViewSingleReel = lazy(
  () => import("../components/dashboard/ViewSingleReel"),
);
const ViewSinglePost = lazy(
  () => import("../components/dashboard/ViewSinglePost"),
);
const ProfileInfo = lazy(
  () => import("../container/auth/profile-info/ProfileInfo"),
);

/* add your route here
 * ex.
 * { path: RoutePath.home, element: <Home />, index: true },
 * only pass index true in main path "/"
 * otherwise pass false or not pass Index param
 */

export const ListRouteElements: CommonRouteProps[] = [
  {
    path: RoutePath.hypeCast,
    element: <HypeCast />,
    index: true,
    isAuth: true,
  },
  {
    path: RoutePath.exploreSearch,
    element: <ExploreSearch />,
    index: false,
    isAuth: true,
  },
  { path: RoutePath.home, element: <Home />, index: false, isAuth: true },
  {
    path: RoutePath.hypeCastSearch,
    element: <HypeCastSearch />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastEpisodesByCat,
    element: <HypeCastEpisodesByCat />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastFollowedPodcast,
    element: <HypeCastFollowedPodcast />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastSavedEpisodes,
    element: <HypeCastSavedEpisodes />,
    index: false,
    isAuth: true,
  },

  {
    path: RoutePath.channel,
    element: <HypeCastChannel />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.podcastByCategory,
    element: <HypeCastPodcastByCat />,
    // element: <PodcastByCategory />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.seeAllChannels,
    element: <HypeCastSeeAllChannels />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastPodcasts,
    element: <HypeCastPodcasts />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastEpisodes,
    element: <HypeCastEpisodes />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastCategories,
    element: <HypeCastCategories />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.search,
    element: <SearchBarPage />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.hypeCastPlayer,
    element: <HypeCastPlayer />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.categories,
    element: <Categories />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.episode,
    element: <Episode />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.episodes,
    element: <Episodes />,
    index: false,
    isAuth: true,
  },
  { path: RoutePath.login, element: <Login />, index: false, isAuth: false },
  {
    path: RoutePath.learnMore,
    element: <LearnMore />,
    index: false,
    isAuth: false,
  },
  {
    path: RoutePath.reportBug,
    element: <ReportABug />,
    index: false,
    isAuth: false,
  },
  {
    path: RoutePath.checkyourmail,
    element: <Checkyourmail />,
    index: false,
    isAuth: false,
  },
  {
    path: RoutePath.resetPassword,
    element: <ResetPasswod />,
    index: false,
    isAuth: false,
  },

  {
    path: RoutePath.forgotPassword,
    element: <ForgotPasswod />,
    index: false,
    isAuth: false,
  },
  {
    path: RoutePath.changePassword,
    element: <ChangePassword />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.signup,
    element: <Signup />,
    index: false,
    isAuth: false,
  },
  {
    path: RoutePath.verifyEmail,
    element: <VerifyEmail />,
    index: false,
    isAuth: null,
  },
  {
    path: RoutePath.emailVerification,
    element: <EmailVerified />,
    index: false,
    isAuth: null,
  },
  {
    path: RoutePath.phoneVerification,
    element: <PhoneVerified />,
    index: false,
    isAuth: null,
  },
  {
    path: RoutePath.verifyPhone,
    element: <VerifyPhone />,
    index: false,
    isAuth: null,
  },
  {
    path: RoutePath.emailPhoneVerify,
    element: <EmailPhoneVerified />,
    index: false,
    isAuth: null,
  },
  {
    path: RoutePath.userProfileById,
    element: <UserProfile />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.userPostsByType,
    element: <UserPosts />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.profile,
    element: <Profile />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.userPostsByTypeReels,
    element: <UserPostReels />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.messageGeneral,
    element: <Chating />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.chatByUserId,
    element: <Chating />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.explore,
    element: <Explore />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.notification,
    element: <Notification />,
    index: true,
    isAuth: true,
  },
  {
    path: RoutePath.createPost,
    element: <MobileCreatePost />,
    index: true,
    isAuth: true,
  },
  {
    path: RoutePath.reels,
    element: <Reels />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.storiesRoute,
    element: <Stories />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.viewSingleStory,
    element: <ViewSingleStory />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.viewSingleReel,
    element: <ViewSingleReel />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.viewSinglePost,
    element: <ViewSinglePost />,
    index: false,
    isAuth: true,
  },

  {
    path: RoutePath.explorePeople,
    element: <SuggestionListUsers />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.editPost,
    element: <MobileEditPost />,
    index: false,
    isAuth: true,
  },
  {
    path: RoutePath.profileInfo,
    element: <ProfileInfo />,
    index: false,
    isAuth: true,
    // isAuth: false,
  },
  {
    path: "*",
    element: <NoPageFound />,
    index: false,
    isAuth: null,
  },
];
