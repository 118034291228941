import React from "react";

const SocialIcon = (props: any) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 63 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1157_15114)">
        <path
          d="M35.21 0.5H60.21L24.21 35.5H0.209961L35.21 10.5Z"
          stroke={props.color ?? "white"}
          strokeMiterlimit="10"
          strokeWidth="8px"
        />
        <path
          d="M27.21 35.5H51.21L24.21 63.5L1.20996 62.5L27.21 35.5Z"
          stroke={props.color ?? "white"}
          strokeMiterlimit="10"
          strokeWidth="8px"
        />
        <path
          d="M26.21 64.5H50.21L27.21 88.5L26.21 64.5Z"
          stroke={props.color ?? "white"}
          strokeMiterlimit="10"
          strokeWidth="8px"
        />
      </g>
      <defs>
        <clipPath id="clip0_1157_15114">
          <rect width="62.44" height="89.69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SocialIcon;
