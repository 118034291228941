import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import ReelsPlayButton from "../buttons/ReelsPlayButton";
import { styles } from "../../../theming/Style/Styles";
import { Box } from "@mui/system";

interface VideoPlayerProps {
  videoUrl: string;
  index?: number;
  muted: boolean;
  height?: number;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  index,
  muted,
  height,
}) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const intersectionRef = useRef<HTMLDivElement | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0.8 }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef.current);
      }
    };
  }, []);

  const handleClick = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    setIsPlaying(isIntersecting);
  }, [isIntersecting]);

  return (
    <div className="video-player">
      <Box
        ref={intersectionRef}
        onClick={handleClick}
        sx={{
          height: { md: "85vh", xs: height },
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          controls={false}
          loop={true}
          playing={isPlaying}
          width={"100%"}
          height={"100%"}
          sx={{ ...styles.ReelsVideoPlayer }}
          muted={muted}
        />
      </Box>
      <ReelsPlayButton
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        index={index}
      />
    </div>
  );
};

export default VideoPlayer;
