import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageUtils } from "../../../helper";
import { KEY_LOCAL_STORAGE } from "../../../helper/local-storage";

interface LoginReducerProps {
  isLogin: boolean | false;
  infoLogin: any | {};
  infoProfile: any | {};
  infoAthlete: any | {};
}

const dataLocalStorage = LocalStorageUtils.GET_LOCAL_STORAGE_DATA(
  KEY_LOCAL_STORAGE.sessionToken
);
const dataLocalStorageProfile = LocalStorageUtils.GET_LOCAL_STORAGE_DATA(
  KEY_LOCAL_STORAGE.profileToken
);
const dataLocalStorageAthlete = LocalStorageUtils.GET_LOCAL_STORAGE_DATA(
  KEY_LOCAL_STORAGE.athleteToken
);
let dataInfo = {};
if (
  dataLocalStorage &&
  dataLocalStorage !== null &&
  dataLocalStorage.length > 0
) {
  dataInfo = JSON.parse(dataLocalStorage);
}
let dataInfo2 = {};
if (
  dataLocalStorageProfile &&
  dataLocalStorageProfile !== null &&
  dataLocalStorageProfile.length > 0
)
  dataInfo2 = JSON.parse(dataLocalStorageProfile);
let dataInfo3 = [];
if (
  dataLocalStorageAthlete &&
  dataLocalStorageAthlete !== null &&
  dataLocalStorageAthlete.length > 0
)
  dataInfo3 = JSON.parse(dataLocalStorageAthlete);

const initalStates: LoginReducerProps = {
  isLogin: Object.keys(dataInfo).length > 0 ? true : false,
  infoLogin: dataInfo,
  infoProfile: dataInfo2,
  infoAthlete: dataInfo3,
};

const LoginSlice = createSlice({
  name: "login",
  initialState: initalStates,
  reducers: {
    actionLogin(state: LoginReducerProps, action) {
      LocalStorageUtils.SET_LOCAL_STORAGE_DATA(
        KEY_LOCAL_STORAGE.sessionToken,
        JSON.stringify(action.payload)
      );
      state.isLogin = true;

      state.infoLogin = action.payload;
    },
    actionLogout(state: LoginReducerProps) {
      LocalStorageUtils.REMOVE_LOCAL_STORAGE_DATA(
        KEY_LOCAL_STORAGE.sessionToken
      );
      state.isLogin = false;
      state.infoLogin = null;
      state.infoProfile = null;
      state.infoAthlete = null;
    },
    actionProfileDetails(state: LoginReducerProps, action) {
      LocalStorageUtils.SET_LOCAL_STORAGE_DATA(
        KEY_LOCAL_STORAGE.profileToken,
        JSON.stringify(action.payload)
      );
      state.isLogin = true;      
      state.infoProfile = action.payload;
    },
    actionGuardianAthleteList(state: LoginReducerProps, action) {
      LocalStorageUtils.SET_LOCAL_STORAGE_DATA(
        KEY_LOCAL_STORAGE.athleteToken,
        JSON.stringify(action.payload)
      );
      state.isLogin = true;
      state.infoAthlete = action.payload;
    },
  },
});

export const {
  actionLogin,
  actionLogout,
  actionProfileDetails,
  actionGuardianAthleteList,
} = LoginSlice.actions;
export default LoginSlice.reducer;
