import { Close, KeyboardArrowRight, West } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ServiceDashBoard, ServiceUserProfile } from "../../api";
import { Plaintextinput } from "../../components/common";
import AutoCompleteInput from "../../components/common/auto-complete-input";
import AutoCompleteSelect from "../../components/common/auto-complete-select";
import Loader from "../../components/common/loader";
import CommonSwitch from "../../components/common/switch";
import CommonUploadButton from "../../components/common/uploadButton";
import { PostMedia } from "../../constants/Icons.constants";
import { showSocialSnackbar } from "../../store/reducer/Snackbar";
import {
  updateCroppedFiles,
  updateUploadedFiles,
} from "../../store/reducer/createPost";
import { styles } from "../../theming/Style/Styles";
import { AppStringUtils } from "../../utils";
import ImageCrop from "./ImageCrop";
import MediaPreview from "./MediaPreview";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { renderBioCharacterCount } from "./EditPost";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CreatePost(props: any) {
  const dispatch = useDispatch();
  const { showPopupCreatePost, setShowPopupCreatePost } = props;
  const t = AppStringUtils();
  const router = useNavigate();

  const [screen, setScreen] = useState<any>(1);
  const [xAxis, setXAxis] = useState<number>(100);
  const [yAxis, setYAxis] = useState<number>(100);
  const { croppedFiles: listFiles } = useSelector(
    (state: any) => state.createPost,
  );
  const { user_uuid, token } = useSelector(
    (state: any) => state.rLogin.infoLogin,
  );

  const handleClosePopup = () => {
    setShowPopupCreatePost(false);
    formik.resetForm();
  };
  const FILE_SIZE = 5242880;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagPeopleList, setTagPeopleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [tagPeopleValue, setTagPeopleValue] = React.useState([]);
  const [locationId, setLocationId] = React.useState<any>({});
  const [diamension, setDiamension] = useState<any>({
    height: 0,
    width: 0,
  });
  let postType = "";
  const [postTypeVideo, postTypeImage] = ["video", "image"];
  const formik: any = useFormik({
    initialValues: {
      file_name: "",
      user_uuid: user_uuid,
      file_url: null,
      file_type: "",
      file_description: "",
      caption: "",
      is_liked_allowed: true,
      is_comment_allowed: true,
      is_post_share_as_msg_allowed: true,
      is_saved_allowed: true,
    },
    validationSchema: Yup.object({
      file_name: Yup.string().required(t.WM_FILENAME_REQUIRED),
      file_url: Yup.mixed()
        .required(t.WM_FILEURL_REQUIRED)
        .test(
          "FILE_SIZE",
          t.WM_FILEURL_SIZE,
          (value) => !value || (value && value[0].size <= FILE_SIZE),
        ),
      file_type: Yup.string().required(t.WM_FILETYPE_REQUIRED),
      file_description: Yup.string().required(t.WM_FILEDESCRIPTION_REQUIRED),
      caption: Yup.string().test("FILE_FORMAT", t.WM_CAPTION_MAX, (value) =>
        value ? [...value]?.length <= 150 : true,
      ),
    }),
    onSubmit: (values: any) => {
      values.file_url = values.file_url[0];
      let formData: any = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
    },
  });
  const isMobileOrTablet = useMediaQuery("(max-width: 901px)");
  useEffect(() => {
    if (isMobileOrTablet) {
      handleClosePopup();
      // router(RoutePath.home);
    }
  }, [isMobileOrTablet]);

  useEffect(() => {
    getTagPeopleList("");
  }, []);

  const fileUploader = (file: any) => {
    let arr: any = [];
    Object.keys(file).forEach((e: any) => {
      arr.push(file[e]);
    });
    dispatch(updateUploadedFiles(arr));
    dispatch(updateCroppedFiles(arr));
    let data: any = new FormData();
    data.document_proof_image = file[0];
    let fileSizeValue = file[0]?.size;
    if (fileSizeValue > 1000000) {
      fileSizeValue = `${(fileSizeValue / 1024 / 1024).toFixed(2) + " MB"} `;
    } else {
      fileSizeValue = `${(fileSizeValue / 1024).toFixed(2) + " KB"}`;
    }

    const isVideo = Array.from(file).some((item: any) =>
      item.type?.includes(postTypeVideo),
    );
    isVideo ? setScreen(3) : setScreen(2);
  };

  const handleBioChange = (event: any) => {
    const inputValue = [...event.target.value];
    inputValue.filter((e: any) => {
      return e > 1;
    });
    // if (inputValue.length >= 150) {
    //   setDisableInput(true);
    // }
    const truncatedValue =
      inputValue.length > 150 ? inputValue.slice(0, 150) : inputValue;
    formik.setFieldValue(
      event.target.name,
      [...truncatedValue].join("").toString(),
    );
  };

  let newValueBio = [...formik.values.caption].filter((e: any, index: any) => {
    return e !== null;
  });

  const buttonValue = {
    buttonText: t.buttonTextSelectFromComputer,
    name: "file_url",
    value: formik.values.file_url,
    formik: formik,
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    touchedValue: formik.touched.file_url,
    errorsValue: formik.errors.file_url,
    fileUploader: fileUploader,
  };

  const captionFieldValue = {
    inputType: "text",
    placeholder: t.textPlaceholderCaption,
    name: "caption",
    value: newValueBio.join("").toString(),
    handleBlur: formik.handleBlur,
    handleChange: handleBioChange,
    touchedValue: formik.touched.caption,
    errorsValue: formik.errors.caption,
    multiline: true,
    rows: 3,
  };
  const advanceSetting = [
    {
      label: t.allowLike,
      name: "is_liked_allowed",
      value: formik.values.is_liked_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_liked_allowed,
      formik: formik,
    },
    {
      label: t.allowComments,
      name: "is_comment_allowed",
      value: formik.values.is_comment_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_comment_allowed,
      formik: formik,
    },
    {
      label: t.allowSharePostAsMessage,
      name: "is_post_share_as_msg_allowed",
      value: formik.values.is_post_share_as_msg_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_post_share_as_msg_allowed,
      formik: formik,
    },
    {
      label: t.allowSavePost,
      name: "is_saved_allowed",
      value: formik.values.is_saved_allowed,
      handleChange: formik.handleChange,
      touchedValue: formik.touched.is_saved_allowed,
      formik: formik,
    },
  ];

  const btnSubmitClicked = async () => {
    let finalArr: any = [];
    setIsLoading(true);
    for (let i = 0; i < listFiles.length; i++) {
      try {
        handleFileChange(listFiles[i]);
        let dict = new FormData();
        dict.append("media_url", listFiles[i]);
        dict.append("height", diamension?.height);
        dict.append("width", diamension?.width);
        if (listFiles[i].type.includes(postTypeVideo)) {
          dict.append("media_type", postTypeVideo);
          postType = postTypeVideo;
        } else if (listFiles[i].type.includes(postTypeImage)) {
          dict.append("media_type", postTypeImage);
          if (listFiles.length === 1) {
            postType = postTypeImage;
          } else if (listFiles.length > 1) {
            postType = postTypeImage;
          }
        }
        let result: any = await ServiceDashBoard.apiCallForAddMediaForPost(
          dict,
          token,
        );
        finalArr.push(result.data.uuid[0]);
      } catch (error) {
        setIsLoading(false);
      }
    }
    if (tagPeopleValue) {
      tagPeopleValue.map((tpValue: any, index: any) => {
        finalArr.map((finalArr: any, innerIndex: any) => {
          let reqBody = {
            user_uuid: tpValue?.user_uuid,
            x_axis: +xAxis + 20 * index, // Increment x-axis based on innerIndex
            y_axis: +yAxis + 20 * index,
          };
          apiCallTagPeople(finalArr, reqBody);
        });
      });
    }
    apiCallForCreatePost(finalArr);
  };

  const handleFileChange = (files: any) => {
    let selectedFile = files;
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img: any = new Image();
        img.onload = () => {
          setDiamension({
            height: img.height,
            width: img.width,
          });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const apiCallForCreatePost = (listUuid: any) => {
    let postTagsData = formik.values.caption;
    let words = ["#"];
    postTagsData = postTagsData.split(" ");
    postTagsData = postTagsData
      .flatMap((tag: any) => tag.match(/#[^\s#]+\b/g))
      .join(" ") // Join into a single string
      .match(/[a-zA-Z]+/g);

    if (!postTagsData || postTagsData.length === 0) {
      postTagsData = [""];
    }

    let dict = {
      post_type: postType,
      is_liked_allowed: formik.values.is_liked_allowed,
      is_comment_allowed: formik.values.is_comment_allowed,
      is_post_share_as_msg_allowed: formik.values.is_post_share_as_msg_allowed,
      is_saved_allowed: formik.values.is_saved_allowed,
      is_post_share_on_other_platform_allowed:
        formik.values.is_post_share_on_other_platform_allowed,
      post_tags: postTagsData,
      post_caption: formik.values.caption,
      media_uuid: listUuid,
      is_publish: true,
      location_uuid: locationId?.uuid,
    };
    if (dict.post_tags[0] === "") {
      delete dict.post_tags;
    }
    ServiceDashBoard.apiCallForCreatePost(dict, token)
      .then((res: any) => {
        setIsLoading(false);
        dispatch(showSocialSnackbar({ message: "Post created successfully!" }));
        handleClosePopup();
        setScreen(1);
        router(0);
      })
      .catch((err: any) => {
        setIsLoading(false);
        dispatch(showSocialSnackbar({ message: err }));
      });
  };

  const getTagPeopleList = (userName: string) => {
    let dataParam = {
      user_uuid: user_uuid,
      search: userName === "" ? "" : userName,
      list_type: "follower",
      page_size: 20,
      page: 1,
    };
    ServiceUserProfile.apiCallGetFollowerFollowing(dataParam, token)
      .then((res: any) => {
        if (res.data) {
          setIsLoading(false);
          let dataList = res.data.map((item: any) => {
            return { ...item.requested_by_data };
          });
          setTagPeopleList(dataList);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const apiCallTagPeople = (mediaId: any, reqBody: any) => {
    setIsLoading(true);
    ServiceDashBoard.apiCallPutAddTag(mediaId, reqBody, token)
      .then((res: any) => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        alert(err);
      });
  };

  const getLocationList = async (location: any) => {
    if (location) {
      setTimeout(() => {
        axios(
          `https://nominatim.openstreetmap.org/?addressdetails=&format=json&q=${location}`,
        )
          .then((res: any) => {
            setIsLoading(false);
            setLocationList(res.data);
          })
          .catch((err: any) => {
            setIsLoading(false);
          });
      }, 500);
    } else if (location === "") {
      setTagPeopleList([]);
    }
  };

  return (
    <Dialog
      fullScreen
      open={showPopupCreatePost}
      onClose={handleClosePopup}
      TransitionComponent={Transition}
    >
      <Box sx={{ ...styles.dialogBoxMedia }}>
        <DialogTitle sx={{ ...styles.textInformation3, fontWeight: "bold" }}>
          {screen === 1 ? (
            ""
          ) : (
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                left: 14,
                top: 10,
                color: "white",
                ...styles.pointer,
              }}
              onClick={() => {
                const isVideo = Array.from(listFiles).some((item: any) =>
                  item.type?.includes(postTypeVideo),
                );
                if (isVideo) {
                  setScreen(screen === 3 ? 1 : 3);
                } else {
                  setScreen(screen === 2 ? 1 : 2);
                }
              }}
            >
              <West />
            </IconButton>
          )}
          {t.textCreatePost}
          {screen === 1 ? (
            <IconButton
              aria-label="close"
              sx={styles.closeIcon}
              onClick={handleClosePopup}
            >
              <Close />
            </IconButton>
          ) : (
            <Box>
              <Typography
                sx={{
                  position: "absolute",
                  right: 25,
                  top: 20,
                  color: "white",
                  fontFamily: "Poppins",
                  ...styles.pointer,
                }}
                onClick={() => {
                  if (!isLoading) {
                    if (screen === 4) {
                      btnSubmitClicked();
                    } else {
                      setScreen(screen + 1);
                    }
                  }
                }}
              >
                {screen !== 4 ? t.buttonTextNext : "Share"}
              </Typography>
            </Box>
          )}
        </DialogTitle>
        <Divider color="grey" />
        {screen === 1 ? (
          <Box sx={{ ...styles.mainBox2, mt: 6 }}>
            <Box>
              <Typography sx={styles.textInformation3}>
                <PostMedia />
              </Typography>
              <Typography sx={{ ...styles.textInformation3, mt: 5 }}>
                {t.textDragPhotoVideo}
              </Typography>
            </Box>
            <Box sx={{ mt: 8 }}>
              <CommonUploadButton
                buttonValue={buttonValue}
                icon={false}
                buttonStyle={styles.uploadButton2}
                isMultiple={true}
                setIsLoading={setIsLoading}
              />
            </Box>
            <Box>{isLoading && <Loader />}</Box>
          </Box>
        ) : screen === 2 ? (
          <Box
            sx={{
              ...styles.mainBox2,
              mt: 3,
              px: 2,
              overflowX: "scroll",
              overflowY: "hidden",
            }}
          >
            {listFiles?.length === 1 &&
            listFiles[0]?.type?.includes(postTypeVideo) ? (
              <>
                <MediaPreview
                  style={{
                    height: "80vh",
                  }}
                />
              </>
            ) : (
              <ImageCrop setScreen={setScreen} />
            )}
          </Box>
        ) : screen === 3 ? (
          <Box sx={{ ...styles.mainBox2, my: 3, px: 2 }}>
            <MediaPreview
              style={{
                height: "80vh",
              }}
            />
          </Box>
        ) : screen === 4 ? (
          <Grid container spacing={2} sx={{ py: 4, px: 5 }}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ mx: 1, flex: 1, textAlign: "center" }}
            >
              <Box sx={styles.alignCenter}>
                <MediaPreview
                  style={{
                    height: "80vh",
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ mx: 2, flex: 1, textAlign: "right" }}
            >
              <Box sx={{ px: 2 }}>
                <Box>
                  <Plaintextinput
                    textFieldValue={captionFieldValue}
                    borderCurved={true}
                  />
                  {renderBioCharacterCount(captionFieldValue.value)}
                </Box>
                <Box
                  sx={{
                    ...styles.alignSpaceBetween,
                    my: 1,
                  }}
                >
                  <Box>
                    <Typography sx={styles.textInformation7}>
                      {t.textAddLocation}
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton
                      sx={{
                        color: "white",
                        mt: -1,
                        ...styles.pointer,
                      }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                </Box>
                <Box>
                  <AutoCompleteSelect
                    onClickCall={getLocationList}
                    listData={locationList}
                    selectedValues={setLocationId}
                    optionValue={"display_name"}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      ...styles.alignSpaceBetween,
                      my: 1,
                    }}
                  >
                    <Box>
                      <Typography sx={styles.textInformation7}>
                        {t.textAdvancedSetting}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        sx={{
                          color: "white",
                          mt: -1,
                          ...styles.pointer,
                        }}
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </Box>
                  </Box>
                  {advanceSetting.map((item: any, index: number) => (
                    <Box sx={{ ...styles.alignSpaceBetween, pb: 1.5 }}>
                      <Box>
                        <Typography sx={styles.textInformation5}>
                          {item?.label}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={styles.listBox1}>
                          <Box sx={{ px: 2, ...styles.pointer }}>
                            <CommonSwitch switchValue={item} />
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  <Typography sx={styles.textInformation5}>
                    {t.textChangePost}
                  </Typography>
                </Box>
                <Box sx={{ my: 1, textAlign: "start" }}>
                  <Typography sx={styles.textInformation7}>
                    {t.textTagPeople}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "start" }}>
                  <AutoCompleteInput
                    onClickCall={getTagPeopleList}
                    tagPeopleList={tagPeopleList}
                    onValueSelectCall={apiCallTagPeople}
                    selectedValues={setTagPeopleValue}
                    optionValue1={"first_name"}
                    optionValue2={"last_name"}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Box>{isLoading && <Loader />}</Box>
      </Box>
    </Dialog>
  );
}
