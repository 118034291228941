export const RoutePath = {
  home: "/",
  search: "/search",
  exploreSearch: "/explore-search",
  hypeCast: "/hype-cast",
  channel: "/hype-cast/channel/:id",
  seeAllChannels: "/hype-cast/see-all-channels",
  hypeCastPodcasts: "/hype-cast/podcasts",
  hypeCastEpisodes: "/hype-cast/episodes",
  hypeCastCategories: "/hype-cast/categories",
  hypeCastPlayer: "/hype-cast/play/:id",
  hypeCastPlayerConstant: "/hype-cast/play/",
  hypeCastSearch: "/hype-cast/search",
  hypeCastViewEpisode: "/hype-cast/episode/view/:id",
  hypeCastSavedEpisodes: "/hype-cast/saved-episodes",
  hypeCastFollowedPodcast: "/hype-cast/followed-podcast",
  hypeCastEpisodesByCat: "/hype-cast/episodes-by-cat/:id",
  podcastByCategory: "/hype-cast/podcast-by-cat/:id",
  categories: "/hype-cast/categories",
  episodes: "/hype-cast/episodes/:id",
  episode: "/hype-cast/episode/:id",
  login: "/login",
  learnMore: "/learn-more",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:id",
  changePassword: "/change-password",
  verifiedEmail: "/verified-email",
  emailPhoneVerify: "/verification",
  emailVerification: "/email-verification",
  phoneVerification: "/phone-verification",
  verifyPhone: "/verifyPhone",
  verifyEmail: "/verifyEmail",
  checkyourmail: "/checkyourmail",
  oldpassword: "/old-password",
  userProfileById: "/user-profile/:id",
  userPostsByType: "/user-posts",
  profile: "/setting",
  chatByUserId: "/chat/inbox/:id",
  explore: "/explore",
  storiesRoute: "/stories",
  userProfile: "/user-profile/",
  messageGeneral: "/chat/inbox",
  explorePeople: "/explore/people",
  selfStory: "/self-story",
  reels: "/reels",
  reportBug: "/report",
  notification: "/notification",
  createPost: "/create-post",
  editPost: "/edit-post/:id",
  viewSingleStory: "/view-story/:id",
  viewSingleReel: "/view-reel/:id",
  viewSinglePost: "/view-post/:id",
  profileInfo: "/profile-info",
  userPostsByTypeReels: "/user-reels",
};

export const AthletePath = [
  "/",
  "/hype-cast",
  "/hype-cast/channel/:id",
  "/hype-cast/podcast-by-cat/:id",
  "/hype-cast/see-all-channels",
  "/hype-cast/podcasts",
  "/hype-cast/episodes",
  "/hype-cast/categories",
  "/hype-cast/play/:id",
  "/hype-cast/search",
  "/hype-cast/episode/view/:id",
  "/hype-cast/saved-episodes",
  "/hype-cast/followed-podcast",
  "/hype-cast/episodes-by-cat/:id",
  "/hype-cast/categories",
  "/hype-cast/episodes/:id",
  "/hype-cast/episode/:id",
  "/login",
  "/learn-more",
  "/signup",
  "/forgot-password",
  "/reset-password/:id",
  "/change-password",
  "/verified-email",
  "/checkyourmail",
  "/old-password",
  "/user-profile/:id",
  "/user-profile",
  "/setting",
  "/profile-info",
];
