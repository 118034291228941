import { IconButton } from "@mui/material";
import React from "react";
import { styles } from "../../../theming/Style/Styles";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";

function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      size="small"
      style={
        disabled
          ? { ...styles.scrollIconButton1 }
          : { ...styles.scrollIconButton }
      }
    >
      {children}
    </IconButton>
  );
}
export const LeftArrow = (props: any) => {
  const { curPage, setCurPage, disabled, resetCommentList } = props;
  function scrollPrev() {
    if (!disabled) {
      resetCommentList();
      setCurPage(curPage - 1);
    }
  }
  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <KeyboardArrowLeft />
    </Arrow>
  );
};

export const RightArrow = (props: any) => {
  const {
    curPage,
    setCurPage,
    disabled,
    resetCommentList,
    hasNextPage,
    fetchMoreData,
    itemCount,
  } = props;
  function scrollNext() {
    if (!disabled) {
      resetCommentList();
      setCurPage(curPage + 1);
      if (curPage === itemCount - 1 && hasNextPage) {
        fetchMoreData();
      }
    }
  }
  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <KeyboardArrowRight />
    </Arrow>
  );
};
