import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import hypeImage from "../../../assets/Images/hype1.jpg";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { primaryColor, styles } from "../../../theming/Style/Styles";
import { AppStringUtils, MATCH_ONLY_STR } from "../../../utils";
import { CommonButton, Plaintextinput, Spinner } from "../../common";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  drawer2: {
    backgroundImage: `url(${hypeImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "start",
    backdropFilter: " blur(250px)",
    overflow: "hidden",
    borderRadius: "30px",
  },
}));

export default function ReportPostDialog(props: any) {
  const classes = useStyles();
  const t = AppStringUtils();
  const { infoLogin } = useSelector((state: any) => state?.rLogin || {});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      post_uuid: props?.uuid,
      description: "",
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required(t.WM_DESCRIPTION_REQUIRED)
        .matches(MATCH_ONLY_STR, t.reportUserEmojiText)
        .test(t.emptyReportError, (value: any) => {
          if (/^\s+$/.test(value)) {
            return false;
          }
          return true;
        }),
    }),
    onSubmit: (values: any) => {
      setIsButtonDisabled(true);
      let dictObj = { ...values };
      ServiceDashBoard.apiCallForReportOnPost(dictObj, infoLogin?.token)
        .then((res: any) => {
          if (!res.error) {
            props?.setIsShow(false);
            setIsButtonDisabled(false);
            formik.resetForm();
            dispatch(showSocialSnackbar({ message: res?.message }));
          }
        })
        .catch((err: any) => {
          props?.setIsShow(false);
          setIsButtonDisabled(false);
          dispatch(showSocialSnackbar({ message: err?.message }));
        });
    },
  });

  const reportFieldValue = {
    inputType: "text",
    name: "description",
    value: formik.values.description,
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    touchedValue: formik.touched.description,
    errorsValue: formik.errors.description,
    multiline: true,
    rows: 3,
    maxlength: 500,
  };

  return (
    <>
      <Dialog
        open={props.isShow}
        onClose={props?.handleClose2}
        maxWidth="xs"
        PaperProps={{ className: classes.drawer2 }}
      >
        <DialogTitle
          sx={{
            color: primaryColor,
            textAlign: "center",
            m: 1,
          }}
        >
          {t.reportPostWarning}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <Plaintextinput textFieldValue={reportFieldValue} />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            {!isButtonDisabled && (
              <CommonButton
                buttonText={t.send}
                buttonType={"submit"}
                onClicked={formik.handleSubmit}
                buttonStyle={{
                  width: "30%",
                }}
              />
            )}
            {isButtonDisabled && (
              <Box sx={styles.spinnerBox}>
                <Spinner isShow={isButtonDisabled} />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
