import { reject } from "lodash";
import { LocalStorageUtils, ServiceRequest } from "../../helper";
import { KEY_LOCAL_STORAGE } from "../../helper/local-storage";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";

const apiCallLogin = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.login, reqBody, "")
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallforgotpassword = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.sendotp, reqBody, "")
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallSignUp = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.signup, reqBody, "")
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForverifyOTP = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.verifyotp, reqBody, "")
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallEmailStatus = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.emailStatus, reqBody, "")
      .then((res: any) => {
        if (res) {
          resolve(res);
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const apiCallUsernameStatus = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.usernameStatus, reqBody, "")
      .then((res: any) => {
        if (res) {
          resolve(res);
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const apiCallResendEmailCode = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.resendEmailCode, reqBody, "")
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallResendPhoneCode = (reqBody: any) => {
  let token: any = reqBody.token ? reqBody.token : getSignuptoken();
  if (reqBody.token) {
    delete reqBody.token;
  }
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.auth.resendPhoneCode,
      reqBody,
      token ?? "",
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForResetpassword = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(endPoint.auth.changePassword, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallVerifyEmailCode = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.verifyEmail, reqBody, "")
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallVerifyPhoneCode = (reqBody: any) => {
  let token: any = getSignuptoken();
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.verifyPhone, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallAthleteGuardianRelation = (reqBody: any) => {
  let token: any = getSignuptoken();
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      endPoint.auth.athleteGuardianRelationList,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallAddguardian = (reqBody: any) => {
  let token: any = getSignuptoken();
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.addGuardian, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallDocumentList = (reqBody: any) => {
  let token: any = getSignuptoken();
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      endPoint.auth.acceptingDocuments,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallAddGuardianDocument = (reqBody: any) => {
  let token: any = getSignuptoken();
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.auth.addGuardianDocument,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const getSignuptoken = () => {
  let token = LocalStorageUtils.GET_LOCAL_STORAGE_DATA(
    KEY_LOCAL_STORAGE.signupToken,
  );
  return token;
};

const apiCallForTimezone = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.auth.timeZone, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallUserStatus = (reqBody: boolean, token: string) => {
  return new Promise((resolve, reject) => {
    let url = `${endPoint.auth.userOnlineStatus}?is_online=${reqBody}`;
    ServiceRequest.GetApiRequest(url, "", token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallForUserLogout = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(`${endPoint.auth.logout}`, "", token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const AuthApi = {
  apiCallLogin,
  apiCallforgotpassword,
  apiCallSignUp,
  apiCallForverifyOTP,
  apiCallForResetpassword,
  apiCallEmailStatus,
  apiCallResendEmailCode,
  apiCallResendPhoneCode,
  apiCallVerifyEmailCode,
  apiCallVerifyPhoneCode,
  apiCallAthleteGuardianRelation,
  apiCallAddguardian,
  apiCallDocumentList,
  apiCallAddGuardianDocument,
  apiCallForTimezone,
  apiCallUserStatus,
  apiCallForUserLogout,
  apiCallUsernameStatus,
};

export { AuthApi };
