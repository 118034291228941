import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { actionPlayer } from "../../store/reducer/player";
import { styles } from "../../theming/Style/Styles";
import { Box } from "@mui/system";
import Spinner from "./spinner";
interface VideoPlayerProps {
  videoUrl: string;
  showIconButton: boolean;
  showPopup: boolean;
  thumbnailURL: string;
}
const VideoPlayers: React.FC<VideoPlayerProps> = (props: any) => {
  const { videoUrl, showIconButton, showPopup, thumbnailURL } = props;
  const dispatch = useDispatch();
  const playerRef = useRef<ReactPlayer | null>(null);
  const intersectionRef = useRef<HTMLDivElement | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const { isMuted, isPlay } = useSelector((state: any) => state.player);
  const [isLoading, setIsLoading] = useState(true); // Track whether video is loading

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },

      { threshold: 1 },
    );
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
    intersectionRef?.current?.focus();
    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef?.current);
      }
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    if (isIntersecting) {
      playerRef.current?.seekTo(0); // Reset video to start when it becomes visible
    }
  }, [isIntersecting]);
  const handlePlay = () => {
    playerRef.current?.seekTo(0); // Reset video to start when it plays
  };

  const togglePlay = () => {
    setIsIntersecting(!isIntersecting);
  };
  const toggleMute = () => {
    dispatch(actionPlayer(!isMuted));
  };
  const handleContextMenu = (event: any) => {
    event.preventDefault();
  };

  return (
    <ImageListItem key={0} sx={styles.pointer}>
      <div
        className="video-player"
        style={{
          borderRadius: "8px",
        }}
      >
        {/* Use intersectionRef here */}
        <div ref={intersectionRef} onClick={togglePlay}>
          <Box onContextMenu={handleContextMenu} sx={styles.videoPlayersBox}>
            {!isLoading && (
              <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                controls={false}
                loop={true}
                muted={isMuted}
                playing={
                  isIntersecting && ((showPopup && showPopup) || !isPlay)
                }
                onPlay={handlePlay}
                width="100%"
                height={"100%"}
                style={{
                  boxShadow: "0px 1px 4px 0px #241E17",
                  borderRadius: "8px",
                }}
              />
            )}

            {isLoading && (
              <Box sx={styles.videoPlayerSpinner}>
                <Spinner isShow={isLoading} />
              </Box>
            )}
            {/* Show loader while video is loading */}
          </Box>
        </div>
        {/* {!isIntersecting && showIconButton && (
          <div className="play-button-overlay">
            <IconButton
              className="play-button"
              sx={{ fontSize: "large", color: "white" }}
              onClick={togglePlay}
            >
              {isIntersecting ? <Pause /> : <PlayCircle />}
            </IconButton>
          </div>
        )} */}
        {showIconButton && (
          <ImageListItemBar
            sx={styles.videoPlayersItemBar}
            position="bottom"
            actionIcon={
              <IconButton
                sx={{ color: "white", zIndex: "1" }}
                onClick={() => {
                  toggleMute();
                }}
              >
                {isMuted ? <VolumeOff /> : <VolumeUp />}
              </IconButton>
            }
            actionPosition={"right"}
          />
        )}
      </div>
    </ImageListItem>
  );
};
export default VideoPlayers;
