import React, { useCallback, useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { primaryColor, styles } from "../../theming/Style/Styles";
import { Avatar, Box, Typography } from "@mui/material";
import { debounce } from "lodash";
import { RoutePath } from "../../routes/route-path";
import { useDispatch } from "react-redux";
import { updateSearchParams } from "../../store/reducer/search";

export default function AutoCompleteSearch(props: any) {
  const {
    onClickCall,
    listData,
    optionValue1,
    optionValue2,
    optionValue3,
    optionValue4,
    optionValue5,
    inputStyles,
    route,
  } = props;

  const [debouncedOnClickCall, setDebouncedOnClickCall] = useState<
    (value: string) => void
  >(() => () => {});
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const debounced = debounce((value: string) => {
      onClickCall(value);
      dispatch(updateSearchParams(value));
    }, 500);

    setDebouncedOnClickCall(() => debounced);

    return () => {
      debounced.cancel(); // Cleanup debounce on component unmount
    };
  }, [onClickCall]);

  const onKeyUpValue = useCallback(
    (event: any) => {
      setInputValue(event.target.value);
      debouncedOnClickCall(event.target.value);
    },
    [debouncedOnClickCall]
  );

  return (
    <Stack spacing={3}>
      <Autocomplete
        ListboxProps={{ style: { maxHeight: 500, overflow: "auto" } }}
        popupIcon={""}
        id="tags-standard"
        options={listData}
        getOptionLabel={(option: any) =>
          option[optionValue1] + " " + option[optionValue2]
        }
        renderOption={(props: any, option: any) => (
          <Box
            style={{
              backgroundColor: "#000000",
              px: 1,
            }}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              my: -1,
            }}
            {...props}
          >
            <Box
              sx={{ mx: 1, py: 1, ...styles.alignStart }}
              onClick={() => {
                sessionStorage.setItem("userDetail", JSON.stringify(option));
                route(RoutePath.userProfile + option[optionValue5]);
              }}
            >
              <Avatar
                sx={styles.notificationUserImg}
                imgProps={{ loading: "lazy" }}
                src={option[optionValue4]}
              />
              <Typography component="div" sx={styles.searchUsername}>
                {option[optionValue1] + " " + option[optionValue2]}
                <Typography
                  component="div"
                  variant="body2"
                  sx={styles.postNameText2}
                >
                  {"@" + option[optionValue3]}
                </Typography>
              </Typography>
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className="input-plain-background"
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Search"
            value={inputValue}
            onKeyUp={onKeyUpValue}
            sx={{
              ...inputStyles,
              "& .MuiChip-label": {
                color: primaryColor,
              },
              "&:hover .MuiSvgIcon-root": {
                color: primaryColor,
              },
              "& .MuiSvgIcon-root": {
                color: primaryColor,
              },
              color: primaryColor,
              position: "relative",
            }}
          />
        )}
      />
    </Stack>
  );
}
