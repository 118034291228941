import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";
import { primaryColor, styles } from "../../theming/Style/Styles";
import { ServiceDashBoard } from "../../api";
import { useSelector } from "react-redux";

export default function AutoCompleteSelect(props: any) {
  const { onClickCall, listData, selectedValues, optionValue, defaultValue } =
    props;

  const { token } = useSelector((state: any) => state.rLogin.infoLogin);

  const onKeyUpValue = (event: any) => {
    onClickCall(event.target.value);
  };
  const [inputValue, setInputValue] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState(
    {
      display_name: defaultValue?.location_name || "",
      lat: defaultValue?.location_latitude || "",
      lon: defaultValue?.location_longitude || "",
    } || null,
  );

  const getNewLocationID = (newValue: any) => {
    let reqBody = {
      location_name: newValue?.display_name,
      location_latitude: newValue?.lat,
      location_longitude: newValue?.lon,
      full_address: newValue.display_name,
    };
    ServiceDashBoard.apiCallForAddNewLocation(reqBody, token)
      .then((res: any) => {
        selectedValues(res.data);
      })
      .catch((err: any) => {
        selectedValues("");
      });
  };

  return (
    <Autocomplete
      value={selectedOption}
      onChange={(_event, newValue: any) => {
        setSelectedOption(newValue || null);
        if (newValue) {
          getNewLocationID(newValue);
        } else {
          selectedValues(null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== null) {
          setInputValue(newInputValue);
        }
      }}
      inputValue={inputValue}
      // defaultValue={{ [optionValue]: defaultValue?.[optionValue] || "" }}
      options={listData}
      getOptionLabel={(option: any) => option[optionValue]}
      renderInput={(params) => (
        <TextField
          {...params}
          className="input-plain-background1"
          variant="outlined"
          fullWidth
          size="small"
          placeholder=""
          onKeyUp={onKeyUpValue}
          sx={{
            ...styles.borderOutline,
            "& .MuiChip-label": {
              color: primaryColor,
            },
            "&:hover .MuiSvgIcon-root": {
              color: primaryColor,
            },
            "& .MuiSvgIcon-root": {
              color: primaryColor,
            },
          }}
        />
      )}
    />
  );
}
