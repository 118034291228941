import {
  Avatar,
  Box,
  Container,
  Divider,
  Menu,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styles } from "../../theming/Style/Styles";
import { AppStringUtils } from "../../utils";
import { ServiceNotification } from "./../../api/notification/index";
import CommonButton from "./../../components/common/button";
import { Check, Clear } from "@mui/icons-material";
import { NotificationTypes } from "../../components/common/enums/notification-types";
import CommonSkeleton from "../../components/common/skeleton";
import { useNavigate } from "react-router";
import UserPostPopup from "../../components/dashboard/post-card/user-post-popup";
import Loader from "../../components/common/loader";
import { useDispatch } from "react-redux";
import { showSocialSnackbar } from "../../store/reducer/Snackbar";

export default function Notification(props: any) {
  const router = useNavigate();
  const { anchorEl, handleClose, open, id, setReadNotification } =
    props.notificationProps;
  const { token } = useSelector((state: any) => state?.rLogin?.infoLogin || {});
  const [media, setMedia] = useState<any>({});
  const [userUid, setUserUid] = useState<any>("");
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState<any>({
    today: [],
    last_week: [],
    yesterday: [],
  });
  const [showPopup, setShowPopup] = useState(false);
  const t = AppStringUtils();
  const handleClosed = () => {
    handleClose();
    setTimeout(() => {
      setNotificationData({
        today: [],
        last_week: [],
        yesterday: [],
      });
      setReadNotification(false);
    }, 300);
  };
  const [popupLoading, setPopupLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const apiCallGetNotificationList = () => {
    setIsLoading(true);
    ServiceNotification.apiCallGetNotificationList(token)
      .then((res: any) => {
        setNotificationData({
          today: res.today,
          last_week: res.last_week,
          yesterday: res.yesterday,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  let n = NotificationTypes;
  let text = "";
  const notificationTextType = (notificationType: any) => {
    switch (notificationType) {
      case n.NOTIFICATION_FOLLOW_REQUEST:
        return (text = t.textUserRequestedToFollow);
      case n.NOTIFICATION_FOLLOW_REQUEST_ACCEPT:
        return (text = t.textUserAcceptRequest);
      case n.NOTIFICATION_USER_STARTED_FOLLOWING:
        return (text = t.textUserStartedFollowing);
      case n.NOTIFICATION_POST_LIKE:
        return (text = t.textLikedPost);
      case n.NOTIFICATION_POST_COMMENT:
        return (text = t.textCommentedOnPost);
      case n.NOTIFICATION_POST_CREATE:
        return (text = t.textNewPostAdded);
      case n.NOTIFICATION_POST_COMMENT_ON_COMMENT:
        return (text = t.textMentionedCommentYouInComment);
      case n.NOTIFICATION_POST_SHARE:
        return (text = t.textSharedPostWithYou);
      case n.NOTIFICATION_POST_USER_TAGGED:
        return (text = t.textHasBeenTaggedYou);
      case n.NOTIFICATION_POST_COMMENT_MENTIONED:
        return (text = t.textMentionedYouInComment);
      case n.NOTIFICATION_POST_NEW_POST_UPLOAD:
        return (text = t.textUploadedNewPost);
      case n.NOTIFICATION_POST_COMMENT_LIKE:
        return (text = t.LIKE_COMMENT);
      case n.NOTIFICATION_POST_USER_POKE:
        return (text = t.POKE_COMMENT);
    }
  };

  useEffect(() => {
    if (open) {
      apiCallGetNotificationList();
    }
  }, [open]);

  const apiCallForAcceptReject = (requestedStatus: string, uuid: string) => {
    ServiceNotification.apiCallForAcceptReject(
      { request_status: requestedStatus, follower_following_uuid: uuid },
      token,
    )
      .then((res: any) => {
        apiCallGetNotificationList();
        dispatch(showSocialSnackbar({ message: res.message }));
      })
      .catch((err) => {});
  };

  const SkeletonUICommon = () => {
    return (
      <Box sx={styles.notifiicationSkeleton}>
        <CommonSkeleton variant="circular" width={50} height={50} />
        <Box sx={{ width: "160px", mt: 1 }}>
          <CommonSkeleton
            variant="text"
            width="100%"
            height="25px"
            styles={{ borderRadius: "5px" }}
          />
          <Box>
            <CommonSkeleton
              width="100%"
              variant="text"
              height="25px"
              styles={{ borderRadius: "5px" }}
            />
          </Box>
        </Box>

        <CommonSkeleton
          variant="text"
          width="60px"
          height="100px"
          styles={{
            marginLeft: "10px",
            marginTop: "-22px",
            borderRadius: "10px",
          }}
        />
      </Box>
    );
  };

  const redirectToProfile = (user_uuid: string) => {
    handleClose();
    router(`/user-profile/${user_uuid}`);
  };

  const avoidTypes = [
    "user_follow_request_accept_notification",
    "user_started_following_notification",
    "user_follow_request_notification",
  ];
  const handleClick = (data: any) => {
    if (!avoidTypes.includes(data.notification_type)) {
      router(`/view-post/${data.post_data?.uuid}`);
      handleClose();
    }
  };
  const ListBody = (props: any) => {
    const { title } = props;
    const [listData, setListData] = useState<any>(props?.listData);
    return (
      <Box>
        <Box sx={styles.notificationBox}>
          <Typography component="div" sx={styles.notificationWeekHeading}>
            {title}
          </Typography>
        </Box>
        <Divider color="grey" />
        {listData &&
          listData.map((item: any, index: number) => (
            <Box sx={{ py: 2 }}>
              <Box
                sx={{
                  ...styles.alignSpaceBetween,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item);
                }}
              >
                <Box sx={{ ...styles.alignStart }}>
                  <Avatar
                    sx={styles.notificationUserImg}
                    src={item?.from_user_data?.profile_image}
                    imgProps={{ loading: "lazy" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      redirectToProfile(item.from_user_data?.user_uuid);
                    }}
                  />

                  <Typography component="div" sx={styles.notificationUsername}>
                    <Typography
                      sx={{ ...styles.lowercase, cursor: "pointer" }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        redirectToProfile(item.from_user_data?.user_uuid);
                      }}
                    >
                      {"@" + item?.from_user_data.username}
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      sx={styles.postNameText2}
                    >
                      {notificationTextType(item?.notification_type)}
                    </Typography>
                  </Typography>
                </Box>
                <Box sx={{ ...styles.alignEnd }}>
                  {item?.notification_type ===
                  "user_follow_request_notification" ? (
                    <Box sx={{ display: "flex", gap: 0.5 }}>
                      <Box sx={{ px: 0.5 }}>
                        <CommonButton
                          buttonText={""}
                          buttonType="text"
                          buttonSize="small"
                          classStyle="gradient-button3"
                          buttonStyle={{
                            ...styles.bold,
                          }}
                          onClicked={() => {
                            setListData(
                              listData.filter(
                                (el: any, i: number) => i !== index,
                              ),
                            );
                            apiCallForAcceptReject(
                              "accepted",
                              item.following_uuid,
                            );
                          }}
                          buttonIcon={<Check />}
                        />
                      </Box>
                      {/* <Box>
                        <CommonButton
                          buttonText={""}
                          buttonType="text"
                          buttonSize="small"
                          classStyle="gradient-button4"
                          buttonStyle={{
                            ...styles.bold,
                          }}
                          onClicked={() => {
                            setListData(
                              listData.filter(
                                (el: any, i: number) => i !== index,
                              ),
                            );
                            apiCallForAcceptReject(
                              "rejected",
                              item.following_uuid,
                            );
                          }}
                          buttonIcon={<Clear />}
                        />
                      </Box> */}
                    </Box>
                  ) : avoidTypes.includes(item?.notification_type) ? (
                    <></>
                  ) : (
                    <Avatar
                      sx={styles.notificationPostImage}
                      src={
                        item?.post_data?.media_url[0]
                          ? item?.post_data?.media_url[0]?.media_thumbnail_url
                          : item?.post_data?.media_url[0]?.media_url
                      }
                      imgProps={{ loading: "lazy" }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={handleClosed}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      {popupLoading && <Loader />}
      <Box sx={{ my: -1 }}>
        <Container maxWidth="xs" sx={{ mx: 1 }}>
          <Box sx={styles.notificationListBox}>
            {notificationData.today.length > 0 ? (
              <ListBody title={"Today"} listData={notificationData.today} />
            ) : (
              ""
            )}
            {notificationData.yesterday.length > 0 ? (
              <ListBody
                title={"Yesterday"}
                listData={notificationData.yesterday}
              />
            ) : (
              ""
            )}
            {notificationData.last_week.length > 0 ? (
              <ListBody
                title={"Last week"}
                listData={notificationData.last_week}
              />
            ) : (
              ""
            )}
            {notificationData.today.length === 0 &&
            notificationData.yesterday.length === 0 &&
            notificationData.last_week.length === 0 &&
            !isLoading ? (
              <Box sx={{ ...styles.contentBox1 }}>
                <Typography variant="h5" sx={styles.textInformation3}>
                  {t.noNotificationMsg}
                </Typography>
              </Box>
            ) : isLoading ? (
              <Box sx={{ mt: 7 }}>
                {Array(4).fill(<SkeletonUICommon />)}
                <SkeletonUICommon />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Container>
        <UserPostPopup
          info={media}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          userUid={userUid}
          {...media}
        />
      </Box>
    </Menu>
  );
}
