import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import React from "react";

export default function ShareMessageDialog(props: any) {
  const { showLimitExceededDialog, setShowLimitExceededDialog } = props;
  return (
    <Dialog
      open={showLimitExceededDialog}
      onClose={() => setShowLimitExceededDialog(false)}
      PaperProps={{
        style: {
          backgroundColor: "#282956",
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle sx={{ color: "#FFF" }}>
        You can't share more than five users.
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => setShowLimitExceededDialog(false)}
          sx={{ color: "#FFF" }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
