import HypeLogo from "../../app/assets/Images/hype-logo.png";
import { styles } from "../theming/Style/Styles";
import AppStoreSVG from "../../app/assets/SVG/appstore.svg";
import PlayStoreSVG from "../../app/assets/SVG/playstore.svg";
import ImageListItem from "@mui/material/ImageListItem";
import { Avatar } from "@mui/material";
import MediaPost from "../../app/assets/SVG/media-post.svg";

export const Logo = (style: any) => {
  return (
    <ImageListItem sx={style}>
      <img src={HypeLogo} alt="" loading="lazy" />
    </ImageListItem>
  );
};
export const AppStore = (props: any) => {
  return (
    <ImageListItem sx={styles.widthStore}>
      <img src={AppStoreSVG} alt="" loading="lazy" />
    </ImageListItem>
  );
};
export const PlayStore = (props: any) => {
  return (
    <ImageListItem sx={{ ...styles.widthStore, ...styles.ml1 }}>
      <img src={PlayStoreSVG} alt="" loading="lazy" />
    </ImageListItem>
  );
};

export const UserProfileicon = (props: any) => {
  return (
    <Avatar
      sx={styles.userProfile}
      src={props.profileImage}
      imgProps={{ loading: "lazy" }}
    />
  );
};

export const FriendsProfileIcon = (props: any) => {
  const { userImage } = props;
  return (
    <Avatar
      sx={styles.userProfile}
      src={userImage}
      imgProps={{ loading: "lazy" }}
    />
  );
};

export const FriendsProfileIconStory = (props: any) => {
  const { userImage } = props;
  return (
    <Avatar
      sx={styles.userProfileStory}
      src={userImage}
      imgProps={{ loading: "lazy" }}
    />
  );
};

export const ProfileImage = (props: any) => {
  return (
    <Avatar
      sx={props.styles}
      src={props.imgURL}
      imgProps={{ loading: "lazy" }}
    />
  );
};

export const PostMedia = () => {
  return (
    <ImageListItem sx={{ width: 200, height: 200 }}>
      <img src={MediaPost} alt="" loading="lazy" />
    </ImageListItem>
  );
};
