import { BrowserRouter } from "react-router-dom";
import BackgroundImage from "../components/common/BackgroundImage";
// import Footer from "../components/footer";
import Header from "../components/header";
import { RoutesElements } from "./root-routes";

function RootRoute() {
  return (
    <BrowserRouter>
      <Header />
      <BackgroundImage>
        <RoutesElements />
      </BackgroundImage>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default RootRoute;
