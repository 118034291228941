const stagingServerURL = "https://backend.hypeathletenetwork.com";
// const stagingServerURL = "http://192.168.1.119:8080";
// const stagingServerURL = "http://127.0.0.1:8080";

export const mediaURLThumNailURL =
  "https://hype-developer-staging-16-03-23.s3.amazonaws.com/";

const liveServerURL = "https://developer.hypeathletenetwork.com";
export const APIUrl = "/api";
export const webSocketUrl =
  "wss://backend.hypeathletenetwork.com:8000/message ";
// export const webSocketUrl = "ws://backend.hypeathletenetwork.com:8000/message";
// export const webSocketUrl = "ws://192.168.1.119:8080/message";

// new-server URL

// export const webSocketNotificationUrl =
//   "ws://backend.hypeathletenetwork.com:8000/messaging_notification_counts";
export const webSocketNotificationUrl =
  "wss://backend.hypeathletenetwork.com:8000/messaging_notification_counts";

export const BaseUrl = stagingServerURL;

const auth = {
  login: "/login/",
  sendotp: "/send-reset-otp-email/",
  signup: "/primary-registration/",
  verifyotp: "/verify-reset-otp-email/",
  checkpassword: "/check-password/",
  changePassword: "/change-password/",
  emailStatus: "/email-status/",
  resendEmailCode: "/resend-email-otp/",
  resendPhoneCode: "/resend-phone-otp/",
  verifyEmail: "/email-verification/",
  verifyPhone: "/verify-phone/",
  athleteGuardianRelationList: "/athlete-guardian-relation-list/",
  addGuardian: "/add-guardian/",
  acceptingDocuments: "/accepting-documents/",
  addGuardianDocument: "/upload-guardian-document/",
  timeZone: "/timezone/",
  userOnlineStatus: "/user-online-status/",
  logout: "/logout/",
  usernameStatus: "/username-status/",
};

const OtherUserProfile = {
  profileOwnerDetails: "/profile/profile-owner/?user_uuid=",
  profilePublicDetails: "/profile/public-view/?user_uuid=",
  profilePostGallery: "/posts/gallery/",
  followUnfollowUser: "/profile/follow-unfollow/",
  followerFollowing: "/profile/follower-following-list/",
  mutualFollower: "/profile/mutual-friends/",
};

const profile = {
  userAddeditDetails: "/user-profile/",
  userAthleteProfiling: "/athlete-single-profile/",
  athleteAddEditProfiling: "/athlete-profile/",
  athleteFiles: "/athlete-files/",
  athleteGetFiles: "/athlete-get-files/",
  athleteDeleteFiles: "/athlete-delete-files/",
  userSports: "/sports/?search",
  userClass: "/classes/",
  userState: "/states/",
  userCity: "/city/",
  userPersonalInfo: "/user-personal-information/",
  changePassword: "/change-password/",
  twoFactorAuthentication: "/account-setting/2-factor-authentication/",
  coachDetails: "/coach-profile/",
  changeEmail: "/send-otp-email/",
  changePhone: "/send-otp-sms/",
  verifyNewEmail: "/verify-otp-email/",
  verifyNewPhone: "/verify-otp-phone/",
  checkPassword: "/check-password/",
  blockUser: "/profile/block-user/",
  userReport: "/profile/report-user/",
};
const message = {
  allchatlist: "/get-all-chats/",
  conversation: "/conversation-relation/",
  newMessage: "/new-message/",
  searchFriend: "/explore/?keyword=",
  sendMediaMessage: "/add-media/",
  updatestatus: "/conversation-status/",
  messageSeenStatus: "/seen-message/",
  deleteMessage: "/delete-message/",
  sharedMessagesAPI: "/share-post-profile-messages/",
  readNewMsg: "/analytics/user-activity-log/",
};

const accountSetting = {
  basicAccountSetting: "/basic-account-setting/?user_uuid=",
  privacyAccountSetting: "/privacy-account-setting/?user_uuid=",
  userPermissionSetting: "/user-permission-setting/?user_uuid=",
  feedControlSetting: "/restrictions-account-setting/?user_uuid=",
  reportBugAddMedia: "/report-bug/add-media/",
  reportBug: "/report-bug/",
};

const dashboard = {
  getGalleryPost: "/posts/gallery/",
  likePost: "/post-like/",
  commentOnPost: "/comment-on-post/",
  commentLikeUnlike: "/comment-like-unlike/",
  addMedia: "/post/add-media/",
  createPost: "/content/post/",
  explore: "/explore/?keyword=",
  msgExplore: "/messaging/explore/?keyword=",
  tagPeopleMediaFiles: "/post/add-media/",
  location: "/explore/location/?location_name=",
  popularUsersList: "/popular-users-list/",
  savePost: "/saved_post/",
  savedPostList: "/saved_post/list/",
  guardianAthleteList: "/guardian-athlete-list/",
  getStoriesList: "/my-own-and-followers-stories/",
  commentOnStory: "/comment-on-story/",
  getOwnStory: "/stories-owner/",
  sendMessageUserList: "/send-message-users-list/",
  storiesSeenLike: "/stories-seen-and-like/",
  deletePost: "/content/post/",
  addStory: "/add-story/",
  deleteStory: "/delete-story/",
  feedsOfFollowingUsers: "/get-feeds-of-following-users/",
  reportOnPost: "/report-on-post/",
  postNotInterested: "/post-not-interested/",
  getPostById: "/content/post/",
  searchSport: "/sports/",
  searchCategories: "/categories/",
  onboardingFlow: "/onboarding-flow/",
  newLocation: "/new-location/",
  exploreLocation: "/explore/location/?location_name=",
  exploreHastags: "/explore/hashtags/?hashtag=",
};
const hypeCast = {
  getPodcast: "/hypecast/home/",
  getChannel: "/channel/",
  getPodcastsByChannelId: "/hypecast/podcast-by-channel/",
  getPodcastsByCategoryId: "/hypecast/podcast-by-category/",
  getEpisodesPodcast: "/hypecast/episodes-by-podcast/",
  getPopularEpisode: "/hypecast/episode/",
  getCategories: "/categories/",
  seeAllPodcasts: "/hypecast/home/see-all-podcast/",
  seeAllPodcastsFollow: "/podcast-follow/",
  seeAllEpisodes: "/hypecast/home/see-all-episode/",
  seeAllRecommendedPodcasts: "/hypecast/home/see-all-recommendation-podcast/",
  seeAllRecommendedEpisodes: "/hypecast/home/see-all-recommendation-episode/",
  search: "/hypecast/search/",
  episodeCrud: "/hype-cast-save-crud/",
  podcastFollow: "/podcast-follow/",
  getEpisodesByPodcastId: "/hypecast/episodes-by-category/",
  updateRecentlyPlayed: "/analytics/episode-dc",
  getScreenStatus: "/registration-screen-status/",
};
const notification = {
  notificationList: "/notifications-list/",
  acceptReject: "/profile/follow-request-accept-reject/",
  notificationCount: "/notifications-count/",
  notificationsRead: "/notifications-read/",
};

const explore = {
  explorePost: "/explore/post/",
  exploreHastags: "/explore/post-hashtag/",
};
const hypeSocial = {
  inviteCode: "/get-invite-code/",
  screenStatus: "/registration-screen-status/",
};

const reels = {
  getAllReels: "/reels-recommendation/",
};

const endPoint = {
  auth,
  profile,
  accountSetting,
  OtherUserProfile,
  message,
  dashboard,
  notification,
  explore,
  hypeCast,
  reels,
  hypeSocial,
};

export { endPoint };
