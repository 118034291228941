import { Suspense } from "react";
import "./App.css";
import { Spinner } from "./app/components/common";
import RootRoute from "./app/routes";

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="div-loading">
          <Spinner isShow={true} />
        </div>
      }
    >
      <RootRoute />
    </Suspense>
  );
};
export default App;
