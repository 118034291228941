import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";

function PostContent(props: any) {
  const t = AppStringUtils();
  const { postText, postTags, postedByData } = props;
  const [openPostText, setOpenPostText] = React.useState(false);
  const maxLength = 100;

  const renderTextWithLineBreaks = (text: string) => {
    if (text?.includes("\n")) {
      return text?.split("\n")?.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      return text;
    }
  };

  return (
    <Box sx={{ px: 2, maxWidth: props?.width }}>
      {(postText?.length > 0 || postTags?.length > 0) && (
        <Box
          sx={{
            ...styles.postContentText1,
            marginBottom: "-10px",
            fontWeight: "bold",
          }}
        >
          {postedByData?.username}
        </Box>
      )}

      <Typography variant="body2" sx={styles.postContentText1}>
        {postText?.length > 0 ? (
          <>
            {!openPostText && postText && postText?.length >= maxLength ? (
              <Box component="span" sx={styles.textFlowWraps}>
                {renderTextWithLineBreaks(postText?.slice(0, maxLength))}
                <Box
                  component="span"
                  color="#7070ef"
                  sx={{
                    ...styles.pointer,
                    ...styles.textFlowWraps,
                  }}
                  onClick={() => setOpenPostText(true)}
                >
                  {`... ${t.textShowMore}`}
                </Box>
              </Box>
            ) : (
              <Box component="span" sx={styles.textFlowWraps}>
                {renderTextWithLineBreaks(postText)}
                {openPostText && postText && postText?.length >= maxLength && (
                  <Box
                    component="span"
                    color="#7070ef"
                    sx={{ ...styles.pointer, ...styles.textFlowWraps, ml: 1 }}
                    onClick={() => setOpenPostText(false)}
                  >
                    {t.textShowLess}
                  </Box>
                )}
              </Box>
            )}
          </>
        ) : (
          <>
            {postTags?.length > 0 && postTags && (
              <>
                <Box component="span" sx={styles.textFlowWraps}>
                  {postTags?.length > 0 &&
                    postTags?.map((item: any, index: number) => (
                      <>{item.length > 1 ? "#" + item : ""}</>
                    ))}
                </Box>
              </>
            )}
          </>
        )}
      </Typography>
    </Box>
  );
}

export default PostContent;
