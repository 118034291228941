import ImageListItem from "@mui/material/ImageListItem";
import { ImagePath } from "../../assets/Images";
import { styles } from "../../theming/Style/Styles";

export const Logo = (props: any) => {
  return (
    <ImageListItem sx={props.style}>
      <img src={ImagePath.HypeLogo} alt="" />
    </ImageListItem>
  );
};
export const AppStore = (props: any) => {
  return (
    <ImageListItem sx={styles.widthStore}>
      <img src={ImagePath.AppStoreSVG} alt="" loading="lazy" />
    </ImageListItem>
  );
};
export const PlayStore = (props: any) => {
  return (
    <ImageListItem sx={{ ...styles.widthStore, ...styles.ml1 }}>
      <img src={ImagePath.PlayStoreSVG} alt="" loading="lazy" />
    </ImageListItem>
  );
};
export const OrganizationIcon = (props: any) => {
  return (
    <ImageListItem sx={{ width: "23px", height: "23px" }}>
      <img src={ImagePath.organizationIcon} alt="" width="25px" height="11px" />
    </ImageListItem>
  );
};
export const SportsIcon = (props: any) => {
  return (
    <ImageListItem sx={{ width: "22px", height: "22px" }}>
      <img src={ImagePath.sportsIcon} alt="" width="22px" height="11px" />
    </ImageListItem>
  );
};
export const CoachIcon = (props: any) => {
  return (
    <>
      <ImageListItem sx={styles.roleIcons}>
        <img src={ImagePath.CoachSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const AthleteIcon = (props: any) => {
  return (
    <>
      <ImageListItem sx={styles.roleIcons}>
        <img src={ImagePath.AthleteSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const MessageIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.messageIcon}>
        <img src={ImagePath.MessageSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const SendIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.messageIcon}>
        <img src={ImagePath.SendIcon} alt="" color="white" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const PostSaveIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.saveIcon}>
        <img src={ImagePath.PostSaveSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const PostUnSaveIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.saveIcon}>
        <img src={ImagePath.PostUnSaveSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const CommentIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.saveIcon}>
        <img src={ImagePath.CommentSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const LikeIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.likeIcon}>
        <img src={ImagePath.LikeSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const NotLikeIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.likeIcon}>
        <img src={ImagePath.NotLikeSVG} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};

export const ShareIcons = () => {
  return (
    <>
      <ImageListItem sx={styles.shareIcon}>
        <img src={ImagePath.shareIcon} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
export const LikeStoryIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.likeStoryIcon}>
        <img src={ImagePath.StoryLike} alt="" />
      </ImageListItem>
    </>
  );
};
export const NotStoryLikeIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.likeStoryIcon}>
        <img src={ImagePath.StoryUnLike} alt="" />
      </ImageListItem>
    </>
  );
};
export const DeleteMsgIcon = () => {
  return (
    <>
      <ImageListItem sx={styles.likeStoryIcon}>
        <img src={ImagePath.ThreeDotIcon} alt="" loading="lazy" />
      </ImageListItem>
    </>
  );
};
