import { Box, CardMedia } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { CommonButton } from "../../components/common";
import { updateCroppedFiles } from "../../store/reducer/createPost";
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
export default function ImageCrop({ setScreen }: any, style: any) {
  const dispatch = useDispatch();
  const { uploadedFiles: file } = useSelector((state: any) => state.createPost);
  const [upImg, setUpImg] = useState<any>([]);
  const [finalFiles, setFinalFiles] = useState(file);
  const imgRef: any = useRef([]);

  const [crop, setCrop] = useState<any>();
  const [completedCrop, setCompletedCrop] = useState<any>(
    new Array(file.length)
  );

  useEffect(() => {
    if (file.length > 0) {
      file.map((item: any) => {
        return setUpImg((prev: any) => [...prev, URL.createObjectURL(item)]);
      });
    }
  }, [file]);

  const onLoad = useCallback((img: any, index: number) => {
    imgRef.current[index] = img.target;
  }, []);

  const onComplete = (c: any, index: number) => {
    let array: any = [];
    for (let i = 0; i < completedCrop.length; i++) {
      i === index ? array.push(c) : array.push(completedCrop[i]);
    }
    setCompletedCrop(array);
  };
  useEffect(() => {
    if (completedCrop.length > 0) {
      completedCrop?.map((item: any, index: number) => {
        item && cropImageNow(imgRef.current[index], item, index);
      });
    }
  }, [completedCrop]);

  const cropImageNow = (image: any, crop: any, index: number) => {
    const canvas: any = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    fetch(base64Image)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "dot.png", blob);
        setFinalFiles(
          finalFiles.map((el: any, i: number) => (index === i ? file : el))
        );
      });
  };

  const onSave = () => {
    dispatch(updateCroppedFiles(finalFiles));
    setScreen(3);
  };

  return (
    <div className="App">
      {
        <Box>
          {file?.length <= 1 ? (
            <Box>
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => onComplete(c, 0)}
              >
                <CardMedia
                  component="img"
                  loading="lazy"
                  sx={{
                    objectFit: "contain",
                    width: "100%",
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "75vh",
                    },
                  }}
                  ref={(element) => (imgRef.current[0] = element)}
                  image={upImg[0]}
                />
              </ReactCrop>
              <div className="flex items-center gap-2 justify-center ">
                <CommonButton
                  buttonText={"Save"}
                  onClicked={onSave}
                  buttonType={"button"}
                  buttonStyle={{ width: "20%" }}
                />
              </div>
            </Box>
          ) : (
            <Box>
              <Carousel
                autoPlay={false}
                cycleNavigation={false}
                swipe={false}
                navButtonsAlwaysVisible={true}
                navButtonsAlwaysInvisible={false}
                sx={{
                  height: {
                    xs: "auto",
                    sm: "auto",
                    md: "75vh",
                  },
                  width: "70vw",
                  maxWidth: 1150,
                }}
              >
                {file?.map((item: any, index: number) => (
                  <ReactCrop
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => onComplete(c, index)}
                  >
                    <CardMedia
                      component="img"
                      loading="lazy"
                      sx={{
                        objectFit: "contain",
                        width: "100%",
                        height: {
                          xs: "auto",
                          sm: "auto",
                          md: "70vh",
                        },
                      }}
                      ref={(element) => (imgRef.current[index] = element)}
                      image={upImg[index]}
                    />
                  </ReactCrop>
                ))}
              </Carousel>
              <div className="flex items-center gap-2 justify-center py-2">
                <CommonButton
                  buttonText={"Save"}
                  onClicked={onSave}
                  buttonType={"button"}
                  buttonStyle={{ width: "20%" }}
                />
              </div>
            </Box>
          )}
        </Box>
      }
    </div>
  );
}
