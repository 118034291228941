import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ImagePath } from "../../../../assets/Images";
import { RoutePath } from "../../../../routes/route-path";
import { useEffect, useState } from "react";
import { styles } from "../../../../theming/Style/Styles";

export const sidebarListStyle: any = {
  display: "flex",
  gap: "16px",
  padding: "8px",
  marginBottom: "8px",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    background:
      "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)",
  },
};

const Sidebar = () => {
  const router = useNavigate();
  const [navIndex, setNavIndex] = useState<number>(1);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === RoutePath.hypeCast) {
      setNavIndex(1);
    } else if (location.pathname === RoutePath.hypeCastSearch) {
      setNavIndex(2);
    } else if (location.pathname === RoutePath.hypeCastSavedEpisodes) {
      setNavIndex(3);
    } else if (location.pathname === RoutePath.hypeCastFollowedPodcast) {
      setNavIndex(4);
    }
  }, [location]);
  return (
    <Box sx={styles.appBarContainer}>
      <Box sx={{ width: "230px" }}>
        <img
          src={ImagePath.hypeCastLogo}
          height={"60px"}
          width={"145px"}
          style={{ objectFit: "contain" }}
          alt=""
        />
        <Box sx={{ marginTop: "10px" }}>
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 1
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => router("/hype-cast")}
          >
            <img
              src={ImagePath.hypeCastHome}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Home
            </Typography>
          </Box>
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 2
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => {
              document.getElementById("search")?.focus();
              router(RoutePath.hypeCastSearch);
            }}
          >
            <img
              src={ImagePath.hypeCastSearch}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Search
            </Typography>
          </Box>
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 3
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => router("hype-cast/saved-episodes")}
          >
            <img
              src={ImagePath.hypeCastBookmarked}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Bookmarked Episode
            </Typography>
          </Box>
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 4
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => router(RoutePath.hypeCastFollowedPodcast)}
          >
            <img
              src={ImagePath.hypeCastFollowed}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Followed Podcast
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
