import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Avatar,
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import { styles } from "../../../theming/Style/Styles";
import { PatterColor } from "../../../theming/Style/color.styles";
import { AppStringUtils } from "../../../utils";
import CommonSkeleton from "../../common/skeleton";
import { ServiceMessage } from "../../../api";
import { useDispatch } from "react-redux";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { useSelector } from "react-redux";
import { Spinner } from "../../common";
import ShareMessageDialog from "../../common/ShareMessageDialog";
type Anchor = "top" | "left" | "bottom" | "right";

export default function MobileSharePopup(props: any) {
  const { usersList, setUsersList, data, isLoader, uuid } = props;
  const { token } = useSelector((state: any) => state.rLogin.infoLogin);
  const t = AppStringUtils();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showLimitExceededDialog, setShowLimitExceededDialog] =
    React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const { postIndex } = useSelector((state: any) => state.savedPost);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleChange = (e: any) => {
    const searchText = e.target.value.toLowerCase();
    if (searchText.length === 0) {
      setFilteredData(data);
    } else {
      const filteredData = props.data.filter((item: any) => {
        const { username, first_name, last_name } = item;
        return (
          username.toLowerCase().includes(searchText) ||
          first_name.toLowerCase().includes(searchText) ||
          last_name.toLowerCase().includes(searchText)
        );
      });
      setFilteredData(filteredData);
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleClose = () => {
    props?.setShowPopup && props?.setShowPopup(false);
    props?.setIsPaused && props?.setIsPaused(false);
    props?.setIsOpenShare && props?.setIsOpenShare(false);
  };

  const apiCallForSharedPosts = (item: any) => {
    setIsLoading(true);
    const { uuid } = props?.info?.post_files[postIndex];
    let reqBody = {
      text_url_shared_media: uuid,
      to_message_user_uuid: item.map((item1: any) => item1?.user_uuid),
      message_type: "Shared_Post",
    };
    ServiceMessage.apiCallSharedProfileMessages(reqBody, token)
      .then((res: any) => {
        if (res) {
          dispatch(
            showSocialSnackbar({ message: t.postSharedMsg, type: "success" }),
          );
        }
        setIsLoading(false);
        handleClose();
        setUsersList([]);
      })
      .catch((err: any) => {
        dispatch(
          showSocialSnackbar({ message: t.wentWrongMsg, type: "error" }),
        );
        setIsLoading(false);
        handleClose();
      });
  };

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={props?.showPopup}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
          sx={{ zIndex: 99999 }}
        >
          <Box sx={styles.mobileShareBox}>
            <Box sx={styles.mobileShareBox2}>
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                {t.share}
              </Typography>
              <Box sx={styles.mobileShareCloseIcon}>
                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    fontSize: "25px",
                    color: "#1976D2",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "block" },
                padding: "5px 0",
              }}
            >
              <TextField
                className="input-plain-background"
                placeholder="Search..."
                fullWidth
                size="small"
                style={{
                  color: "white",
                  border: "1px solid #526156",
                  borderRadius: "17px ",
                }}
                InputLabelProps={{
                  style: { ...styles.inputLabel, color: "white" },
                }}
                onChange={handleChange}
              />
            </Box>
            <Box sx={styles.mobilePostSharePopup} role="presentation">
              {filteredData?.length === 0 ? (
                <Box sx={styles.postShareRecordStyle}>{t.noUserFound}</Box>
              ) : (
                <>
                  {(isLoader ? Array.from(new Array(20)) : filteredData)?.map(
                    (item: any, index: number) => {
                      return (
                        <Box
                          className="hoverConver"
                          sx={{
                            ...styles.popoverPost,
                            padding: 0,
                            mx: 0,
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <ListItem
                            sx={{
                              padding: "0px 0px",
                            }}
                          >
                            {isLoader ? (
                              <CommonSkeleton
                                variant="rectangulars"
                                width="100%"
                                height="50px"
                                styles={{ borderRadius: "20px" }}
                              />
                            ) : (
                              <ListItemButton
                                sx={{ color: PatterColor.white }}
                                onClick={() => {
                                  const userUuidArray = usersList.map(
                                    (item: any) => item.user_uuid,
                                  );
                                  const isUserSelected = userUuidArray.includes(
                                    item.user_uuid,
                                  );
                                  if (isUserSelected) {
                                    setUsersList(
                                      usersList.filter(
                                        (el: any) =>
                                          el.user_uuid !== item.user_uuid,
                                      ),
                                    );
                                  } else if (usersList.length < 5) {
                                    setUsersList([...usersList, item]);
                                  } else {
                                    setShowLimitExceededDialog(true);
                                    props?.setShowPopup(false);
                                  }
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                  }}
                                  src={item.profile_image}
                                  imgProps={{ loading: "lazy" }}
                                />
                                <ListItemText
                                  primary={
                                    item.first_name + " " + item.last_name
                                  }
                                />
                                <ListItemIcon
                                  sx={{
                                    textAlign: "right",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Checkbox
                                    sx={{
                                      color: PatterColor.white,
                                    }}
                                    edge="end"
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    checked={usersList
                                      ?.map((item: any) => item?.user_uuid)
                                      .includes(item?.user_uuid)}
                                  />
                                </ListItemIcon>
                              </ListItemButton>
                            )}
                          </ListItem>
                        </Box>
                      );
                    },
                  )}
                  {isLoading ? (
                    <>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Spinner isShow={isLoading} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        sx={{
                          ...styles.popoverPost,
                          color: PatterColor.white,
                          my: 2,
                        }}
                        // disabled={usersList?.length === 0 ? true : false}
                        onClick={() => {
                          usersList.length >= 1 &&
                            apiCallForSharedPosts(usersList);
                        }}
                      >
                        {t.send}
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </SwipeableDrawer>
        <ShareMessageDialog
          showLimitExceededDialog={showLimitExceededDialog}
          setShowLimitExceededDialog={setShowLimitExceededDialog}
        />
      </React.Fragment>
    </div>
  );
}
