import { createSlice } from "@reduxjs/toolkit";
interface PlayerReducerProps {
  isMuted: boolean | true;
}
const ReelsSlice = createSlice({
  name: "playerSlice",
  initialState: {
    isMuted: true,
  },
  reducers: {
    actionMute(state: PlayerReducerProps) {
      state.isMuted = !state.isMuted;
    },
  },
});

export const { actionMute } = ReelsSlice.actions;

export default ReelsSlice.reducer;
