export enum FOLLOW_STATUS {
  FOLLOW = "follow",
  UN_FOLLOW = "unfollow",
  REQUESTED = "requested",
  FOLLOWING = "following",
}

export enum REQUESTED_STATUS {
  RECEIVER = "receiver",
  BOTH = "both",
  SENDER = "sender",
}

export const getFollowButtonLabel = (
  requestedStatus: string | null,
  followStatus: string
) => {
  switch (requestedStatus) {
    case null:
      return followStatus === FOLLOW_STATUS.FOLLOW
        ? FOLLOW_STATUS.UN_FOLLOW
        : FOLLOW_STATUS.FOLLOW;
    case REQUESTED_STATUS.RECEIVER:
    case REQUESTED_STATUS.BOTH:
      return followStatus === FOLLOW_STATUS.UN_FOLLOW
        ? FOLLOW_STATUS.REQUESTED
        : FOLLOW_STATUS.FOLLOW;
    case REQUESTED_STATUS.SENDER:
      return followStatus === FOLLOW_STATUS.FOLLOW
        ? FOLLOW_STATUS.UN_FOLLOW
        : FOLLOW_STATUS.FOLLOW;
    default:
      return FOLLOW_STATUS.FOLLOW;
  }
};
