import { useNavigate } from "react-router-dom";

interface navigationUtilProps {
  pathName: string;
  isReplace?: boolean | false;
}

export const NavigationUtils = () => {
  const navigation = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function navigate(_props: navigationUtilProps) {
    navigation(_props.pathName, { replace: _props.isReplace });
  }
  return { navigate };
};
