import React from "react";

const CreatePostIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      id={"app-bar-icons"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.12 6.22476L10.8675 2.17476C10.32 2.09976 9.70505 2.06226 9.03005 2.06226C4.31255 2.06226 2.73755 3.80226 2.73755 8.99976C2.73755 14.2048 4.31255 15.9373 9.03005 15.9373C13.755 15.9373 15.33 14.2048 15.33 8.99976C15.33 7.93476 15.2625 7.01226 15.12 6.22476Z"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4504 2.12427V4.12002C10.4504 5.51352 11.5799 6.64227 12.9734 6.64227H15.1867"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7343 9.73523H7.05933"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89746 11.5728V7.89783"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreatePostIcon;
