import { ExploreOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import MessageIcon from "../../../../assets/Icons/MessageIcon";
import { styles } from "../../../../theming/Style/Styles";
import { PatterColor } from "../../../../theming/Style/color.styles";
import { StyledBadgeNotification } from "../../LabelBottomNavigation";
import { RoutePath } from "../../../../routes/route-path";

export default function HamburgerMenu(props: any) {
  const { count, btnNavClicked } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { md: "none" } }}>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "white", mt: -0.5 }}
      >
        <StyledBadgeNotification
          badgeContent={open ? 0 : count?.message_count}
          color="warning"
          overlap={"circular"}
        >
          <MenuIcon />
        </StyledBadgeNotification>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            btnNavClicked(RoutePath.explore);
            handleClose();
          }}
          sx={styles.menuItem}
        >
          <ExploreOutlined
            sx={{
              color: PatterColor.white,
            }}
          />
          <>Explore</>
        </MenuItem>
        <MenuItem
          onClick={() => {
            btnNavClicked(RoutePath.messageGeneral);
            handleClose();
          }}
          sx={styles.menuItem}
        >
          <StyledBadgeNotification
            badgeContent={count?.message_count}
            color="warning"
            overlap={"circular"}
          >
            <MessageIcon />
          </StyledBadgeNotification>
          <>Chat</>
        </MenuItem>
      </Menu>
    </Box>
  );
}
