import { Close } from "@mui/icons-material";
import { Container, Dialog, DialogTitle, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import { styles } from "../../../theming/Style/Styles";
import DeletePost from "./DeletePost";
import { LeftArrow, RightArrow } from "./arrows";
import UserData from "./components/UserData";
import MobileDeletePopup from "./MobileDeletePopup";
import Loader from "../../common/loader";
import { useLocation } from "react-router-dom";

export default function UserPostPopup(props: any) {
  const {
    showPopup,
    setShowPopup,
    params,
    itemCount,
    pageNo,
    userUid,
    hasNextPage = false,
    fetchMoreData,
  } = props;

  let [pageSize, pageNumber] = [20, 1];
  const [commentList, setCommentList] = useState({
    commentData: new Array(),
    hasNextPageCommentList: undefined,
  });
  const location = useLocation();
  const [curPage, setCurPage] = useState<number>(params?.page);
  const [info, setInfo] = useState<any>(props?.info);
  const [showDeletePostPopup, setShowDeletePostPopup] = useState(false);
  const [isEdit1, setIsEdit1] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMobileDeletePostPopup, setShowMobileDeletePostPopup] =
    useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [postData, setPostData] = useState({
    isLike: props?.info?.is_like,
    likesCount: props?.info?.likes_count,
    isSaved: props?.info.is_saved,
  });

  const { token, user_uuid } = useSelector(
    (state: any) => state?.rLogin?.infoLogin,
  );

  const resetCommentList = async () => {
    await setCommentList({
      commentData: new Array(),
      hasNextPageCommentList: undefined,
    });
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    resetCommentList();
    setInfo([]);
    pageNumber = 1;
  };

  const apiCallGetCommentByPostId = (
    pageNumber: number,
    postId: string,
    isCommentAdded = false,
  ) => {
    setIsLoader(true);
    ServiceDashBoard.apiCallGetCommentOnPost(
      pageNumber,
      pageSize,
      postId,
      token,
    )
      .then((res: any) => {
        setCommentList({
          commentData: isCommentAdded
            ? [...commentList?.commentData, ...res.data]
            : res.data,
          hasNextPageCommentList: res.links.next,
        });
        setIsLoader(false);
      })
      .catch((err) => {
        setTimeout(() => {}, 3000);
        setIsLoader(false);
      });
  };

  const apiCallGetPostById = (uuid: any) => {
    setLoading(true);
    ServiceDashBoard.apiCallGetPostById(
      { post_uuid: uuid, post_status: "gallery" },
      token,
    )
      .then((res: any) => {
        if (res) {
          setInfo(res?.data);
          setPostData({
            isLike: res?.data?.is_like,
            likesCount: res?.data?.likes_count,
            isSaved: res?.data.is_saved,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getPostDataById = (uuid: any) => {
    setLoading(true);
    props?.data?.filter((item: any) => {
      if (item?.uuid === uuid) {
        setInfo(item);
        setPostData({
          isLike: item?.is_like,
          likesCount: item?.likes_count,
          isSaved: item?.is_saved,
        });
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    !location?.pathname?.includes("explore-search") &&
      props?.info?.uuid &&
      showPopup &&
      apiCallGetPostById(props?.info?.uuid);
    location?.pathname?.includes("explore-search") &&
      getPostDataById(props?.info?.uuid);
    props?.info?.uuid &&
      showPopup &&
      apiCallGetCommentByPostId(1, props?.info?.uuid);
  }, [props?.info?.uuid, showPopup]);

  return (
    <Dialog open={showPopup} onClose={handleClosePopup} maxWidth={"md"}>
      {loading && <Loader />}
      <Container maxWidth="lg" sx={{ ...styles.dialogBox }}>
        <DialogTitle sx={{ ...styles.textInformation3, fontWeight: "bold" }}>
          <IconButton
            aria-label="close"
            sx={styles.closeIcon}
            onClick={handleClosePopup}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <ScrollMenu
          LeftArrow={
            <LeftArrow
              curPage={props.index}
              setCurPage={setCurPage}
              disabled={props.index === 0}
              resetCommentList={() => props?.setIndex(props?.index - 1)}
            />
          }
          RightArrow={
            <RightArrow
              curPage={props.index}
              setCurPage={setCurPage}
              disabled={props.index === itemCount - 1 && !hasNextPage}
              itemCount={itemCount}
              hasNextPage={hasNextPage}
              fetchMoreData={fetchMoreData}
              resetCommentList={() => props?.setIndex(props?.index + 1)}
            />
          }
        >
          <UserData
            {...props}
            isModalOpen={showPopup}
            setIsModalOpen={setShowPopup}
            postId={info?.uuid}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setIsEdit1={setIsEdit1}
            data={props?.data}
            setData={props?.setData}
            info={info}
            setInfo={setInfo}
            postData={postData}
            setPostData={setPostData}
            apiCallGetCommentByPostId={apiCallGetCommentByPostId}
            commentList={commentList}
            setShowDeletePostPopup={
              document?.body?.clientWidth > 900
                ? setShowDeletePostPopup
                : setShowMobileDeletePostPopup
            }
            isLoader={false}
          />
        </ScrollMenu>
        <DeletePost
          open={showDeletePostPopup}
          setOpen={setShowDeletePostPopup}
          uuid={props?.info?.uuid}
          index={props?.index}
          data={props?.data}
          setData={props?.setData}
          setShowPopup={setShowPopup}
        />
        <MobileDeletePopup
          open={showMobileDeletePostPopup}
          setOpen={setShowMobileDeletePostPopup}
          uuid={props?.info?.uuid}
          index={props?.index}
          data={props?.data}
          setData={props?.setData}
          setShowPopup={setShowPopup}
        />
      </Container>
    </Dialog>
  );
}
