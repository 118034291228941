import { createSlice } from "@reduxjs/toolkit";

interface languageProps {
  homeData: {};
  channelData: [];
}

const initialStates: languageProps = {
  homeData: {},
  channelData: [],
};

const hypeCast = createSlice({
  name: "hypeCast",
  initialState: initialStates,
  reducers: {
    resetHypeCastHomeData: () => initialStates,
    updateHypeCastHomeData(state: any, action: any) {
      state.homeData = action.payload;
    },
    updateHypeCastHomeChannelData(state: any, action: any) {
      state.channelData = action.payload;
    },
    addRecentlyPlayed(state: any, action: any) {
      if (Object.keys(state.homeData).length > 0) {
        let episode = undefined;
        const data = state.homeData.recently_played?.filter((item: any) => {
          if (item.uuid === action.payload.uuid) {
            episode = item;
            return false;
          } else {
            return true;
          }
        });

        const finalData = [episode ? episode : action.payload, ...data];

        state.homeData = { ...state.homeData, recently_played: finalData };
      }
    },
  },
});

export const {
  resetHypeCastHomeData,
  updateHypeCastHomeData,
  addRecentlyPlayed,
  updateHypeCastHomeChannelData,
} = hypeCast.actions;
export default hypeCast.reducer;
