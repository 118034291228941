import { Send } from "@mui/icons-material";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../api";
import hypeImage from "../../../assets/Images/hype1.jpg";
import { Spinner } from "../../../components/common";
import DialogCommon from "../../../components/common/dialog-common";
import Loader from "../../../components/common/loader";
import { styles } from "../../../theming/Style/Styles";
import { AppStringUtils } from "../../../utils";
import { Typography } from "@mui/joy";
import { PostUserInfo } from "../../../components/dashboard/post-card";
import { LikeIcon, NotLikeIcon } from "../../../components/common/icons";
import { getEmojiFromUnicode } from "../../../components/dashboard/post-card/comment-popup";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonSkeleton from "../../../components/common/skeleton";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "50%",
    height: "550px",
    backgroundImage: `url(${hypeImage})`,
    overflow: "hidden",
    borderRadius: "20px",
  },
  drawerHeader: {
    margin: "30px 10px",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    cursor: "pointer",
  },
}));

function removeAtNamePrefix(input: string) {
  return input.replace(/^@\w+\s*/, "");
}

const CommentPopup = ({
  uuid,
  isOpen,
  setIsOpen,
  handleCommentCount,
  commentCount,
  setCommentCount,
  index,
  postData,
  setPostData,
}: any) => {
  const classes = useStyles();
  const { token } = useSelector((state: any) => state.rLogin.infoLogin);
  const t = AppStringUtils();
  const [commentList, setCommentList] = useState({
    commentData: new Array(),
    hasNextPageCommentList: undefined,
  });
  const commentData = commentList.commentData;
  const hasNextPageCommentList = commentList.hasNextPageCommentList;
  const [loading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  let [pageSize, pageNumber] = [20, 1];
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const [commentUuuid, setCommentUuid] = useState("");
  const [isReply, setIsReply] = useState(false);
  const inputRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const addComment = (postId: any, reqBody: any) => {
    ServiceDashBoard.apiCallPostAddComment(postId, reqBody, token)
      .then((res: any) => {
        setLoading(false);
        inputRef.current.value = "";
        setCommentCount(
          commentCount?.map((item: number, i: number) =>
            i === index ? item + 1 : item,
          ),
        );
        setCommentList({
          commentData: new Array(),
          hasNextPageCommentList: undefined,
        });
        apiCallGetCommentByPostId(pageNumber, uuid);
        handleCommentCount(true);
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {
        setIsInputDisabled(false);
      });
  };

  const replyOnComment = (commentData: any) => {
    setIsReply(true);
    document.getElementById("comment-input")?.focus();
    inputRef.current.value = "@" + commentData.commented_by_data.username + " ";
    setCommentUuid(commentData?.uuid);
  };
  const commentLikeUnlike = (commentId: any) => {
    ServiceDashBoard.apiCallPostCommentLikeUnlike(commentId, token)
      .then((res: any) => {
        apiCallGetCommentByPostId(pageNumber, uuid);
      })
      .catch((err) => {});
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (!isInputDisabled && inputRef.current.value.trim() !== "") {
        setIsInputDisabled(true);
        let reqBody = {
          comment: removeAtNamePrefix(inputRef.current.value),
          comment_uuid: commentUuuid,
        };
        addComment(uuid, reqBody);
      }
    }
  };

  const commentONPost = async (event: any) => {
    event.preventDefault();
    const comment = inputRef.current.value;

    if (comment.trim() !== "" && !isInputDisabled) {
      setIsInputDisabled(true);

      if (isReply) {
        let reqBody = {
          comment: comment,
          comment_uuid: commentUuuid,
        };
        addComment(uuid, reqBody);
      } else {
        let reqBody = {
          comment: comment,
        };
        addComment(uuid, reqBody);
      }
    }
  };

  const handleClose = () => {
    inputRef.current.value = "";
    setIsOpen(false);
    setCommentList({
      commentData: new Array(),
      hasNextPageCommentList: undefined,
    });
  };

  const fetchMoreData = () => {
    hasNextPageCommentList && setCurrentPage(currentPage + 1);
  };

  const apiCallGetCommentByPostId = (pageNumber: number, postId: string) => {
    setIsLoader(true);
    ServiceDashBoard.apiCallGetCommentOnPost(pageNumber, 20, postId, token)
      .then((res: any) => {
        setIsLoader(false);
        setPostData({
          ...postData,
          commentCount: res?.count,
        });
        setCommentList({
          commentData:
            hasNextPageCommentList && currentPage !== 1
              ? [...commentList.commentData, ...res.data]
              : [...res.data],
          hasNextPageCommentList: res.links.next,
        });
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };

  useEffect(() => {
    setCommentList({
      commentData: new Array(),
      hasNextPageCommentList: undefined,
    });

    !hasNextPageCommentList && setCurrentPage(1);
    if (isOpen) {
      apiCallGetCommentByPostId(currentPage, uuid);
    }
  }, [isOpen, currentPage]);

  const CommentBox = React.memo((commentData: any) => {
    const [valuesLike, setValuesLike] = useState({
      isLike: commentData?.is_like,
    });
    const handleClickLikeCountComment = async (commentId: any) => {
      await commentLikeUnlike(commentId);
      await setValuesLike({
        ...valuesLike,
        isLike: !valuesLike.isLike,
      });
    };
    return (
      <Box sx={{ my: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {isLoader ? (
              <Box display={"flex"} alignItems={"center"} gap="5px">
                <CommonSkeleton
                  variant="circle"
                  height="40px"
                  width="40px"
                  styles={{ borderRadius: "50px" }}
                />
                <CommonSkeleton variant="text" styles={{ width: "150px" }} />
              </Box>
            ) : (
              <PostUserInfo
                postedByData={commentData.commented_by_data}
                profileImage={true}
                show={false}
              />
            )}
          </Box>
          <Box sx={{ pt: 1.5, ...styles.pointer }}>
            {isLoader ? (
              <CommonSkeleton
                variant="circle"
                width="30px"
                height="30px"
                styles={{ borderRadius: "50px" }}
              />
            ) : (
              <IconButton
                onClick={() => handleClickLikeCountComment(commentData.uuid)}
              >
                {valuesLike.isLike ? <LikeIcon /> : <NotLikeIcon />}
              </IconButton>
            )}
          </Box>
        </Box>
        {isLoader ? (
          <CommonSkeleton variant="text" width="100%" height="15px" />
        ) : (
          <Box>
            <Typography sx={styles.textInformation2}>
              {/* {commentData?.comment} */}
              {commentData?.comment.includes("U+")
                ? getEmojiFromUnicode(commentData?.comment)
                : commentData?.comment}
            </Typography>
          </Box>
        )}
        {!commentData.subComment ? (
          <Box>
            <Typography
              sx={{
                ...styles.textInformation1,
                fontSize: "12px",
                justifyContent: "space-between",
              }}
            >
              {isLoader ? (
                <>
                  <CommonSkeleton variant="text" width="100px" height="15px" />
                  <CommonSkeleton variant="text" width="100px" height="15px" />
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ mr: 1 }}>{commentData?.like_count} Like</Box>
                    <Box
                      sx={{ mr: 1, ...styles.pointer }}
                      onClick={() => replyOnComment(commentData)}
                    >
                      {t.reply}
                    </Box>
                  </Box>

                  <Box sx={{ mr: 1 }}>{commentData?.commented_at}</Box>
                </>
              )}
            </Typography>
          </Box>
        ) : (
          <></>
        )}

        {commentData?.replied_on_comment_data &&
          commentData?.replied_on_comment_data.length !== 0 &&
          commentData.replied_on_comment_data.map((item: any, index: any) => (
            <Box sx={{ ml: 3 }}>
              <CommentBox {...item} subComment={true} />
            </Box>
          ))}
      </Box>
    );
  });

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{ className: classes.drawer }}
    >
      <Container sx={{ backgroundColor: "rgb(40,41,86)" }}>
        {loading ? <Loader /> : ""}
        <Box sx={{ p: 2 }}>
          <DialogCommon dialogTitle={"Comment"} setIsOpen={setIsOpen} />
        </Box>
        <Divider color="grey" sx={styles.autoHW} />
        <DialogContent sx={{ margin: "auto" }}>
          <Box sx={styles.ReelsCommentPopupContainer}>
            <Box id="comments-container">
              {commentList?.commentData?.length === 0 && !isLoader ? (
                <Box sx={styles.commentListBox}>
                  <Typography sx={styles.textInformation2}>
                    {t.noCommentsYet}
                  </Typography>
                </Box>
              ) : (
                <Box sx={styles.commentPopupScrollable2} id="scrollableDiv">
                  <InfiniteScroll
                    dataLength={commentData.length}
                    next={fetchMoreData}
                    hasMore={hasNextPageCommentList ? true : false}
                    loader={isLoader && pageNumber > 1 && <Loader />}
                    scrollableTarget="scrollableDiv"
                  >
                    {(isLoader ? Array.from(new Array(10)) : commentData).map(
                      (item: any, index: any) => (
                        <CommentBox {...item} key={index} />
                      ),
                    )}
                  </InfiniteScroll>
                  <Box>
                    {isLoader && pageNumber > 1 && (
                      <Spinner isShow={isLoader} />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Divider
            color="grey"
            sx={{
              ...styles.autoHW,
              my: 1,
            }}
          />
          <Box sx={{ marginTop: "20px", display: "flex" }}>
            <input
              className="input-plain-background6"
              id="comment-input"
              ref={inputRef}
              placeholder="Comments"
              onKeyDown={handleKeyDown}
              type="text"
              disabled={isInputDisabled}
            />
            <IconButton
              disabled={isInputDisabled}
              onClick={commentONPost}
              edge="end"
              sx={{ ...styles.colorWhite, marginLeft: "-40px" }}
            >
              <Send />
            </IconButton>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default React.memo(CommentPopup);
