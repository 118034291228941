import ImgPostTest from "./post.png";
import HypeLogo from "./hype-logo.svg";
import HypeCastLogo from "./hype-cast-logo.svg";
import AppStoreSVG from "../SVG/appstore.svg";
import PlayStoreSVG from "../SVG/playstore.svg";
import CoachSVG from "../SVG/coach.svg";
import CoachPNG from "../Images/coach.png";
import AthleteSVG from "../SVG/athlete.svg";
import MessageSVG from "../SVG/message-icon.svg";
import PostSaveSVG from "../SVG/post-save.svg";
import PostUnSaveSVG from "../SVG/post-unsave.svg";
import CommentSVG from "../SVG/comment.svg";
import LikeSVG from "../SVG/like.svg";
import NotLikeSVG from "../SVG/not-like.svg";
import shareIcon from "../SVG/share.svg";
import StoryLike from "../SVG/storyLike.svg";
import StoryUnLike from "../SVG/storyUnLike.svg";
import tenSecondBackward from "../SVG/tenSecondBackward.svg";
import tenSecondForward from "../SVG/tenSecondForward.svg";
import play from "../SVG/play.svg";
import hypeCastLogo from "../SVG/hypeCastLogo.svg";
import hypeCastHome from "../SVG/hypeCastHome.svg";
import hypeCastSearch from "../SVG/hypeCastSearch.svg";
import hypeCastBookmarked from "../SVG/hypeCastBookmarked.svg";
import hypeCastFollowed from "../SVG/hypeCastFollowed.svg";
import hypeCastPeople from "../SVG/hypeCastPeople.svg";
import hypeCastTimer from "../SVG/hypeCastTimer.svg";
import successfulIcon from "../SVG/successful.svg";
import hypeSocial from "../SVG/hypeSocial.svg";
import podcastPlay from "../SVG/podcastPlay.svg";
import ThreeDotIcon from "../SVG/three-dots.svg";
import SendIcon from "../SVG/send-icon.svg";
import addIcon from "../SVG/add-icon.svg";
import organizationIcon from "../SVG/organization-icon.svg";
import sportsIcon from "../SVG/sports-icon.svg";

export const ImagePath = {
  ImgPostTest,
  HypeLogo,
  AppStoreSVG,
  PlayStoreSVG,
  CoachSVG,
  AthleteSVG,
  MessageSVG,
  PostSaveSVG,
  PostUnSaveSVG,
  CommentSVG,
  LikeSVG,
  CoachPNG,
  NotLikeSVG,
  shareIcon,
  StoryLike,
  StoryUnLike,
  tenSecondBackward,
  tenSecondForward,
  play,
  hypeCastLogo,
  hypeCastHome,
  hypeCastSearch,
  hypeCastBookmarked,
  hypeCastFollowed,
  hypeCastPeople,
  hypeCastTimer,
  successfulIcon,
  hypeSocial,
  podcastPlay,
  ThreeDotIcon,
  SendIcon,
  addIcon,
  organizationIcon,
  sportsIcon,
};
