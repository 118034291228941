import React from "react";

const HypeCastIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={"app-bar-icons"}
      {...props}
    >
      <path
        d="M4.77368 10.2586C4.77368 12.6069 6.67718 14.5104 9.02543 14.5104C11.3744 14.5104 13.2779 12.6069 13.2779 10.2586"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2779 7.81707V6.12657C13.2779 3.77832 11.3744 1.87482 9.02543 1.87482C6.67718 1.87482 4.77368 3.77832 4.77368 6.12657V7.81707"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.02539 16.1246V14.5106"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.67505 10.2586H14.376"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HypeCastIcon;
