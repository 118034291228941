import { Send } from "@mui/icons-material";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../../api";
import { styles } from "../../../../theming/Style/Styles";
import { PatterColor } from "../../../../theming/Style/color.styles";
import { AppStringUtils } from "../../../../utils";
import { Plaintextinput } from "../../../common";
import Loader from "../../../common/loader";
import PostIcon from "../post-icons";
import PostImage from "../post-image";
import PostUserInfo from "../post-user-info";
import CommentBox from "./CommentBox";

const UserData = (props: any) => {
  const t = AppStringUtils();
  const { token } = useSelector((state: any) => state.rLogin.infoLogin);
  const {
    post_tags,
    post_files,
    post_type,
    posted_by_data,
    uuid,
    readable_time,
    is_comment_allowed,
    is_following,
    post_caption,
    post_location,
  } = props?.info;

  const [page, setPage] = useState(1);
  const [commentData, setCommentData] = useState(
    props?.commentList?.commentData,
  );
  useEffect(() => {
    setCommentData(props?.commentList?.commentData);
  }, [props?.commentList?.commentData]);

  const hasNextPage =
    props?.commentList?.hasNextPageCommentList !== null ? true : false;
  const [isLoader, setIsLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const scrollContainerRef = useRef<any>();
  const formik = useFormik({
    initialValues: {
      comment: "",
      comment_uuid: "",
      username: "",
    },
    onSubmit: async (values: any) => {
      if (
        isLoader ||
        values.comment.length === 0 ||
        values.comment.includes("\u3000") ||
        values.comment.trim().length === 0
      ) {
        return;
      }
      setIsDisabled(true);
      let infoValue = { ...values };
      delete infoValue.postId;
      if (!infoValue.comment_uuid) {
        delete infoValue.comment_uuid;
      }
      if (!infoValue.comment.includes(infoValue.username)) {
        delete infoValue.comment_uuid;
      }
      if (infoValue.comment.includes(infoValue.username)) {
        infoValue.comment = infoValue.comment.replace(infoValue.username, "");
      }
      delete infoValue.username;
      // addComment(uuid, infoValue);
      try {
        await addComment(uuid, infoValue);
      } catch (error) {
        console.error("Error submitting comment:", error);
      } finally {
        setIsLoader(false);
        formik.resetForm();
      }
      return;
    },
  });

  const fetchMoreData = () => {
    hasNextPage &&
      props?.apiCallGetCommentByPostId(page + 1, props?.postId, true);
    setPage(page + 1);
  };

  const addComment = (postId: any, reqBody: any) => {
    if (!isDisabled && reqBody.comment) {
      ServiceDashBoard.apiCallPostAddComment(postId, reqBody, token)
        .then((res: any) => {
          formik.resetForm();
          props?.apiCallGetCommentByPostId(page, props?.postId);
          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
        });
    }
    return;
  };
  const replyOnComment = (commentData: any) => {
    document.getElementById("comment-input")?.focus();
    formik.setFieldValue("comment_uuid", commentData.uuid);
    formik.setFieldValue("comment_content", commentData.content);
    formik.setFieldValue(
      "username",
      "@" + commentData.commented_by_data.username + " ",
    );
    formik.setFieldValue(
      "comment",
      "@" + commentData.commented_by_data.username + " ",
    );
  };
  const handleClickEdit = () => {
    formik.handleSubmit();
  };
  const textFieldValue1 = {
    inputType: "text",
    placeholder: t.addComment,
    name: "comment",
    value: formik.values?.comment,
    handleChange: formik.handleChange,
    handleBlur: formik.handleBlur,
    icon: <Send />,
    touchedValue: formik.touched.comment,
    errorsValue: formik.errors.comment,
    handleClickEdit: handleClickEdit,
  };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={7} lg={7} sm={7} md={7}>
          <PostImage
            postFiles={post_files}
            postType={post_type}
            popup={true}
            setIsModalOpen={props?.setIsModalOpen}
            isModalOpen={props?.isModalOpen}
          />
          <Box sx={{ ...styles.borderCard1 }}></Box>
        </Grid>
        <Grid item xs={5} lg={5} sm={5} md={5}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <PostUserInfo
                postedByData={posted_by_data}
                postLocation={post_location}
                postCaption={post_caption}
                profileImage={true}
                show={true}
                info={props?.info}
                setInfo={props?.setInfo}
                setShowDeletePostPopup={props?.setShowDeletePostPopup}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                uuid={props?.postId}
                data={props?.data}
                setData={props?.setData}
                index={props?.index}
                isFollow={is_following}
                isModalOpen={props?.isModalOpen}
                setIsModalOpen={props?.setIssModalOpen}
                setIsEdit1={props?.setIsEdit1}
              />
            </Box>
            <Divider
              color="grey"
              sx={{
                ...styles.autoHW,
                my: 1,
              }}
            />
            <Box sx={styles.commentListBox} ref={scrollContainerRef}>
              {is_comment_allowed && (
                <>
                  <Box id="comments-container">
                    {commentData?.length === 0 ? (
                      <Box sx={styles.commentListBox}>
                        <Typography
                          sx={{
                            ...styles.textInformation2,
                          }}
                        >
                          {t.noCommentsYet}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        id="scrollableDiv"
                        sx={styles.commentPopupScrollable}
                      >
                        <InfiniteScroll
                          dataLength={commentData.length}
                          next={fetchMoreData}
                          hasMore={hasNextPage && true}
                          loader={
                            (props?.isLoader || isLoader) &&
                            page > 1 && <Loader />
                          }
                          scrollableTarget="scrollableDiv"
                        >
                          {commentData &&
                            commentData.map((item: any, index: any) => (
                              <CommentBox
                                {...item}
                                key={item.uuid}
                                replyOnComment={replyOnComment}
                                apiCallGetCommentByPostId={
                                  props?.apiCallGetCommentByPostId
                                }
                                pageNumber={page}
                                postUuid={uuid}
                              />
                            ))}
                        </InfiniteScroll>
                      </Box>
                    )}
                  </Box>
                  {(props?.isLoader || isLoader) && <Loader />}
                </>
              )}
            </Box>
            <Divider
              color="grey"
              sx={{
                ...styles.autoHW,
                my: 1,
              }}
            />
            <PostIcon
              info={props?.info}
              isComment={false}
              postData={props?.postData}
              setPostData={props?.setPostData}
            />
            <Box
              sx={{
                ...styles.textInformation8,
                fontSize: "12px",
                color: PatterColor.thirdGray,
                marginTop: "0px",
              }}
            >
              {readable_time}
            </Box>
            {is_comment_allowed && (
              <Box>
                <Divider
                  color="grey"
                  sx={{
                    ...styles.autoHW,
                    my: 1,
                  }}
                />
                <form onSubmit={formik.handleSubmit}>
                  <Plaintextinput
                    textFieldValue={textFieldValue1}
                    borderCurved={true}
                  />
                </form>
              </Box>
            )}
            {!is_comment_allowed && <Box sx={{ py: 6 }}></Box>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserData;
