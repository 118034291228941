import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { List, ListItem, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../routes/route-path";
import { sidebarListStyle } from "./Sidebar";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "../../../../theming/Style/Styles";
import { ImagePath } from "../../../../assets/Images";

type Anchor = "top" | "left" | "bottom" | "right";

export default function SidebarDrawer() {
  const router = useNavigate();
  const [state, setState] = React.useState(false);
  const [navIndex, setNavIndex] = React.useState<number>(1);
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname === RoutePath.hypeCast) {
      setNavIndex(1);
    } else if (location.pathname === RoutePath.hypeCastSearch) {
      setNavIndex(2);
    } else if (location.pathname === RoutePath.hypeCastSavedEpisodes) {
      setNavIndex(3);
    } else if (location.pathname === RoutePath.hypeCastFollowedPodcast) {
      setNavIndex(4);
    }
  }, [location]);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState(open);
    };
  const list = (anchor: Anchor) => (
    <Box sx={styles.sideBarDrawer} role="presentation">
      <Box sx={{ width: "100%", textAlign: "right", cursor: "pointer" }}>
        <CloseIcon
          sx={{ color: "white" }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        />
      </Box>
      <List sx={{ color: "white" }}>
        <ListItem
          disablePadding
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 1
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => router("/hype-cast")}
          >
            <img
              src={ImagePath.hypeCastHome}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Home
            </Typography>
          </Box>
        </ListItem>
        <ListItem
          disablePadding
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 2
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => {
              document.getElementById("search")?.focus();
              router(RoutePath.hypeCastSearch);
            }}
          >
            <img
              src={ImagePath.hypeCastSearch}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Search
            </Typography>
          </Box>
        </ListItem>
        <ListItem
          disablePadding
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 3
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => router("hype-cast/saved-episodes")}
          >
            <img
              src={ImagePath.hypeCastBookmarked}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Bookmarked Episode
            </Typography>
          </Box>
        </ListItem>
        <ListItem
          disablePadding
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              ...sidebarListStyle,
              background: `${
                navIndex == 4
                  ? "linear-gradient(91.25deg, #6C83F9 4.15%, rgba(171, 53, 237, 0) 99.31%)"
                  : ""
              }`,
            }}
            onClick={() => router(RoutePath.hypeCastFollowedPodcast)}
          >
            <img
              src={ImagePath.hypeCastFollowed}
              height={24}
              width={24}
              style={{ objectFit: "contain" }}
              alt=""
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Followed Podcast
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Box onClick={toggleDrawer(true)}>
          <MenuIcon sx={{ color: "white", cursor: "pointer" }} />
        </Box>
        <Drawer
          anchor={"left"}
          open={state}
          onClose={toggleDrawer(false)}
          sx={{ display: { md: "none" }, width: "100%" }}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
