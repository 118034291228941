import { AppBar, Box } from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";
import { RoutePath } from "../../../routes/route-path";
import { styles } from "../../../theming/Style/Styles";
import DesktopAppBar from "./components/DesktopAppBar";
import MobileAppBar from "./components/MobileAppBar";

export function HeaderDashboard(props: any) {
  const location = useLocation();

  const getIsPathMatch = (pathname: string) => {
    const match = matchPath(pathname, location.pathname);
    return match ? true : false;
  };

  const noAppBarRoutesMobile = [
    RoutePath.reels,
    RoutePath.profile,
    RoutePath.chatByUserId,
    RoutePath.createPost,
    RoutePath.editPost,
    RoutePath.profileInfo,
  ];
  const noAppBarRoutesDesktop = [RoutePath.profileInfo];

  const notOnMobile = noAppBarRoutesMobile.some((item: any) =>
    getIsPathMatch(item),
  );
  const notOnDesktop = noAppBarRoutesDesktop.some((item: any) =>
    getIsPathMatch(item),
  );

  return (
    <>
      <AppBar
        sx={{
          ...styles.logobar2,
          py: 1,
          px: 1,
          position: "fixed",
          top: 0,
          display: {
            xs: notOnMobile ? "none" : "block",
            md: notOnDesktop ? "none" : "block",
          },
        }}
        id={"app-bar"}
      >
        <Box sx={{ display: { xs: "block", md: "none", width: "100%" } }}>
          <MobileAppBar {...props} />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block", width: "100%" } }}>
          <DesktopAppBar {...props} />
        </Box>
      </AppBar>
    </>
  );
}
