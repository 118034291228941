import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ServiceDashBoard } from "../../../../api";
import { styles } from "../../../../theming/Style/Styles";
import { AppStringUtils } from "../../../../utils";
import { LikeIcon, NotLikeIcon } from "../../../common/icons";
import CommonSkeleton from "../../../common/skeleton";
import { getEmojiFromUnicode } from "../comment-popup";
import PostUserInfo from "../post-user-info";

const CommentBox = (props: any) => {
  const t = AppStringUtils();
  const { token } = useSelector((state: any) => state.rLogin.infoLogin);
  const [commentLikeLoading, setCommentLikeLoading] = useState(false);
  const [isCommentLike, setIsCommentLike] = useState<boolean>(props.is_like);
  const [commentCount, setCommentCount] = useState(props?.like_count);
  const { isLoader } = props;

  useEffect(() => {
    setIsCommentLike(props.is_like);
  }, [props.is_like]);

  const commentLikeUnlike = (commentId: any) => {
    setCommentLikeLoading(true);
    ServiceDashBoard.apiCallPostCommentLikeUnlike(commentId, token)
      .then((res: any) => {
        setCommentCount(isCommentLike ? commentCount - 1 : commentCount + 1);
        setCommentLikeLoading(false);
        // setIsCommentLike(!isCommentLike);
        setIsCommentLike((prevIsLike: boolean) => !prevIsLike);
      })
      .catch((err) => {
        setCommentLikeLoading(false);
      });
  };

  const handleClickLikeCountComment = async (commentId: any) => {
    await commentLikeUnlike(commentId);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {isLoader ? (
            <Box display={"flex"} alignItems={"center"} gap="5px">
              <CommonSkeleton
                variant="circle"
                height="40px"
                width="40px"
                styles={{ borderRadius: "50px" }}
              />
              <CommonSkeleton variant="text" styles={{ width: "150px" }} />
            </Box>
          ) : (
            <PostUserInfo
              postedByData={props.commented_by_data}
              profileImage={true}
              show={false}
            />
          )}
        </Box>
        <Box sx={{ pt: 1 }}>
          {isLoader && (
            <CommonSkeleton
              variant="circle"
              width="30px"
              height="30px"
              styles={{ borderRadius: "50px" }}
            />
          )}
          {(isCommentLike || !isCommentLike) && (
            <IconButton
              onClick={() => handleClickLikeCountComment(props.uuid)}
              disabled={commentLikeLoading}
            >
              {(isCommentLike && <LikeIcon />) ||
                (!isCommentLike && <NotLikeIcon />)}
            </IconButton>
          )}
        </Box>
      </Box>
      <Box>
        {isLoader ? (
          <CommonSkeleton variant="text" width="100%" height="15px" />
        ) : (
          <Typography
            sx={{
              ...styles.textInformation2,
              fontSize: "13px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {props?.comment?.includes("U+")
              ? getEmojiFromUnicode(props?.comment)
              : props?.comment}
          </Typography>
        )}
      </Box>
      {!props.subComment ? (
        <Box>
          <Typography
            sx={{
              ...styles.textInformation1,
              fontSize: "12px",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {isLoader ? (
              <>
                <CommonSkeleton variant="text" width="100px" height="15px" />
                <CommonSkeleton variant="text" width="100px" height="15px" />
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box sx={{ mr: 1 }}>{commentCount} Like</Box>
                  <Box
                    sx={{ mr: 1, ...styles.pointer }}
                    onClick={() => props.replyOnComment(props)}
                  >
                    {t.reply}
                  </Box>
                </Box>
                <Box sx={{ width: 150 }}>{props?.commented_at}</Box>
              </>
            )}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      {props?.replied_on_comment_data &&
        props?.replied_on_comment_data.length !== 0 &&
        props.replied_on_comment_data.map((item: any, index: any) => (
          <Box sx={{ ml: 3 }}>
            <CommentBox {...item} subComment={true} />
          </Box>
        ))}
    </Box>
  );
};

export default CommentBox;
