import axios from "axios";
import fileDownload from "js-file-download";
import { LocalStorageUtils, ServiceRequest } from "../../helper";
import { KEY_LOCAL_STORAGE } from "../../helper/local-storage";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";

const apiCallGetProfileDetails = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.profile.userPersonalInfo}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetProfilingDetails = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.profile.userAthleteProfiling}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallAddEditProfilingDetails = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.profile.athleteAddEditProfiling,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallAddDocuments = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.profile.athleteFiles, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetAthleteFiles = (user_uuid: string, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.profile.athleteGetFiles + "?user_uuid=" + user_uuid}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallDeleteAthleteFiles = (reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.profile.athleteDeleteFiles,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
/*Add Edit personal details*/
const apiCallAddEditPersonalInfo = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.profile.userAddeditDetails,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const filesDownload = (url: string, fileName: string) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res: any) => {
      fileDownload(res.data, fileName);
    });
};

const apiCallForchangepassword = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      endPoint.profile.changePassword,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallFor2FAPUT = (user_uuid: string, reqBody: any, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PutApiRequest(
      `${endPoint.profile.twoFactorAuthentication + user_uuid + `/`}`,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallFor2FAGet = (user_uuid: string, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.profile.twoFactorAuthentication + user_uuid + `/`}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallChangeEmail = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.profile.changeEmail, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallVerifyEmail = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.profile.verifyNewEmail,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallChangePhone = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(endPoint.profile.changePhone, reqBody, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetStates = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(`${endPoint.profile.userState}`, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetCity = (stateId: string, token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(
      `${endPoint.profile.userCity + stateId + "/"}`,
      {},
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetClass = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(`${endPoint.profile.userClass}`, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallGetSports = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(`${endPoint.profile.userSports}`, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallGetCategories = (token: string) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.GetApiRequest(`/categories/`, {}, token)
      .then((res: any) => {
        if (!res.error) {
          resolve(res.data);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

const apiCallVerifyPhone = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.profile.verifyNewPhone,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
const apiCallVerifyPassword = (reqBody: any, token: any) => {
  return new Promise((resolve, reject) => {
    ServiceRequest.PostApiRequest(
      endPoint.profile.checkPassword,
      reqBody,
      token,
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};
export const ServiceProfile = {
  apiCallGetProfileDetails,
  apiCallGetProfilingDetails,
  apiCallAddEditProfilingDetails,
  apiCallAddDocuments,
  apiCallGetAthleteFiles,
  apiCallDeleteAthleteFiles,
  filesDownload,
  apiCallAddEditPersonalInfo,
  apiCallGetStates,
  apiCallGetCity,
  apiCallGetClass,
  apiCallGetSports,
  apiCallChangeEmail,
  apiCallVerifyEmail,
  apiCallChangePhone,
  apiCallVerifyPhone,
  apiCallForchangepassword,
  apiCallFor2FAPUT,
  apiCallFor2FAGet,
  apiCallVerifyPassword,
  apiCallGetCategories,
};
