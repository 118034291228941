import { ServiceRequest } from "../../helper";
import { endPoint } from "../../helper/rest-api/url-schema";
import { getErrorFromCatch } from "../../utils";

const apiCallForGetAllReels = (
  { pageNumber, pageSize = 10 }: any,
  token: string
) => {
  return new Promise((resolve, reject) => {
    let url = endPoint.explore.explorePost;
    ServiceRequest.GetApiRequest(
      url,
      { post_type: "reels", page: pageNumber, page_size: pageSize },
      token
    )
      .then((res: any) => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res.message);
        }
      })
      .catch((err: any) => {
        reject(getErrorFromCatch(err));
      });
  });
};

export const ServiceReels = { apiCallForGetAllReels };
