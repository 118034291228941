import { configureStore } from "@reduxjs/toolkit";
import { isProduction } from "../utils";
import {
  LanguageReducer,
  LoginReducer,
  SearchReducer,
  SnackbarReducer,
  MessageReducer,
  StoryReducer,
  PlayerReducer,
  ReelsReducer,
  createPost,
  appBar,
  hypeCast,
  userLogin,
  SavedPost,
  ChatListSlice,
  UserListSlice,
  UsersDetailsSlice,
} from "./reducer";

export const Store = configureStore({
  reducer: {
    appLanguage: LanguageReducer,
    rLogin: LoginReducer,
    messageStae: MessageReducer,
    search: SearchReducer,
    snackbar: SnackbarReducer,
    storyIndex: StoryReducer,
    player: PlayerReducer,
    reels: ReelsReducer,
    createPost: createPost,
    appBar: appBar,
    hypeCast: hypeCast,
    userLogin: userLogin,
    savedPost: SavedPost,
    chatList: ChatListSlice,
    usersList: UserListSlice,
    usersDetails: UsersDetailsSlice,
  },
  devTools: !isProduction,
});
