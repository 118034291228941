import React from "react";

const HomeIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={"app-bar-icons"}
      {...props}
    >
      <path
        d="M6.80908 12.1016H11.1703"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80005 10.2848C1.80005 6.0615 2.26055 6.35625 4.7393 4.0575C5.8238 3.1845 7.5113 1.5 8.96855 1.5C10.425 1.5 12.1463 3.17625 13.2405 4.0575C15.7193 6.35625 16.179 6.0615 16.179 10.2848C16.179 16.5 14.7098 16.5 8.98955 16.5C3.2693 16.5 1.80005 16.5 1.80005 10.2848Z"
        stroke={props?.color ?? "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
