import {
  Dialog,
  Container,
  DialogTitle,
  Divider,
  DialogContent,
  Box,
  TextField,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "../../../theming/Style/Styles";
import { PatterColor } from "../../../theming/Style/color.styles";
import DialogCommon from "../../common/dialog-common";
import CommonSkeleton from "../../common/skeleton";
import { makeStyles } from "@mui/styles";
import hypeImage from "../../../assets/Images/hype1.jpg";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { AppStringUtils } from "../../../utils";
import { ServiceMessage } from "../../../api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showSocialSnackbar } from "../../../store/reducer/Snackbar";
import { Spinner } from "../../common";
import ShareMessageDialog from "../../common/ShareMessageDialog";
import NoRecordFound from "../../../container/hype-cast/common/NoRecordFound";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundImage: `url(${hypeImage})`,
    overflow: "hidden",
    borderRadius: "20px",
  },
  drawerHeader: {
    margin: "30px 0px",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    cursor: "pointer",
  },
}));

export default function PostSharePopup(props: any) {
  const { uuid, isLoader, data, postInfo } = props;
  const classes = useStyles();
  const t = AppStringUtils();
  const { token } = useSelector((state: any) => state?.rLogin?.infoLogin);
  const { postIndex } = useSelector((state: any) => state.savedPost);

  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showLimitExceededDialog, setShowLimitExceededDialog] = useState(false);

  const handleChange = (e: any) => {
    const searchText = e.target.value.toLowerCase();
    if (searchText.length === 0) {
      setFilteredData(data);
    } else {
      const filteredData = props.data.filter((item: any) => {
        const { username, first_name, last_name } = item;
        return (
          username.toLowerCase().includes(searchText) ||
          first_name.toLowerCase().includes(searchText) ||
          last_name.toLowerCase().includes(searchText)
        );
      });
      setFilteredData(filteredData);
    }
  };

  const apiCallForSharedPosts = (item: any) => {
    setIsLoading(true);
    const { uuid } = postInfo[postIndex];
    let reqBody = {
      text_url_shared_media: uuid,
      to_message_user_uuid: item.map((item1: any) => item1?.user_uuid),
      message_type: "Shared_Post",
    };
    ServiceMessage.apiCallSharedProfileMessages(reqBody, token)
      .then((res: any) => {
        if (res) {
          dispatch(
            showSocialSnackbar({ message: t.postSharedMsg, type: "success" }),
          );
        }
        handleClose();
        setUsersList([]);
        setIsLoading(false);
      })
      .catch((err: any) => {
        dispatch(
          showSocialSnackbar({ message: t.wentWrongMsg, type: "error" }),
        );
        handleClose();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleClose = () => {
    setUsersList([]);
    props?.setIsOpen(false);
  };

  return (
    <Dialog
      open={props?.isOpen}
      maxWidth="sm"
      onClose={handleClose}
      PaperProps={{ className: classes.drawer }}
    >
      <Container
        sx={{
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <DialogTitle
          sx={{
            position: "sticky",
            width: "400px",
          }}
        >
          <DialogCommon dialogTitle={t.share} setIsOpen={props?.setIsOpen} />
          <Divider color="grey" sx={styles.autoHW} />
        </DialogTitle>

        <DialogContent
          sx={{
            width: "400px",
            background: "black",
            color: "white",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              paddingBottom: "5px",
            }}
          >
            <TextField
              className="input-plain-background"
              placeholder="Search..."
              fullWidth
              size="small"
              style={{
                color: "white",
                border: "1px solid #526156",
                borderRadius: "17px ",
              }}
              InputLabelProps={{
                style: { ...styles.inputLabel, color: "white" },
              }}
              onChange={handleChange}
            />
          </Box>
          <Box
            sx={{
              margin: "auto",
              height: "35vh",
              overflow: "scroll",
              "::-webkit-scrollbar": {
                display: "none",
              },
              paddingTop: "20px",
            }}
          >
            {filteredData?.length === 0 ? (
              <Box sx={styles.postShareRecordStyle}>{t.noUserFound}</Box>
            ) : (
              <>
                {(isLoader ? Array.from(new Array(20)) : filteredData).map(
                  (item: any, index: number) => {
                    return (
                      <Box className="hoverConver" sx={styles.popoverPost}>
                        <ListItem
                          sx={{
                            padding: "0px 0px",
                          }}
                        >
                          {isLoader ? (
                            <CommonSkeleton
                              variant="rectangulars"
                              width="100%"
                              height="50px"
                              styles={{ borderRadius: "20px" }}
                            />
                          ) : (
                            <ListItemButton
                              sx={{ color: PatterColor.white }}
                              onClick={() => {
                                const userUuidArray = usersList.map(
                                  (item: any) => item.user_uuid,
                                );
                                const isUserSelected = userUuidArray.includes(
                                  item.user_uuid,
                                );
                                if (isUserSelected) {
                                  setUsersList(
                                    usersList.filter(
                                      (el: any) =>
                                        el.user_uuid !== item.user_uuid,
                                    ),
                                  );
                                } else if (usersList.length < 5) {
                                  setUsersList([...usersList, item]);
                                } else {
                                  setShowLimitExceededDialog(true);
                                }
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  marginRight: "20px",
                                }}
                                src={item.profile_image}
                                imgProps={{ loading: "lazy" }}
                              />

                              <ListItemText
                                primary={item.first_name + " " + item.last_name}
                              />
                              <ListItemIcon>
                                <Checkbox
                                  sx={{
                                    color: PatterColor.white,
                                  }}
                                  edge="end"
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<RadioButtonCheckedIcon />}
                                  checked={usersList
                                    ?.map((item: any) => item?.user_uuid)
                                    .includes(item?.user_uuid)}
                                />
                              </ListItemIcon>
                            </ListItemButton>
                          )}
                        </ListItem>
                      </Box>
                    );
                  },
                )}
                {isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Spinner isShow={isLoading} />
                  </Box>
                ) : (
                  <Button
                    sx={{
                      ...styles.popoverPost,
                      color: PatterColor.white,
                      padding: "5px 20px",
                    }}
                    // disabled={usersList?.length === 0 ? true : false}
                    onClick={() => {
                      return (
                        usersList.length >= 1 &&
                        apiCallForSharedPosts(usersList)
                      );
                    }}
                  >
                    {t.send}
                  </Button>
                )}
              </>
            )}
          </Box>
        </DialogContent>
      </Container>
      <ShareMessageDialog
        showLimitExceededDialog={showLimitExceededDialog}
        setShowLimitExceededDialog={setShowLimitExceededDialog}
      />
    </Dialog>
  );
}
