import { Avatar, Badge, Box, Container, Paper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CreatePostIcon from "../../assets/Icons/CreatePostIcon";
import HypeCastIcon from "../../assets/Icons/HypeCastIcon";
import NotificationIcon from "../../assets/Icons/NotificationIcon";
import SocialIcon from "../../assets/Icons/SocialIcon";
import { RoutePath } from "../../routes/route-path";
import { PatterColor } from "../../theming/Style/color.styles";
import { UserTypes } from "../common/enums/user-types";

export const StyledBadgeNotification = withStyles((theme) => ({
  badge: {
    fontSize: "10px",
    height: "12px",
  },
}))(Badge);

export const iconContainerStyle = {
  height: { xs: 35, sm: 40 },
  width: { xs: 45, sm: 40 },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderImageSlice: 1,
  borderTop: "2px solid",
  borderImageSource: `linear-gradient(125.87deg, #E900FF 9.62%, #16E8FF 108.03%)`,
  cursor: "pointer",
};
export default function LabelBottomNavigation(props: any) {
  const { count, setReadNotification } = props;
  const [value, setValue] = React.useState("recents");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { infoLogin, infoProfile } = useSelector(
    (state: any) => state?.rLogin || {},
  );
  const { user_details, user_type } = infoLogin || {};

  const router = useNavigate();
  const location = useLocation();
  const [navIndex, setNavIndex] = useState<number>(2);
  const routePath = RoutePath.userProfile;
  const [screenStatus, setScreenStatus] = useState(
    parseInt(localStorage?.getItem("profile-info-screen") || "0"),
  );

  const navigate = useNavigate();

  let profileImage = "";
  let userData: any = {};
  if (infoProfile?.profile_image) {
    userData = infoProfile;
    profileImage = userData?.profile_image;
  } else {
    userData = user_details;
    profileImage = userData?.profile_image;
  }

  useEffect(() => {
    switch (location.pathname) {
      case RoutePath.home:
        setNavIndex(1);
        break;
      case RoutePath.hypeCast:
        setNavIndex(2);
        break;
      case RoutePath.notification:
        setNavIndex(3);
        break;
      case RoutePath.createPost:
        setNavIndex(4);
        break;
    }
    location.pathname.includes(RoutePath.userProfile) && setNavIndex(5);
  }, [location]);

  useEffect(() => {
    setScreenStatus(
      parseInt(localStorage?.getItem("profile-info-screen") || "0"),
    );
  }, [localStorage?.getItem("profile-info-screen")]);
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        display: { md: "none", xs: "flex" },
        justifyContent: "center",
        background: "black",
      }}
      elevation={3}
      id={"bottom-navigation"}
    >
      <Container maxWidth="md">
        <BottomNavigation
          sx={{
            bottom: 0,
            background: "black",
            justifyContent: "space-between",
            width: { xs: "100%", sm: "100%" },
            height: "50px",
          }}
          value={value}
          onChange={handleChange}
        >
          <Box
            onClick={() => {
              router("/hype-cast");
            }}
            sx={{
              ...iconContainerStyle,
              borderImageSource:
                navIndex === 2
                  ? `linear-gradient(125.87deg, #E900FF 9.62%, #16E8FF 108.03%)`
                  : "none",
            }}
          >
            <HypeCastIcon style={{ marginTop: "5px" }} />
          </Box>
          <Box
            onClick={() => {
              router(RoutePath.home);
            }}
            sx={{
              ...iconContainerStyle,
              borderImageSource:
                navIndex === 1
                  ? `linear-gradient(125.87deg, #E900FF 9.62%, #16E8FF 108.03%)`
                  : "none",
            }}
          >
            <SocialIcon style={{ marginTop: "5px" }} />
          </Box>
          {(user_type !== UserTypes.basic || screenStatus === 5) && (
            <Box
              onClick={() => {
                navigate(RoutePath.createPost);
                setNavIndex(4);
              }}
              sx={{
                ...iconContainerStyle,
                borderImageSource:
                  navIndex === 4
                    ? `linear-gradient(125.87deg, #E900FF 9.62%, #16E8FF 108.03%)`
                    : "none",
              }}
            >
              <CreatePostIcon style={{ marginTop: "5px" }} />
            </Box>
          )}
          <Box
            onClick={() => {
              navigate(RoutePath.notification);
              setReadNotification(true);
            }}
            sx={{
              ...iconContainerStyle,
              borderImageSource:
                navIndex === 3
                  ? `linear-gradient(125.87deg, #E900FF 9.62%, #16E8FF 108.03%)`
                  : "none",
            }}
          >
            <StyledBadgeNotification
              badgeContent={count?.notification_count}
              color="warning"
              variant="dot"
              overlap="circular"
              sx={{
                mt: 2 / 3,
                "& .MuiBadge-dot": {
                  width: "12px", // Adjust the width of the dot
                  height: "12px", // Adjust the height of the dot
                  borderRadius: "50%", // Ensure the dot is circular
                },
              }}
            >
              <NotificationIcon />
            </StyledBadgeNotification>
          </Box>
          <Box
            onClick={() => {
              router(`${routePath + user_details.user_uuid}`);
            }}
            sx={{
              ...iconContainerStyle,
              borderImageSource:
                navIndex === 5
                  ? `linear-gradient(125.87deg, #E900FF 9.62%, #16E8FF 108.03%)`
                  : "none",
            }}
          >
            <Avatar
              sx={{
                color:
                  navIndex === 3 ? PatterColor.white : PatterColor.thirdGray,
                width: 25,
                height: 25,
                marginTop: "5px",
                mx: 1,
                cursor: "pointer",
                "&:hover": {
                  color: "white",
                },
              }}
              src={profileImage}
              imgProps={{ loading: "lazy" }}
            />
          </Box>
        </BottomNavigation>
      </Container>
    </Paper>
  );
}
