import { createSlice } from "@reduxjs/toolkit";

interface SearchReducerProps {
  search: String;
  searchResult?: any;
  searchParam?: string;
}

const initialStates: SearchReducerProps = {
  search: "",
  searchResult: {},
  searchParam: "",
};

const SearchSlice = createSlice({
  name: "search",
  initialState: initialStates,
  reducers: {
    actionSearch(state: SearchReducerProps, action) {
      state.search = action.payload;
    },
    updateSearchResult(state: SearchReducerProps, action) {
      state.searchResult = action.payload;
    },
    updateSearchParams(state: SearchReducerProps, action) {
      state.searchParam = action.payload;
    },
    // updateSearchword
  },
});

export const { actionSearch, updateSearchResult, updateSearchParams } =
  SearchSlice.actions;
export default SearchSlice.reducer;
